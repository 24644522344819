import React from "react"
import ContentLoader from "react-content-loader"

const SummaryLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={200}
    height={100}
    viewBox="0 0 200 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="34" y="73" rx="3" ry="3" width="88" height="10" /> 
    <circle cx="165" cy="76" r="20" /> 
    <rect x="31" y="36" rx="0" ry="0" width="153" height="10" />
  </ContentLoader>
)

export default SummaryLoader
