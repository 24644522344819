import React, { useState, useEffect } from "react";
import { Button, Input, Form, Row, Col, Select, message } from "antd";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import StyledModal from "../styled-modal";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetchUsersAction } from "../../../services/actions";
import makeAPICall from "../../../utilities/apiUtils";

export default function EditUserModal({ show, onClose, user }) {
  // console.log(user, "test");
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.RoleReducer);
  const { countries } = useSelector((state) => state.CountryReducer.countries);
  const [incomplete, setIncomplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    role: user?.role,
  });

  const countryArray = countries?.map((item) => item?.country.toLowerCase());

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const onSelectChange = (value, name) => {
    if (value === "administrator") value = "admin";
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  useEffect(() => {
    setIncomplete(false);

    if (!profile.firstName || profile.firstName === "") setIncomplete("true");
    if (!profile.lastName || profile.lastName === "") setIncomplete("true");
    if (!profile.phone || profile.phone === "") setIncomplete("true");
    if (!profile.email || profile.email === "") setIncomplete("true");
    if (!profile.role || profile.role === "") setIncomplete("true");
  }, [profile]);

  const onSubmitForm = () => {
    const { firstName, lastName, email, role, phone } = profile;
    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      role: role,
    };
    if (role === "owner") {
      message.error("You cannot assign owner role to a user");
      return;
    } else {
      setIsLoading(true);
      return makeAPICall({
        path: `users/${user.id}`,
        payload: data,
        method: "PUT",
      })
        .then((res) => {
          message.success({
            content: "User edited successfully",
            duration: 4,
          });
          setIsLoading(false);
          setProfile({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            role: "",
          });
          onClose();
          dispatch(fetchUsersAction());
        })
        .catch((err) => {
          setIsLoading(false);
          message.error({ content: err.message, duration: 4 });
        });
    }
  };

  function closeModal() {
    setProfile({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",
    });
    onClose();
  }

  function handleOnChangePhoneInput(value) {
    setProfile({ ...profile, phone: value });
  }

  var myData = Object.keys(roles?.roles).map((key) => {
    return roles?.roles[key];
  });

  return (
    <StyledModal
      centered
      show={show}
      footer={null}
      onClose={() => closeModal()}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <div>
              <div className="mt-4">
                <p className="text-bold color-bold text-md mb-1">Edit User</p>
              </div>
              <Form layout="vertical">
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="First Name">
                        <Input
                          name="firstName"
                          value={profile.firstName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Last Name">
                        <Input
                          name="lastName"
                          value={profile.lastName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Email">
                        <Input
                          name="email"
                          value={profile.email}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Phone">
                        <ReactPhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                            className: "formInput",
                          }}
                          countryCodeEditable={false}
                          value={profile.phone}
                          onChange={handleOnChangePhoneInput}
                          // enableSearch={true}
                          // preferredCountries={countryArray}
                          onlyCountries={countryArray}
                          disableCountryCode={false}
                          placeholder="0800 000 0000"
                          autoFormat={true}
                          enableClickOutside={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                {user?.role === "owner" ? null : (
                  <Form.Item label="User Role">
                    <Select
                      className="form__select mt-1"
                      name="role"
                      placeholder="Choose Users Role"
                      value={profile.role}
                      onChange={(value) => onSelectChange(value, "role")}
                    >
                      {myData?.map((role, index) => (
                        <Select.Option
                          value={role.toLowerCase().replaceAll(" ", "_")}
                          key={index}
                        >
                          {role}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Button
                  type="primary"
                  className="simple-block-btn text-bold"
                  size="large"
                  block
                  onClick={onSubmitForm}
                  disabled={incomplete}
                  loading={isLoading}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
