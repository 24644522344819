import ACTIONS from "../constants";

const initialState = {
  wallets: [],
  errorMessage: null,
  loading: false,
  isExchange: false,
  isAddMoney: false,
  isSendMoney: false,
  isCreateCard: false,
  isSendMoneyBeneficiary: false,
  isAddMoneyValue: null,
  isType: null,
  isSendMoneyState: false,
  isSendMoneyBeneficiaryValue: null,
  hasCalled: 0,
};

const walletsReducer = (state = initialState, action) => {
  // console.log(action, 'try')
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_WALLETS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
        hasCalled: state.hasCalled === 3 ? 3 : 1,
      };
    case ACTIONS.FETCH_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: payload,
        loading: false,
        hasCalled: 3,
      };
    case ACTIONS.FETCH_WALLETS_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
        hasCalled: state.hasCalled === 3 ? 3 : 2,
      };
    case ACTIONS.TOGGLE_EXCHANGE_MODAL:
      return {
        ...state,
        isExchange: !state.isExchange,
        isExchangeValue: payload,
      };
    case ACTIONS.TOGGLE_ADDMONEY_MODAL:
      return {
        ...state,
        isAddMoney: !state.isAddMoney,
        isAddMoneyValue: payload.name,
        isType: payload.item,
      };
    case ACTIONS.TOGGLE_SENDMONEY_MODAL:
      return {
        ...state,
        isSendMoney: !state.isSendMoney,
      };
    case ACTIONS.TOGGLE_CREATECARD_MODAL:
      return {
        ...state,
        isCreateCard: !state.isCreateCard,
      };
    case ACTIONS.TOGGLE_SENDMONEY_BENEFICIARY_MODAL:
      return {
        ...state,
        isSendMoneyBeneficiary: !state.isSendMoneyBeneficiary,
        isSendMoneyBeneficiaryValue: payload,
        isSendMoneyState: true,
      };
    default:
      return state;
  }
};

export default walletsReducer;
