import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function Search({ onChange, className, maxWidth, placeholder }) {

  function handleChange(event) {
    onChange && onChange(event);
  }
  
  return (
    <div className="align-self-center">
      <Input
        size="large"
        placeholder={placeholder}
        prefix={<SearchOutlined className="faded" />}
        className={className ? className : "header-search"}
        style={{ height: "100%", maxWidth: maxWidth }}
        onChange={ handleChange }
      />
    </div>
  );
}
