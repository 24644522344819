import ACTIONS from '../constants';

const initialState = {
	transactionsSummary: [],
	accountSummary: null,
	transactionsSummaryError: null,
	accountSummaryError: null,
	transactionsSummaryloading: false,
	accountSummaryloading: false,
	transCall: 0,
	accountCall: 0
};

const analyticsReducer = (state = initialState, action) => {
	const { payload } = action;
	switch (action.type) {
		case ACTIONS.FETCH_ANALYTICS_BALANCE:
			return {
				...state,
				accountSummaryError: null,
				accountSummaryloading: true,
				accountCall: state.accountCall === 3 ? 3 : 1
			};
		case ACTIONS.FETCH_ANALYTICS_BALANCE_SUCCESS:
			return {
				...state,
				accountSummary: payload,
				accountSummaryloading: false,
				accountCall: 3
			};
		case ACTIONS.FETCH_ANALYTICS_BALANCE_ERROR:
			return {
				...state,
				accountSummaryError: payload,
				accountSummaryloading: false,
				accountCall: state.accountCall === 3 ? 3 : 2
			};
		case ACTIONS.FETCH_ANALYTICS_TRANSACTIONS:
			return {
				...state,
				transactionsSummaryError: null,
				transactionsSummaryloading: true,
				transCall: state.transCall === 3 ? 3 : 1
			};
		case ACTIONS.FETCH_ANALYTICS_TRANSACTIONS_SUCCESS:
			return {
				...state,
				transactionsSummary: payload.transactions,
				change: payload.change,
				transactionsSummaryloading: false,
				transCall: 3
			};
		case ACTIONS.FETCH_ANALYTICS_TRANSACTIONS_ERROR:
			return {
				...state,
				transactionsSummaryError: payload,
				transactionsSummaryloading: false,
				transCall: state.transCall === 3 ? 3 : 2
			};
		default:
			return state;
	}
};

export default analyticsReducer;
