import React, { useState } from "react";

import StyledModal from "../styled-modal";
import imageIcon from "../../../assets/images/ip_icon.svg";
import { Tag, message } from "antd";
import makeAPICall from "../../../utilities/apiUtils";
import { useDispatch } from "../../../utilities/hooks";
import { fetchProfileAction } from "../../../services/actions";

export default function IPAddressModal({
  show,
  onClose,
  checkAccess,
  handleOpenModal,
}) {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState(
    checkAccess() ? "single" : "all"
  );

  const key = "changeAccess";

  const handleChange = (e) => {
    setInputValues(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValues === "all") {
      message.error("This isn't currently available", 4);
    } else {
      message.loading({ content: "Loading...", key });
      return makeAPICall({
        path: "account/deactivateAllAccess",
        method: "PATCH",
      })
        .then((res) => {
          dispatch(fetchProfileAction());
          handleOpenModal();
          onClose();
        })
        .catch((err) => {
          message.error({ content: err.message, key, duration: 4 });
        });
    }
  };

  return (
    <StyledModal centered show={show} footer={null} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-3 mt-5">
            <div className="add-ip-address">
              <div className="text-center">
                <img src={imageIcon} alt="notification icon" />
                <h1 className="mt-3">Change IP whitelist access</h1>
                <p className="mt-3 mb-4">
                  API whitelisting helps you to connect Eversend with other apps
                  / websites you use
                </p>
              </div>
              <div className="custom-radio-group-general">
                {/* <label className="custom-radio-general mb-3">
                  <input
                    type="radio"
                    id="radio-input-1"
                    name="radio-group"
                    value="all"
                    onChange={handleChange}
                    checked={inputValues === "all"}
                  />
                  <span className="checkmark"></span>
                  <span className="biz-title">Allow access from anywhere </span>
                  <br />
                  <span className="biz-desc">
                    This is a high risk option that grants access to all apps
                    and websites on the internet. Use only if you understand the
                    security risks
                  </span>
                </label> */}
                <label className="custom-radio-general mb-1">
                  <input
                    type="radio"
                    id="radio-input-2"
                    name="radio-group"
                    value="single"
                    onChange={handleChange}
                    checked={inputValues === "single"}
                  />
                  <span className="checkmark"></span>
                  <span className="biz-title">
                  Allow access from specific IP addresses
                  </span>
                  <br />
                  <span className="biz-desc">
                    This is a low risk option that grants access to only apps
                    and websites you know to access your Eversend data.
                  </span>
                </label>
                <Tag color="green">Recommended</Tag>
              </div>
              <div className="text-right">
                <button
                  className="ev-primary-btn"
                  onClick={handleSubmit}
                  disabled={!inputValues}
                >
                  Allow access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
