import React, { useState, useEffect } from "react";
import { Button, Input, Form, message, Select, Spin } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import * as ctzc from "country-tz-currency";

import DeleteBeneficiaryModal from "./DeleteBeneficiary";
import {
  editSingleBeneficiaryAction,
  deleteSingleBeneficiariesAction,
} from "../../../services/actions";
import { useDispatch, useSelector } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";
import StyledModal from "../styled-modal";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  bankName: Yup.string().required().min(3).label("Bank Name"),
  country: Yup.string().required().label("Country"),
  bankAccountNumber: Yup.string().required().min(8).label("Account Number"),
  phoneNumber: Yup.string().required().min(8).label("Phone Number"),
});

const validationSchemaMomo = Yup.object().shape({
  phoneNumber: Yup.string().required().min(3).label("Phone Number"),
});

export default function EditBeneficiaryModal({
  show,
  onClose,
  item,
  beneficiaries,
}) {
  const dispatch = useDispatch();
  const { banks } = useSelector((state) => state.BanksReducer);
  const { countries } = useSelector((state) => state.CountryReducer.countries);

  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] =
    useState(false);
  const [banksSelect, setBanksSelect] = useState([]);
  const [country, setCountry] = useState(item.country);
  const [itemPosition, setItemPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accName, setAccName] = useState("");
  const [bankName, setBankName] = useState(item.bankName);
  const [bankCode, setBankCode] = useState(item.bankCode);

  function handleSubmit(values, setSubmitting) {
    setSubmitting(true);
    let counter = 4;
    if (item.isBank) counter = 7;
    let result = { ...item, ...values, counter };

    setSubmitting(false);

    message.success({
      content: "Beneficiary Details Edited Successfully",
      duration: 4,
    });

    dispatch(editSingleBeneficiaryAction(result));
    onClose();
  }

  function handleOk() {
    dispatch(deleteSingleBeneficiariesAction([itemPosition]));
    setShowDeleteBeneficiaryModal(false);
    onClose();
  }

  function handleFieldChange(value, setSubmitting) {
    setSubmitting(true);
    if (item.country === "NG") {
      setAccName("");
      let params = {
        countryCode: item.country,
        bankCode: bankCode,
        accountNumber: value.target.value,
      };

      setLoading(true);
      return makeAPICall({
        path: "/settings/banks/accounts",
        params,
        method: "GET",
      })
        .then((res) => {
          setAccName(res?.data?.account_name);
          message.success("Beneficiary Fetched Successfully", 10);
          setLoading(false);
          setSubmitting(false);
        })
        .catch((err) => {
          setLoading(false);
          setSubmitting(false);
        });
    }
  }

  useEffect(() => {
    setBanksSelect(banks[country] || []);
  }, [country, banks]);

  useEffect(() => {
    let position = beneficiaries.indexOf(item);
    setItemPosition(position);
  }, [item, beneficiaries]);

  useEffect(() => {
    let bankInfo = banksSelect.filter((data) => data.name === bankName);
    if (bankInfo.length > 0) setBankCode(bankInfo[0].code);
  }, [bankName, banksSelect]);

  if (Object.keys(item).length === 0) {
    return null;
  }

  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="beneficiaries__list" style={{ maxHeight: "100vh" }}>
        <div className="container">
          <div className="row">
            {item.isBank ? (
              <div className="col-sm-6 offset-3  mt-5">
                <p>Edit imported beneficiary field</p>
                {item.counter !== 7 ? (
                  <WarningFilled
                    style={{ fontSize: "26px", color: "#FFD200" }}
                    className="mr-4"
                  />
                ) : null}

                <span>
                  {itemPosition + 1} of {beneficiaries.length}
                </span>
                <div className="flex align-center mt-5">
                  <span className="beneficiary__avatar">
                    {/* country flag */}
                    {item.firstName[0]}
                    {item.lastName[0]}
                  </span>
                  <span className="ml-3">
                    <p className="color-bold text-bold text-smd mb-0">
                      {item.firstName} {item.lastName}
                    </p>
                  </span>
                </div>
                <br />
                <Formik
                  initialValues={{
                    bankName: item.bankName,
                    country: item.country,
                    bankAccountNumber: item.bankAccountNumber,
                    phoneNumber: item.phoneNumber,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                  }) => (
                    <Form layout="vertical">
                      <Form.Item label="Country">
                        <Select
                          size="large"
                          name="country"
                          value={values.country}
                          onChange={(value) => {
                            setFieldValue("country", value);
                            setCountry(value);
                          }}
                          onBlur={handleBlur}
                          disabled
                        >
                          {countries.map((data) => (
                            <Option key={data.country} value={data.country}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.country && touched.country && errors.country}
                      </p>
                      <Form.Item label="Phone Number">
                        <div className="flex justify-content-around">
                          <div>
                            <p
                              style={{ width: "100px", height: "50px" }}
                              className="card p-2 pt-3 mr-3"
                            >
                              {ctzc.getCountryByCode(values.country).areaCode}
                            </p>
                          </div>
                          <Input
                            style={{ height: "20px" }}
                            size="small"
                            type="text"
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                          />
                        </div>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </p>

                      <Form.Item label="Bank Name">
                        <Select
                          size="large"
                          name="bankName"
                          value={values.bankName}
                          onChange={(value) => {
                            setFieldValue("bankName", value);
                            setBankName(value);
                          }}
                          onBlur={handleBlur}
                          showSearch
                          placeholder="Select Bank"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {banksSelect.map((data) => (
                            <Option key={data.id} value={data.name}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.bankName && touched.bankName && errors.bankName}
                      </p>

                      <Form.Item label="Account Number">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="bankAccountNumber"
                          onChange={(value) => {
                            handleChange(value);
                            setAccName("");
                          }}
                          onBlur={(value) => {
                            handleChange(value);
                            handleFieldChange(value, setSubmitting);
                          }}
                          value={values.bankAccountNumber}
                        />
                      </Form.Item>
                      <div className="flex justify-content-end">
                        {loading ? <Spin /> : null}
                      </div>
                      {accName !== "" ? (
                        <p className="card pt-3 pl-3">
                          <p>{accName}</p>
                        </p>
                      ) : null}

                      <p className="text-danger">
                        {errors.bankAccountNumber &&
                          touched.bankAccountNumber &&
                          errors.bankAccountNumber}
                      </p>

                      <div className="flex justify-content-between mt-5">
                        <Button
                          type="primary"
                          size={"large"}
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          block
                        >
                          Save Edit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <div className="col-sm-6 offset-3  mt-5">
                <p>Edit imported beneficiary field</p>
                {item.counter !== 4 ? (
                  <WarningFilled
                    style={{ fontSize: "26px", color: "#FFD200" }}
                    className="mr-4"
                  />
                ) : null}

                <span>
                  {itemPosition + 1} of {beneficiaries.length}
                </span>
                <div className="flex align-center mt-5">
                  <span className="beneficiary__avatar">
                    {/* country flag */}
                    {item?.firstName[0]}
                    {item?.lastName[0]}
                  </span>
                  <span className="ml-3">
                    <p className="color-bold text-bold text-smd mb-0">
                      {item?.firstName} {item?.lastName}
                    </p>
                  </span>
                </div>
                <Formik
                  initialValues={{
                    phoneNumber: item.phoneNumber,
                  }}
                  validationSchema={validationSchemaMomo}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form layout="vertical">
                      <Form.Item label="Phone Number">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                        />
                      </Form.Item>
                      <p className="text-danger">
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </p>

                      <div className="flex justify-content-between mt-5">
                        <Button
                          type="primary"
                          size={"large"}
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          block
                        >
                          Save Edit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteBeneficiaryModal
        show={showDeleteBeneficiaryModal}
        onClose={() => setShowDeleteBeneficiaryModal(false)}
        onOk={handleOk}
        item={item}
      />
    </StyledModal>
  );
}
