import makeAPICall from "../../utilities/apiUtils";
import { message } from "antd";
import {
  fetchTransactions,
  fetchTransactionsError,
  fetchTransactionsSuccess,
  fetchCryptoTransactions,
  fetchCryptoTransactionsError,
  fetchCryptoTransactionsSuccess,
  fetchCryptoAddresses,
  fetchCryptoAddressesError,
  fetchCryptoAddressesSuccess,
  fetchCardTransactions,
  fetchCardTransactionsSuccess,
  fetchCardTransactionsError,
  createExchangeQuotation,
  createExchangeQuotationSuccess,
  createExchangeQuotationError,
  submitExchangeQuotation,
  submitExchangeQuotationSuccess,
  submitExchangeQuotationError,
  resetQuotation,
  createPayout,
  createPayoutError,
  createPayoutSuccess,
  createPayoutQuotation,
  createPayoutQuotationSuccess,
  createPayoutQuotationError,
  deletePayout,
  deletePayoutQuotation,
} from "../selectors";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_completed_transaction",
    transaction_type: "exchange",
  },
};

const tagManagerArgsPayout = {
  dataLayer: {
    event: "b2b_completed_transaction",
    transaction_type: "send_money",
  },
};

const fetchTransactionsAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchTransactions());
    return makeAPICall({
      path: "transactions",
      method: "GET",
      params,
    })
      .then((res) => {
        const { data } = res;

        dispatch(fetchTransactionsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchTransactionsError(err.message));
      });
  };

const fetchCryptoTransactionsAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchCryptoTransactions());
    return makeAPICall({
      path: "crypto/transactions",
      method: "GET",
      params,
    })
      .then((res) => {
        const { data } = res;

        dispatch(fetchCryptoTransactionsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchCryptoTransactionsError(err.message));
      });
  };

const fetchCardTransactionsAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchCardTransactions());
    return makeAPICall({
      path: "cards/transactions",
      method: "GET",
      params,
    })
      .then((res) => {
        const { data } = res;

        dispatch(fetchCardTransactionsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchCardTransactionsError(err.message));
      });
  };

const fetchCryptoAddressesAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchCryptoAddresses());
    return makeAPICall({
      path: "crypto/addresses",
      method: "GET",
      params,
    })
      .then((res) => {
        const { data } = res;

        dispatch(fetchCryptoAddressesSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchCryptoAddressesError(err.message));
      });
  };

const createExchangeQuotations = (payload) => (dispatch) => {
  dispatch(createExchangeQuotation());

  return makeAPICall({
    path: "transactions/quotations",
    payload,
    method: "POST",
  })
    .then((res) => {
      const { quotation, token } = res.data;
      if (!token) {
        message.warning("Insufficient balance", 10);
      }

      dispatch(createExchangeQuotationSuccess({ ...quotation, token }));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(createExchangeQuotationError(err.message));
    });
};

const submitQuotation = (payload) => (dispatch) => {
  dispatch(submitExchangeQuotation());
  return makeAPICall({
    path: "transactions/exchanges",
    payload,
    method: "POST",
  })
    .then((res) => {
      TagManager.dataLayer(tagManagerArgs);
      dispatch(submitExchangeQuotationSuccess("Exchange was successful"));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(submitExchangeQuotationError(err.message));
    });
};

const createPayoutAction = (data) => (dispatch) => {
  dispatch(createPayout());
  return makeAPICall({
    path: "transactions/payouts",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      TagManager.dataLayer(tagManagerArgsPayout);
      const { transaction } = res.data;
      dispatch(createPayoutSuccess(transaction));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(createPayoutError(err.message));
    });
};

const createPayoutQuotationAction = (data) => (dispatch) => {
  dispatch(createPayoutQuotation());
  return makeAPICall({
    path: "transactions/payouts/quotation",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      if (!res.data.token) {
        message.warning("Insufficient balance", 10);
      }
      // const { token } = res.data;
      dispatch(createPayoutQuotationSuccess(res.data));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(createPayoutQuotationError(err.message));
    });
};

const resetQuotationParams = () => (dispatch) => {
  dispatch(resetQuotation());
};

const deletePayoutAction = () => (dispatch) => {
  dispatch(deletePayout());
};

const deletePayoutQuotationAction = () => (dispatch) => {
  dispatch(deletePayoutQuotation());
};

export {
  fetchTransactionsAction,
  fetchCryptoTransactionsAction,
  fetchCardTransactionsAction,
  fetchCryptoAddressesAction,
  createExchangeQuotations,
  submitQuotation,
  resetQuotationParams,
  createPayoutAction,
  createPayoutQuotationAction,
  deletePayoutAction,
  deletePayoutQuotationAction,
};
