import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import AccordionDoc from "../Accordion/documents";
import makeAPICall from "../../utilities/apiUtilsFetch";

export default function Documents({
  user,
  closeModalContinue,
  closeModalDone,
}) {
  const [selected, setSelected] = useState(
    user?.businessCountry ? user?.businessCountry : user?.account?.country
  );
  const [businessType, setBusinessType] = useState(
    user?.businessType
      ? user?.businessType.toUpperCase()
      : user?.account?.type.toUpperCase()
  );
  const [kyc, setKyc] = useState();

  const countryArray = [
    "NG",
    "GH",
    "RW",
    "KE",
    "UG",
    "US",
    "CM",
    "CI",
    "TZ",
    "GB",
  ];

  const options = [
    {
      value: "SP",
      label: "Sole Proprietorship",
      title:
        "A sole proprietorship is a type of business that is owned and run by one person. The owner makes all the decisions and keeps all the profits.",
    },
    {
      value: "NGO",
      label: "Non Governmental Organization",
      title:
        "An NGO, or Non-Governmental Organization, is a non-profit, voluntary group or association that operates independently of government institutions. NGOs typically work towards social, environmental, humanitarian, or development causes at the local, national, or international level. ",
    },
    {
      value: "LLC",
      label: "Limited Liability Company",
      title:
        "An LLC combines elements of both partnerships and corporations, offering owners limited liability protection and pass-through taxation. In an LLC, owners (also known as members) are not personally liable for the company's debts or liabilities, meaning their personal assets are protected in case the business faces financial or legal difficulties. An LLC can be owned by one or more members, who can be individuals, other LLCs, or corporations. The management structure can be member-managed, where all members are actively involved in running the business, or manager-managed, where one or more designated managers handle the day-to-day operations.",
    },
  ];

  function handleSelect(event) {
    setBusinessType(event.target.value);
  }

  function getKycDoc(params = null) {
    return makeAPICall({
      path: "kyc/document",
      method: "GET",
      params,
    })
      .then((res) => {
        setKyc(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    const params = {
      type: businessType.toUpperCase(),
      country: selected,
    };
    getKycDoc(params);
  }, [businessType, selected]);

  const selectTitle = (selectedValue) => {
    let selectedOption;
    if (selectedValue) {
      selectedOption = options.find((option) => option.value === selectedValue);
      return selectedOption;
    } else {
      return selectedOption;
    }
  };

  // console.log(selectTitle(businessType));
  return (
    <div className="welcome-container">
      <div className="welcome-container-main">
        <div className="row mt-2">
          <div className="col-md-3">
            <ReactFlagsSelect
              className="form__select_flag"
              placeholder="Select your business country"
              selected={selected}
              countries={countryArray}
              onSelect={(code) => setSelected(code)}
              searchable="true"
            />
            <br />
            <select
              value={businessType}
              onChange={handleSelect}
              className="reg-select"
            >
              <option value="">Select an option</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-8 docs-welcome">
            <div className="mb-4">
              <h1 className="welcome-container-main-h1">Documents to submit</h1>
              <p>
                For you to begin transacting on Eversend and performing business
                activities, you will need to submit your business documents,
                ensure that you submit the right document
              </p>
            </div>

            <div>
              <h3>{selectTitle(businessType)?.label}</h3>
              <p>{selectTitle(businessType)?.title}</p>
              <h3>Required registration documents</h3>
              {kyc?.map(({ document, comment, id }) => (
                <div key={id}>
                  <AccordionDoc title={document} content={comment} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="welcome-container-footer">
        <div className="d-flex align-items-center justify-between">
          <p>&nbsp;</p>
          {user?.account?.kybStatus === "submitted" ||
          user?.account?.kybStatus === "verified" ? (
            <button
              type="submit"
              className="custom-modal__close-button"
              onClick={closeModalDone}
            >
              Onboarding Completed
            </button>
          ) : (
            <button
              type="submit"
              className="custom-modal__close-button"
              onClick={closeModalContinue}
            >
              Complete onboarding
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
