import ACTIONS from "../constants";

const initialState = {
  cards: [],
  error: null,
  loading: false,
  cardAnalytics: {},
  errorCard: null,
  loadingCard: false,
  controller: null, // Ensure you have a controller property in your initial state
};

const cardsReducer = (state = initialState, action) => {
  // console.log(action, 'try')
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_CARDS:
      return {
        ...state,
        controller: action.controller,
        error: null,
        loading: true,
      };
    case ACTIONS.FETCH_CARDS_SUCCESS:
      return {
        ...state,
        cards: payload,
        loading: false,
        controller: null,
      };
    case ACTIONS.FETCH_CARDS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        controller: null,
      };
    case ACTIONS.FETCH_CARDS_DEFAULT:
      return {
        ...state,
        controller: null,
        error: null,
        loading: false,
      };
    case ACTIONS.FETCH_CARD_ANALYTICS:
      return {
        ...state,
        errorCard: null,
        loadingCard: true,
      };
    case ACTIONS.FETCH_CARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        cardAnalytics: payload,
        loadingCard: false,
      };
    case ACTIONS.FETCH_CARD_ANALYTICS_ERROR:
      return {
        ...state,
        errorCard: payload,
        loadingCard: false,
      };
    default:
      return state;
  }
};

export default cardsReducer;
