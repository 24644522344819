import React, { useState, useEffect } from "react";
import PinInput from "react-pin-input";
import { ReactComponent as Success } from "../../../assets/images/confirm_success.svg";
import downArrow from "../../../assets/images/Chevron_down.svg";
import countries from "../../../utilities/countries.json";
import CountryPhoneInput from "../../../components/CountryPhoneInput";
import { useSelector } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";
import { message } from "antd";
import Loader from "../../../components/Loader";

export default function PhoneVerification({
  verified,
  formDetails,
  changePhone,
  emailVerified,
}) {
  const { sourceCountries } = useSelector((state) => state.CountryReducer);
  const countriesArray = sourceCountries.map((item) => item.code);
  const selectedCountries = countries.filter((country) =>
    countriesArray.includes(country.code)
  );
  const [showTextBox, setShowTextBox] = useState(false);
  const [phoneValue, setPhoneValue] = useState(formDetails?.phone);
  const [countryValue, setCountryValue] = useState(formDetails?.country);
  const [dialCodeValue, setDialCodeValue] = useState(formDetails?.dialCode);
  const [localValue, setLocalValue] = useState(formDetails?.localPhone);
  const [otpCodeView, setOtpCodeView] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [success, setSuccess] = useState(false);
  const defaultCountry = countries.find(
    (country) => country.code === formDetails?.country
  );
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pinId, setPinId] = useState("");

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, "0");
  const secondsFormatted = (timeLeft % 60).toString().padStart(2, "0");

  function handleInputPinChange(otpInput) {
    //makeApiCall(otpInput)
    const adjustedPhoneValue = phoneValue.startsWith("+")
      ? phoneValue.slice(1)
      : phoneValue;
    const data = {
      email: formDetails.email,
      phone: adjustedPhoneValue,
      pinId: pinId,
      code: otpInput,
    };
    setIsLoading(true);
    return makeAPICall({
      path: "auth/verifyPhone",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setIsLoading(false);
        setSuccess(true);
        setOtpCodeView(false);
        setShowTextBox(false);
        setTimeLeft();
        verified();
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message, 5);
      });
  }

  function resendOtp() {
    // makeApiCall()
    const adjustedPhoneValue = phoneValue.startsWith("+")
      ? phoneValue.slice(1)
      : phoneValue;
    setLoading(true);
    const data = {
      email: formDetails.email,
      phone: adjustedPhoneValue,
    };
    return makeAPICall({
      path: "auth/sendPhoneVerification",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setPinId(res.data.result.pinId);
        setTimeLeft(150);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  const allDetails = (details) => {
    // Remove the leading zero if present
    const adjustedValue = details?.phoneNumber.startsWith("0")
      ? details?.phoneNumber.slice(1)
      : details?.phoneNumber;
    setPhoneValue(`${details?.selectedCountry?.dial_code}${adjustedValue}`);
    setDialCodeValue(details?.selectedCountry?.dial_code);
    setLocalValue(adjustedValue);
    setCountryValue(details?.selectedCountry?.code);
    changePhone(details);
  };

  function sendPhoneCode() {
    // makeApiCall()
    const adjustedPhoneValue = phoneValue.startsWith("+")
      ? phoneValue.slice(1)
      : phoneValue;
    setLoading(true);
    const data = {
      email: formDetails.email,
      phone: adjustedPhoneValue,
    };
    return makeAPICall({
      path: "auth/sendPhoneVerification",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setPinId(res.data.result.pinId);
        setLoading(false);
        setTimeLeft(150);
        setOtpCodeView(true);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  return (
    <div className="verify-box">
      {success ? (
        <div className="d-flex">
          <Success />
          <p className="verify-otp-view-p" style={{ marginLeft: "10px" }}>
            Success! Your phone number has been verified.
          </p>
        </div>
      ) : (
        <div>
          {otpCodeView ? (
            <div className="verify-otp-view">
              <p className="verify-otp-view-p">
                We sent a verification code to (
                <span>{formDetails?.phone}</span>).
              </p>
              <p className="verify-otp-view-p">
                Please check your phone to proceed.
              </p>
              {isLoading ? (
                <button
                  style={{
                    background: "transparent",
                    outline: "none",
                    border: "none",
                    marginTop: "15px",
                  }}
                >
                  <span className="d-flex align-items-center justify-between">
                    <Loader dark={true} /> Verifying Phone Number
                  </span>
                </button>
              ) : (
                <PinInput
                  focus
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  inputStyle={{
                    border: "1px solid #CBCAE5",
                    borderRadius: "8px",
                    color: "#949494",
                  }}
                  length={6}
                  type="numeric"
                  onComplete={handleInputPinChange}
                />
              )}
              <hr />
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <button
                    className="verify-resend-otp-btn"
                    style={{ marginRight: "10px" }}
                    disabled={timeLeft !== 0}
                    onClick={resendOtp}
                  >
                    {loading ? (
                      <span className="d-flex align-items-center justify-between">
                        <Loader dark={true} /> Resending
                      </span>
                    ) : (
                      <span>Resend OTP {timeLeft ? "in" : null}</span>
                    )}
                  </button>
                  {timeLeft ? (
                    <h1 className="timer-class">
                      {minutes}:{secondsFormatted}
                    </h1>
                  ) : null}
                </div>
                {timeLeft ? null : (
                  <button
                    className="verify-resend-otp-btn"
                    onClick={() => {
                      setOtpCodeView(false);
                      setTimeLeft(0);
                      setShowTextBox(true);
                    }}
                  >
                    Re-enter Phone
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="verify-edit">
              <h1 className="verify-title">Phone Verification</h1>
              {showTextBox ? (
                <div className="d-flex justify-content-between">
                  <CountryPhoneInput
                    countries={selectedCountries}
                    defaultCountry={defaultCountry}
                    allDetails={allDetails}
                    phoneNumber={localValue}
                  />
                  <button
                    className="verify-confirm-btn"
                    onClick={() => {
                      setShowTextBox(false);
                    }}
                    disabled={!phoneValue}
                  >
                    Confirm
                  </button>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between email-edit-box">
                    <p className="verify-email-text">
                      {countryValue} ({dialCodeValue}) &nbsp;
                      <img
                        src={downArrow}
                        alt="downArrow"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      />
                      {localValue}
                    </p>
                    <button
                      className="verify-edit-btn"
                      onClick={() => {
                        setShowTextBox(true);
                      }}
                      disabled={!emailVerified}
                    >
                      Edit
                    </button>
                  </div>
                  <button
                    className="verify-btn"
                    onClick={sendPhoneCode}
                    disabled={!emailVerified}
                    type="submit"
                  >
                    {loading ? (
                      <span className="d-flex align-items-center justify-between">
                        <Loader dark={false} /> Verify phone number
                      </span>
                    ) : (
                      "Verify phone number"
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
