import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/Eversend conf.4 for white bcgrnd.svg";
import ArrowRight from "../../../assets/images/Arrow_right.svg";
import ArrowLeft from "../../../assets/images/Arrow_left.svg";
import bgImage from "../../../assets/images/B2B.png";
import heroImage from "../../../assets/images/no-business.svg";
import { useIntercom } from "react-use-intercom";
import "./signup.css";
import FirstForm from "./first";
import SecondForm from "./second";
import ThirdForm from "./third";
import FourthForm from "./fourth";
import FifthForm from "./fifth";
import { fetchSourceAction } from "../../../services/actions";
import { useDispatch } from "../../../utilities/hooks";
// import { maskMiddleDigits } from "../../../utilities/helperFunctions";

export default function SignupContainer() {
  const dispatch = useDispatch();
  const { boot } = useIntercom();
  boot({
    isB2B: "B2B",
  });
  const [noBusiness, setNoBusiness] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [step, setStep] = useState(1);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    localPhone: "",
    dialCode: "",
    password: "",
    country: "",
    haveBusiness: "",
    businessType: "",
    businessRegistered: "",
    businessCountry: "",
  });

  const goToNextStep = (password) => {
    setInputValues({
      ...inputValues,
      password: password,
    });
    setStep(step + 1);
  };

  function nextFirst(inputDetails) {
    setInputValues({
      firstName: inputDetails.firstName,
      lastName: inputDetails.lastName,
      email: inputDetails.email,
      phone: inputDetails.phone,
      localPhone: inputDetails.localPhone,
      dialCode: inputDetails.dialCode,
      country: inputDetails.country,
      haveBusiness: inputDetails.haveBusiness,
    });
    if (inputDetails.haveBusiness === "yes") {
      setStep(step + 1);
    } else {
      setNoBusiness(true);
    }
  }

  useEffect(() => {
    dispatch(fetchSourceAction());
  }, [dispatch]);

  function nextSecond(inputDetail) {
    setInputValues({
      ...inputValues,
      businessType: inputDetail,
    });
    setStep(step + 1);
  }

  function nextThird(selectedCountry, registeredBiz) {
    setInputValues({
      ...inputValues,
      businessCountry: selectedCountry,
      businessRegistered: registeredBiz,
    });
    setStep(step + 1);
  }

  const goToPreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div>
      {noBusiness ? (
        <div className="no-business-hero-section">
          <div className="text-column">
            <h1>Hi {inputValues?.firstName},</h1>
            {downloadLink ? (
              <div>
                <h2>Here's the download link</h2>
                {/* <p>
                  We sent a download link to your phone number &nbsp;
                  {maskMiddleDigits(inputValues?.phone)}
                </p> */}
                <p>
                  Please use &nbsp;
                  <a href="https://eversend.app.link">
                    https://eversend.app.link
                  </a>
                  &nbsp; to download the Eversend app.
                </p>
                <br />
                <a href="https://eversend.co" className="visit-site-second">
                  Visit website
                </a>
                {/* <h3>
                  Didn't get link? <span>Resend link</span>
                </h3> */}
              </div>
            ) : (
              <div>
                <h2>
                  We think that an Eversend mobile app account would be most
                  suitable for you
                </h2>
                <br />
                <p>Would you like to download Eversend mobile app?</p>
                <br />
                <button onClick={() => setDownloadLink(true)}>
                  Yes, Download Eversend app
                </button>
                <br />
                <a href="https://eversend.co" className="visit-site ">
                  Learn more on website &nbsp;
                  <img src={ArrowRight} alt="Eversend website" />
                </a>
              </div>
            )}
          </div>
          <div className="image-column">
            <img src={heroImage} alt="New Eversend App" />
          </div>
        </div>
      ) : (
        <div className="sign-container">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="login-link">
            <p>
              Already have an account? <Link to="/login">Log-in</Link>
            </p>
          </div>
          <div className="content">
            <div className="image-container">
              <div className="image-wrapper">
                <img src={bgImage} alt="Eversend people" />
                <div className="overlay"></div>
              </div>
            </div>
            <div className="form-container">
              <div className="form-details">
                {step === 1 || step === 5 ? null : (
                  <div
                    className="d-flex align-items-center"
                    style={{ marginBottom: "10px" }}
                  >
                    <button
                      className="back-button"
                      onClick={goToPreviousStep}
                      style={{ marginRight: "15px" }}
                    >
                      <img
                        src={ArrowLeft}
                        alt="back to previous"
                        style={{ marginRight: "10px", width: "20px" }}
                      />
                      Back
                    </button>
                    <div className="progress-bar">
                      <div
                        className="progress-step"
                        style={{ width: `${(step - 1) * 20}px` }}
                      />
                    </div>
                  </div>
                )}

                <div>
                  {step === 1 && (
                    <FirstForm
                      goToNextStep={nextFirst}
                      inputDetails={inputValues}
                      title={"Create an Eversend business account"}
                    />
                  )}
                  {step === 2 && (
                    <SecondForm
                      goToNextStep={nextSecond}
                      inputDetails={inputValues}
                      title={"Create an Eversend business account"}
                    />
                  )}
                  {step === 3 && (
                    <ThirdForm
                      goToNextStep={nextThird}
                      inputDetails={inputValues}
                      title={"Create an Eversend business account"}
                    />
                  )}
                  {step === 4 && (
                    <FourthForm
                      title="Set up a password"
                      inputDetails={inputValues}
                      goToNextStep={goToNextStep}
                    />
                  )}
                  {step === 5 && (
                    <FifthForm
                      title="One last thing
                      verify email and phone number"
                      inputDetails={inputValues}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
