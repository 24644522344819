import React from "react";
import { Card, Tabs, Row, Col } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";

import WalletLoader from "../../../../components/WalletsLoader";
import WalletItems from "../../../../components/Wallet/walletItem";
import { useSelector } from "../../../../utilities/hooks";

export default function AvailableAccountBalance({
  loading,
  wallets,
  showBal,
  user,
}) {
  //   console.log(wallets, 'tete');
  const { TabPane } = Tabs;

  const { inactiveWalletLoading } = useSelector(
    (state) => state.activateDeactiveReducer
  );
  const isActive = wallets?.some((item) => item?.isCrypto === true);

  const renderWallet = (entry, index) => {
    return (
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={8}
        xxl={6}
        className="single-wallet-in-list-container"
        key={index}
      >
        <WalletItems
          wallet={entry}
          index={index}
          showBal={showBal}
          key={_.get(entry, "currency", "")}
          wallets={wallets}
          user={user}
          loading={inactiveWalletLoading}
        />
      </Col>
    );
  };

  // console.log('Wallets: ', wallets);

  const areAllWalletFalse = wallets?.every((item) => !item.enabled);

  const renderWallets = (entries) => {
    const gutter = { xs: 8, sm: 8, md: 8, lg: 12 };

    return (
      <Row gutter={[gutter, gutter]}>
        {_.map(entries, (entry, index) => renderWallet(entry, index))}
      </Row>
    );
  };

  return (
    <Card className="dashboard-wallets-container">
      {loading === 1 ? (
        <WalletLoader />
      ) : (
        <div>
          <div className="dashboard-wallets-header-container">
            <h3>Available account balance</h3>
          </div>
          <div className="dashboard-wallets-list-outside-container">
            <Link to="/wallets">View all</Link>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Fiat" key="1">
                {areAllWalletFalse ? (
                  <p>
                    Please go to wallets page and activate a wallet,&nbsp;
                    <Link to="/wallets" style={{ color: "#5A50FE" }}>
                      click to activate
                    </Link>
                  </p>
                ) : (
                  <div>
                    {renderWallets(
                      _.remove(
                        _.concat([], wallets),
                        ({ isCrypto }) => isCrypto !== true
                      )
                    )}
                  </div>
                )}
              </TabPane>
              {isActive ? (
                <TabPane tab="Crypto" key="2">
                  {areAllWalletFalse ? (
                    <p>
                      Please go to wallets page and activate a wallet,
                      <Link to="/wallets" style={{ color: "#5A50FE" }}>
                        click to activate
                      </Link>
                    </p>
                  ) : (
                    <div>
                      {renderWallets(
                        _.remove(
                          _.concat([], wallets),
                          ({ isCrypto }) => isCrypto === true
                        )
                      )}
                    </div>
                  )}
                </TabPane>
              ) : (
                ""
              )}
              {/* <TabPane tab="Crypto" key="2">
                {renderWallets(
                  _.remove(
                    _.concat([], wallets),
                    ({ isCrypto }) => isCrypto === true
                  )
                )}
              </TabPane> */}
            </Tabs>
          </div>
        </div>
      )}
    </Card>
  );
}
