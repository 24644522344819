import ACTIONS from "../../constants";

const initialState = {
  errorMessage: null,
  loading: false,
  email: null,
  code: null,
  resetSuccessful: false,
};

const forgotPassword = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.RESET_FORGOT_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        email: null,
        code: null,
        resetSuccessful: false,
      };
    case ACTIONS.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        email: payload,
      };
    case ACTIONS.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.VERIFY_RESET_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.VERIFY_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload,
        resetSuccessful: false,
      };
    case ACTIONS.VERIFY_RESET_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetSuccessful: true,
      };
    case ACTIONS.RESET_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.PASSWORD_RESET:
      return {
        ...state,
        email: null,
        code: null,
        resetSuccessful: false,
      };
    default:
      return state;
  }
};

export default forgotPassword;
