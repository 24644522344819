import { Tabs } from "antd";
// import { useParams, useHistory } from "react-router-dom";
import SettingsProfile from "./pages/profileSettings";
import SettingsSecurity from "./pages/securitySettings";
import { useIntercom } from "react-use-intercom";
import { useSelector } from "../../utilities/hooks";
// import {
//   fetchProfileAction,
// } from "../../services/actions";

const { TabPane } = Tabs;

export default function SettingsGeneral({ match, history }) {
  const { boot } = useIntercom();
  
  const { user, loading} = useSelector((state) => state.profileReducer);
  // const dispatch = useDispatch();
  //   const { source } = useParams();
  //   const history = useHistory();

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    "isB2B": "B2B",
  });

  // useEffect(() => {
  //   dispatch(fetchProfileAction());
  // }, [dispatch]);

  return (
    <div>
      <div className="row"></div>
      <br />
      <div className="row">
        <div className="col-md-12 settings-tabs-settings">
          <Tabs
            activeKey={match.params.sourcepane ? match.params.sourcepane : "profile"}
            onChange={(key) => {
              history.push(`/settings-general/${key}`);
            }}
            tabBarGutter={0}
          >
            <TabPane tab="My Profile" key="profile">
              <SettingsProfile user={user} loading={loading} />
            </TabPane>
            <TabPane tab="Security" key="security">
              <SettingsSecurity user={user} />
            </TabPane>
           
          </Tabs>
        </div>
      </div>
    </div>
  );
}
