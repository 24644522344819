import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { DateFilterStyle } from "./style";
import {
  allMonth,
  getCurrentYear,
  getMonthName,
  generateYearsBetween,
} from "../../utilities/helperFunctions";
// import CustomReactSelect from "../CustomReactSelect";

const OneDateRange = ({ showDateFilter, children, ...props }) => {
  const years = generateYearsBetween(2021, getCurrentYear());

  // const yearOptions = years.map((year) => ({
  //   value: year,
  //   label: year,
  // }));

  // const monthOptions = allMonth.map((month, i) => ({
  //   value: i,
  //   label: month,
  // }));

  return (
    <DatePicker
      showPopperArrow={false}
      renderCustomHeader={({ date, changeYear, changeMonth }) => {
        const getYear = getCurrentYear(date);
        const getMonth = getMonthName(date);
        // console.log(getMonth, getYear, "testing")

        return (
          <div className="d-flex align-items-center justify-content-between mb-2">
            <select
              onChange={(e) => {
                changeMonth(allMonth.indexOf(e.target.value));
              }}
              placeholder={getMonth}
              value={getMonth}
              className="reg-select-date"
            >
              {allMonth.map((month, i) => (
                <option key={i} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                changeYear(e.target.value);
              }}
              value={getYear}
              // placeholder={getYear}
              className="reg-select-year"
            >
              {years.map((year, i) => (
                <option key={i} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {/* <CustomReactSelect
              options={yearOptions}
              placeholder={getYear}
              onChange={({ value }) => {
                changeYear(value);
              }}
            /> */}
          </div>
        );
      }}
      {...props}
    >
      {/* {showDateFilter ? (
        <DateFilterStyle>
          <p className="clear">Cancel</p>
          <p className="linethrough" />
          <p>Apply</p>
        </DateFilterStyle>
      ) : null}
      {children} */}
    </DatePicker>
  );
};

export default OneDateRange;
