import React from "react"
import ContentLoader from "react-content-loader"

const WalletLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={2000}
    height={300}
    viewBox="0 0 2000 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="61" y="63" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="40" cy="58" r="14" /> 
    <rect x="62" y="52" rx="0" ry="0" width="69" height="5" /> 
    <rect x="642" y="61" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="621" cy="56" r="14" /> 
    <rect x="643" y="50" rx="0" ry="0" width="69" height="5" /> 
    <rect x="361" y="65" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="340" cy="60" r="14" /> 
    <rect x="362" y="54" rx="0" ry="0" width="69" height="5" /> 
    <rect x="62" y="125" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="41" cy="120" r="14" /> 
    <rect x="63" y="114" rx="0" ry="0" width="69" height="5" /> 
    <rect x="643" y="123" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="622" cy="118" r="14" /> 
    <rect x="644" y="112" rx="0" ry="0" width="69" height="5" /> 
    <rect x="362" y="127" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="341" cy="122" r="14" /> 
    <rect x="363" y="116" rx="0" ry="0" width="69" height="5" /> 
    <rect x="62" y="188" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="41" cy="183" r="14" /> 
    <rect x="63" y="177" rx="0" ry="0" width="69" height="5" /> 
    <rect x="643" y="186" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="622" cy="181" r="14" /> 
    <rect x="644" y="175" rx="0" ry="0" width="69" height="5" /> 
    <rect x="362" y="190" rx="3" ry="3" width="41" height="5" /> 
    <circle cx="341" cy="185" r="14" /> 
    <rect x="363" y="179" rx="0" ry="0" width="69" height="5" />
  </ContentLoader>
)

export default WalletLoader