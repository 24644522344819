import React from "react";
import { Typography } from "antd";
import { ReactComponent as CopyIcon } from "../../../assets/images/copy-icon-used.svg";
// import moment from "moment";
import { maskCardMiddleNumbers } from "../../../utilities/helperFunctions";
import moment from "moment";

const { Paragraph } = Typography;

export default function CardNumbers({
  cardDetailsAll,
  showCardSummary,
  checkAccess,
  cardNumber,
  isCardDeleted,
  isCardFrozen,
}) {
  const isExpiryDate = (date) => {
    // Check if the expiration date is in the first format
    if (/^\d{4}$/.test(date)) {
      return date;
    } else {
      date = moment(date).format("MM/YY");
      return date;
    }
  };

  return (
    <div className="card-numbers-details">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h1>Card Id</h1>
          <p>{cardDetailsAll?.card?.id}</p>
        </div>
        <div className="div-card-details">
          <Paragraph
            copyable={{
              icon: [<CopyIcon key="copy-icon" />],
              text: cardDetailsAll?.card?.id,
            }}
          ></Paragraph>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h1>Number</h1>
          <p>
            {checkAccess("cards").granted
              ? !showCardSummary
                ? cardNumber(cardDetailsAll?.card?.number)
                : maskCardMiddleNumbers(
                    cardNumber(cardDetailsAll?.card?.number)
                  )
              : cardDetailsAll?.card?.mask}
          </p>
        </div>
        <div
          className="div-card-details"
          disabled={isCardFrozen || isCardDeleted}
        >
          <Paragraph
            copyable={{
              icon: [<CopyIcon key="copy-icon" />],
              text: cardDetailsAll?.card?.number,
            }}
          ></Paragraph>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div>
          <h1>Valid Thru</h1>
          <p>
            {checkAccess("cards").granted ? (
              <span>{isExpiryDate(cardDetailsAll?.card.expiration)}</span>
            ) : (
              "****"
            )}
          </p>
        </div>
        <div
          className="div-card-details"
          disabled={isCardFrozen || isCardDeleted}
        >
          <Paragraph
            copyable={{
              icon: [<CopyIcon key="copy-icon" />],
              text: cardDetailsAll?.card?.expiration,
            }}
          ></Paragraph>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div>
          <h1>CVV</h1>
          <p>
            {checkAccess("cards").granted
              ? !showCardSummary
                ? cardDetailsAll?.card?.securityCode
                : "***"
              : "***"}
          </p>
        </div>
        <div
          className="div-card-details"
          disabled={isCardFrozen || isCardDeleted}
        >
          <Paragraph
            copyable={{
              icon: [<CopyIcon key="copy-icon" />],
              text: cardDetailsAll?.card?.securityCode,
            }}
          ></Paragraph>
        </div>
      </div>
      {isCardDeleted || isCardFrozen ? null : (
        <div>
          <h2 className="mt-2 mb-2 billing-details">Billing address</h2>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Street</h1>
              <p>{cardDetailsAll?.card?.billingAddress?.address}</p>
            </div>
            <div className="div-card-details">
              <Paragraph
                copyable={{
                  icon: [<CopyIcon key="copy-icon" />],
                  text: cardDetailsAll?.card?.billingAddress?.address,
                }}
              ></Paragraph>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Town/City</h1>
              <p>{cardDetailsAll?.card?.billingAddress?.city}</p>
            </div>
            <div className="div-card-details">
              <Paragraph
                copyable={{
                  icon: [<CopyIcon key="copy-icon" />],
                  text: cardDetailsAll?.card?.billingAddress?.city,
                }}
              ></Paragraph>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>ZIP/Postal code</h1>
              <p>{cardDetailsAll?.card?.billingAddress?.zipCode}</p>
            </div>
            <div className="div-card-details">
              <Paragraph
                copyable={{
                  icon: [<CopyIcon key="copy-icon" />],
                  text: cardDetailsAll?.card?.billingAddress?.zipCode,
                }}
              ></Paragraph>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>State</h1>
              <p>{cardDetailsAll?.card?.billingAddress?.state}</p>
            </div>
            <div className="div-card-details">
              <Paragraph
                copyable={{
                  icon: [<CopyIcon key="copy-icon" />],
                  text: cardDetailsAll?.card?.billingAddress?.state,
                }}
              ></Paragraph>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Country</h1>
              <p>{cardDetailsAll?.card?.billingAddress?.country}</p>
            </div>
            <div className="div-card-details">
              <Paragraph
                copyable={{
                  icon: [<CopyIcon key="copy-icon" />],
                  text: cardDetailsAll?.card?.billingAddress?.country,
                }}
              ></Paragraph>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1>Phone number</h1>
            <p>{cardDetailsAll?.card?.status === "block" ? "*******" : "+1 609 257 4786"}</p>
          </div>
          <div className="div-card-details" disabled={cardDetailsAll?.card?.status === "block"}>
            <Paragraph
              copyable={{
                icon: [<CopyIcon key="copy-icon" />],
                text: "+16092574786",
              }}
            ></Paragraph>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
}
