import makeAPICall from "../../../utilities/apiUtils";
import {
  forgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
  forgotPasswordReset
} from "../../selectors";
import { message } from "antd";

const forgotPasswordAction = (data) => (dispatch) => {
  const { email } = data;
  dispatch(forgotPassword());
  return makeAPICall({
    path: "auth/forgotPassword",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      dispatch(forgotPasswordSuccess(email));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(forgotPasswordError(err.message));
    });
};

const forgotPasswordActionReset = () => (dispatch) => {
  dispatch(forgotPasswordReset());
};

export {forgotPasswordAction, forgotPasswordActionReset};
