// CustomDateInput.js
import React from "react";
import "./dateinput.css";
import { ReactComponent as CalenderIcon } from "../../assets/images/calendarIcon.svg";

const CustomDateInput = ({ value, onChange }) => {
  const handleDateChange = (event) => {
    const inputDate = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedDate = "";

    for (let i = 0; i < inputDate.length; i++) {
      formattedDate += inputDate[i];
      if ((i === 1 || i === 3) && i !== inputDate.length - 1) {
        formattedDate += "-";
      }
    }
    onChange(formattedDate);
  };
  return (
    <div className="custom-date-input">
      <CalenderIcon className="icon" />
      <input
        type="text"
        value={value}
        onChange={handleDateChange}
        placeholder="DD-MM-YYYY"
        maxLength="10"
      />
    </div>
  );
};

export default CustomDateInput;
