import React from "react";
import { Route, Switch } from "react-router-dom";
import _ from "lodash";
import DashboardOverview from "../pages/overview";
// import OverviewContainer from "../pages/overview/OverviewContainer";
import PayoutsContainer from "../pages/payouts/PayoutsContainer";
import PayoutsHistory from "../pages/payouts/payoutsHistory";
import { Dashboard } from "../components/dashboardLayout/Dashboard";
import RedirectToOverview from "../components/redirectToOverview";
import BeneficiariesContainer from "../pages/beneficiaries/BeneficiariesContainer";
import BeneficiarySingle from "../pages/beneficiaries/BeneficiarySingle";
import WalletsContainer from "../pages/wallets/WalletsContainer";
import CardsContainer from "../pages/cards/CardsContainer";
import CardsUsers from "../pages/cards/CardsUsers";
import SingleWallet from "../pages/wallets/singleWallet";
import SingleTransaction from "../pages/transactions/singleTransaction";
import TransactionsContainer from "../pages/transactions/TransactionsContainer";
import CryptoContainer from "../pages/crypto";
import SettingsContainer from "../pages/settings/SettingsContainer";
import SettingsGeneral from "../pages/settings/GeneralSettings";
import { getUser } from "../utilities/authUtils";
import ActivityLogs from "../pages/activityLogs";

const MainRoute = (props) => {
  const user = getUser();

  return (
    <div>
      <Dashboard>
        {!_.get(user, "account", "") ? (
          <DashboardOverview />
        ) : (
          <Switch>
            <Route exact path="/">
              <RedirectToOverview />
            </Route>
            <Route
              exact
              path="/overview"
              render={(routeProps) => (
                // <Suspense>
                <DashboardOverview {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path={"/overview/:id"}
              render={(routeProps) => (
                // <Suspense>
                <DashboardOverview {...props} {...routeProps} />
                // </Suspense>
              )}
            />

            <Route
              exact
              path="/transactions"
              render={(routeProps) => (
                // <Suspense>
                <TransactionsContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/transaction/details/:name"
              render={(routeProps) => (
                // <Suspense>
                <SingleTransaction {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/crypto"
              render={(routeProps) => (
                // <Suspense>
                <CryptoContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/cards"
              render={(routeProps) => (
                // <Suspense>
                <CardsContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/cards-users"
              render={(routeProps) => (
                // <Suspense>
                <CardsUsers {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/wallets"
              render={(routeProps) => (
                // <Suspense>
                <WalletsContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/wallets/currency/:name"
              render={(routeProps) => (
                // <Suspense>
                <SingleWallet {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              path="/payouts"
              render={(routeProps) => (
                // <Suspense>
                <PayoutsContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              path="/payouts-history"
              render={(routeProps) => (
                // <Suspense>
                <PayoutsHistory {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/beneficiaries/details/:id"
              render={(routeProps) => (
                // <Suspense>
                <BeneficiarySingle {...props} {...routeProps} />
                // </Suspense>
              )}
            />
            <Route
              exact
              path="/beneficiaries"
              render={(routeProps) => (
                // <Suspense>
                <BeneficiariesContainer {...props} {...routeProps} />
                // </Suspense>
              )}
            />

            <Route
              path={"/settings/:source?"}
              render={(routeProps) => (
                <SettingsContainer {...props} {...routeProps} />
              )}
            />
            <Route
              path={"/settings-general/:sourcepane?"}
              render={(routeProps) => (
                <SettingsGeneral {...props} {...routeProps} />
              )}
            />
            <Route
              path={"/logs"}
              render={(routeProps) => (
                <ActivityLogs {...props} {...routeProps} />
              )}
            />
          </Switch>
        )}
      </Dashboard>
    </div>
  );
};

export default MainRoute;
