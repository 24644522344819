import makeAPICall from "../../utilities/apiUtils";

import {
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesError,
  fetchDestinationCountries,
  fetchDestinationCountriesSuccess,
  fetchDestinationCountriesError,
  fetchSourceCountries,
  fetchSourceCountriesSuccess,
  fetchSourceCountriesError,
} from "../selectors";

const fetchCountryAction = () => (dispatch) => {
  // dispatch(fetchCountriesSuccess(data));
  dispatch(fetchCountries());
  return makeAPICall({
    path: "transactions/payouts/countries",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchCountriesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchCountriesError(err.message));
    });
};

const fetchDestinationAction = () => (dispatch) => {
  dispatch(fetchDestinationCountries());
  return makeAPICall({
    path: "auth/destinationCountry",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchDestinationCountriesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchDestinationCountriesError(err.message));
    });
};

const fetchSourceAction = () => (dispatch) => {
  dispatch(fetchSourceCountries());
  return makeAPICall({
    path: "auth/sourceCountry",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchSourceCountriesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchSourceCountriesError(err.message));
    });
};

export { fetchCountryAction, fetchDestinationAction, fetchSourceAction };
