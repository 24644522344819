import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../utilities/authUtils";

const PublicRouteCheck = ({ component: Component, title, ...rest }) => {

  useEffect(() => {
    document.title = `Eversend | ${title}`;
  }, [title]);
  
  return (<Route
    {...rest}
    render={(props) =>
      isLogin() === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/overview",
          }}
        />
      )
    }
  />)
};

export default PublicRouteCheck;
