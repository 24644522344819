import React, { useState } from "react";
import { Steps } from "antd";
import "../pay.css";
import StyledModal from "../../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Four";
import { useDispatch } from "../../../../utilities/hooks";
import {
  deletePayoutAction,
  deletePayoutQuotationAction,
  resetQuotationParams,
} from "../../../../services/actions";
// import { useHistory } from "react-router-dom";

export default function BeneficiaryModal({
  show,
  onClose,
  beneficiary = null,
  isFirstTime,
}) {
  const [current, setCurrent] = useState(0);
  const [showNow, setShowNow] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [transactionDetails, setTransactionDetails] = useState();
  const [currencyDetails, setCurrencyDetails] = useState();
  const [exchange, setExchange] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [payOutData, setPayOutData] = useState({});

  const dispatch = useDispatch();

  const [difference, setDifference] = useState({
    startTime: Date.now(),
    endTime: Date.now() + 30000,
  });

  const { Step } = Steps;

  const steps = [
    {
      title: "Choose beneficiary",
      content: (
        <First
          onClick={nextFirst}
          selectedBeneficiary={beneficiary}
          isFirstTime={isFirstTime}
        />
      ),
    },
    {
      title: "Payout details",
      content: (
        <Second
          onClick={nextSecond}
          paymentType={paymentType}
          profile={selectedBeneficiary}
          goBack={() => prev()}
          payOutData={payOutData}
          onSetPayOutData={(value) => setPayOutData(value)}
          onNumberChange={(value) => setDifference(value)}
        />
      ),
    },
    {
      title: "Review payout",
      content: (
        <Third
          currencyDetails={currencyDetails}
          profile={selectedBeneficiary}
          onClick={nextLast}
          paymentType={paymentType}
          goBack={() => prev()}
          difference={difference}
          payOutData={payOutData}
        />
      ),
    },
    {
      title: "Receipt",
      content: (
        <Fourth
          transDetails={transactionDetails}
          exchange={exchange}
          newPayout={newPayout}
          gotoHome={gotoHome}
        />
      ),
    },
  ];

  function resetInputs() {
    setShowNow(false);
    setCurrencyDetails();
    setTransactionDetails();
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
  }

  function nextFirst(paymentType, details) {
    setPaymentType(paymentType);
    setSelectedBeneficiary(details);
    setShowNow(true);
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
    setCurrent(current + 1);
  }

  function nextSecond(payoutQuotation) {
    // console.log(quotation, inputAmount);
    setCurrencyDetails(payoutQuotation);
    setShowNow(false);
    setCurrent(current + 1);
  }

  function nextLast(payout, optionTwo) {
    setTransactionDetails(payout);
    setExchange(optionTwo);
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
    dispatch(resetQuotationParams());
    dispatch(deletePayoutQuotationAction());
  }

  function onCloseModal(item) {
    setCurrent(item);
    dispatch(resetQuotationParams());
    resetInputs();
    onClose();
  }

  function newPayout() {
    dispatch(resetQuotationParams());
    resetInputs();
    // onClose()
    // dispatch(toggleSendMoneyBeneficiaryModalAction());
    setCurrent(0);
  }

  function gotoHome() {
    resetInputs();
    dispatch(resetQuotationParams());
    onClose();
    // history.push("/overview")
  }

  return (
    <StyledModal show={show} onClose={() => onCloseModal(0)}>
      <div className="p-4">
        <div className="payout-modal-text">
          <Steps
            direction="vertical"
            size="small"
            current={current}
            style={{ alignSelf: "flex-start" }}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>{steps[current].content}</div>
          <div className="selected">
            {showNow && (
              <div>
                <div className="grey-bg-name">
                  <div className="d-flex">
                    <div className="table-in">
                      {selectedBeneficiary.firstName.charAt(0) +
                        selectedBeneficiary.lastName.charAt(0)}
                    </div>
                    <p className="ml-auto">&nbsp;</p>
                  </div>
                  <br />
                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Beneficiary Name
                  </p>
                  <p className="benefit-name">
                    {selectedBeneficiary.firstName}&nbsp;
                    {selectedBeneficiary.lastName}
                  </p>
                  <br />
                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Payout type
                  </p>
                  <p className="benefit-name">{paymentType}</p>
                  <br />
                  {selectedBeneficiary.isBank && (
                    <>
                      <p
                        className="benefit-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Bank Name
                      </p>
                      <p className="benefit-name">
                        {selectedBeneficiary.bankName}
                      </p>
                      <br />
                      <p
                        className="benefit-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Bank Account Name
                      </p>
                      <p className="benefit-name">
                        {selectedBeneficiary.bankAccountName}
                      </p>
                      <br />
                      <p
                        className="benefit-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Bank Account Number
                      </p>
                      <p className="benefit-name">
                        {selectedBeneficiary.bankAccountNumber}
                      </p>
                    </>
                  )}
                  <br />
                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Phone number
                  </p>
                  <p className="benefit-name">
                    {selectedBeneficiary.phoneNumber}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
