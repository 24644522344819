import React from 'react';
import { EyeInvisibleOutlined, EyeOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import _ from 'lodash';

import { formatMoney } from '../../../../utilities/helperFunctions';

import SummaryLoader from '../../../../components/SummaryLoader';

export default function AccountValue({ summary, wallets, accountLoader, showBal, handleShowBalance }) {
	const walletIcon = wallets.filter(wallet => {
		return wallet.currency === _.get(summary, 'currency', '');
	});

	return (
		<Card className='dashboard-overview-summary-card'>
			{accountLoader === 1 ? (
				<SummaryLoader />
			) : (
				<div>
					<div>
						<span>
							All accounts value in {_.get(summary, 'currency', '')}: &nbsp;&nbsp;
							<span onClick={handleShowBalance} className='cursor-pointer'>
								{showBal ? <EyeOutlined /> : <EyeInvisibleOutlined />}
							</span>
						</span>
						<div className={`${!showBal ? 'blur' : ''}`}>
							<span>{_.get(summary, 'currency', '')}</span>
							<span>{!showBal ? '*********' : formatMoney(_.get(summary, 'availableAmount', 0))}</span>
							<span className='ant-avatar ant-avatar-circle ant-avatar-image avatar-normal'>
								<img src={_.get(walletIcon[0], 'icon', '')} alt={_.get(summary, 'currency', '')} />
							</span>
						</div>
					</div>
					<div>
						<span>
							Ledger balance &nbsp;
							<Tooltip title='This includes money that has not been settled yet into your available balance.'>
								<QuestionCircleFilled />
							</Tooltip>
						</span>
						<div className={`${!showBal ? 'blur' : ''}`}>
							<span>{_.get(summary, 'currency', '')}</span>
							<span>{!showBal ? '*********' : formatMoney(_.get(summary, 'amount', 0))}</span>
						</div>
					</div>
				</div>
			)}
		</Card>
	);
}
