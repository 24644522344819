import React from "react";
import { ArrowsAltOutlined } from "@ant-design/icons";

export default function MinimizedModal({ children, maximize }) {
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          zIndex: "1000",
          height: "10rem",
          width: "30rem",
          background: "white",
          boxShadow:
            "0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)",
          borderRadius: "4px",
        }}
        className="pl-5 pt-3 pr-5"
      >
        <div className="flex justify-content-between">
          <h5>
            <b> Uploading file </b>
          </h5>

          <ArrowsAltOutlined onClick={maximize} style={{ fontSize: "24px" }} />
        </div>
        <p>This should take only a few minutes</p>
        {children}
      </div>
    </>
  );
}
