export const AUTH_TOKEN = "__API_TOKEN__";
export const SESSION_HASH = "__SESSION_HASH__";
export const USER_DATA = "__USER_DATA__";
export const PERSIST = "persist:counter";
export const REDIRECT_URL = "__REDIRECT_URL__";
export const VERIFICATION_EMAIL = "__VERIFICATION_EMAIL__";
export const PASSWORD = "__PASSWORD__";
export const ACTIONS = {
  ALERT_WARN: "ALERT_WARN",
  ALERT_ERROR: "ALERT_ERROR",
  ALERT_SUCCESS: "ALERT_SUCCESS",
  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_ERROR: "REGISTER_USER_ERROR",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  VERIFICATION_EMAIL: "VERIFICATION_EMAIL",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  TWO_FACTOR_USER: " TWO_FACTOR_USER",
  TWO_FACTOR_USER_ERROR: " TWO_FACTOR_USER_ERROR",
  TWO_FACTOR_USER_SUCCESS: " TWO_FACTOR_USER_SUCCESS",
  ADMIN_PROFILE: "ADMIN_PROFILE",
  ADMIN_PROFILE_ERROR: "ADMIN_PROFILE_ERROR",
  ADMIN_PROFILE_SUCCESS: "ADMIN_PROFILE_SUCCESS",
  BUSINESS_INFO: "BUSINESS_INFO",
  BUSINESS_INFO_ERROR: "BUSINESS_INFO_ERROR",
  BUSINESS_INFO_SUCCESS: "BUSINESS_INFO_SUCCESS",
  BUSINESS_INFO_RESET: "BUSINESS_INFO_RESET",
  BUSINESS_INFO_GET: "BUSINESS_INFO_GET",
  BUSINESS_INFO_GET_ERROR: "BUSINESS_INFO_GET_ERROR",
  BUSINESS_INFO_GET_SUCCESS: "BUSINESS_INFO_GET_SUCCESS",
  BUSINESS_KYB: "BUSINESS_KYB",
  BUSINESS_KYB_ERROR: "BUSINESS_KYB_ERROR",
  BUSINESS_KYB_SUCCESS: "BUSINESS_KYB_SUCCESS",
  SEND_PHONE_CODE: "SEND_PHONE_CODE",
  RESET_SEND_PHONE_CODE: "RESET_SEND_PHONE_CODE",
  SEND_PHONE_CODE_ERROR: "SEND_PHONE_CODE_ERROR",
  SEND_PHONE_CODE_SUCCESS: "SEND_PHONE_CODE_SUCCESS",
  VERIFY_PHONE_CODE: "VERIFY_PHONE_CODE",
  VERIFY_PHONE_CODE_ERROR: "VERIFY_PHONE_CODE_ERROR",
  RESET_VERIFY_PHONE_CODE: "RESET_VERIFY_PHONE_CODE",
  VERIFY_PHONE_CODE_SUCCESS: "VERIFY_PHONE_CODE_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",
  VERIFY_USER: "VERIFY_USER",
  VERIFY_USER_ERROR: "VERIFY_USER_ERROR",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  FETCH_USER: "FETCH_USER",
  FETCH_USER_ERROR: "FETCH_USER_ERROR",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_IP_ADDRESS: "FETCH_IP_ADDRESS",
  FETCH_IP_ADDRESS_ERROR: "FETCH_IP_ADDRESS_ERROR",
  FETCH_IP_ADDRESS_SUCCESS: "FETCH_IP_ADDRESS_SUCCESS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  PASSWORD_RESET: "PASSWORD_RESET",
  VERIFY_RESET_PASSWORD: "VERIFY_RESET_PASSWORD",
  VERIFY_RESET_PASSWORD_ERROR: "VERIFY_RESET_PASSWORD_ERROR",
  VERIFY_RESET_PASSWORD_SUCCESS: "VERIFY_RESET_PASSWORD_SUCCESS",
  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  FETCH_WALLETS: "FETCH_WALLETS",
  FETCH_WALLETS_ERROR: "FETCH_WALLETS_ERROR",
  FETCH_WALLETS_SUCCESS: "FETCH_WALLETS_SUCCESS",
  FETCH_CARDS: "FETCH_CARDS",
  FETCH_CARDS_ERROR: "FETCH_CARDS_ERROR",
  FETCH_CARDS_DEFAULT: "FETCH_CARDS_DEFAULT",
  FETCH_CARDS_SUCCESS: "FETCH_CARDS_SUCCESS",
  FETCH_CARD_ANALYTICS: "FETCH_CARD_ANALYTICS",
  FETCH_CARD_ANALYTICS_ERROR: "FETCH_CARD_ANALYTICS_ERROR",
  FETCH_CARD_ANALYTICS_SUCCESS: "FETCH_CARD_ANALYTICS_SUCCESS",
  FETCH_TRANSACTIONS: "FETCH_TRANSACTIONS",
  FETCH_TRANSACTIONS_ERROR: "FETCH_TRANSACTIONS_ERROR",
  FETCH_TRANSACTIONS_SUCCESS: "FETCH_TRANSACTIONS_SUCCESS",
  FETCH_CRYPTO_TRANSACTIONS: "FETCH_CRYPTO_TRANSACTIONS",
  FETCH_CRYPTO_TRANSACTIONS_ERROR: "FETCH_CRYPTO_TRANSACTIONS_ERROR",
  FETCH_CRYPTO_TRANSACTIONS_SUCCESS: "FETCH_CRYPTO_TRANSACTIONS_SUCCESS",
  FETCH_CARD_TRANSACTIONS: "FETCH_CARD_TRANSACTIONS",
  FETCH_CARD_TRANSACTIONS_ERROR: "FETCH_CARD_TRANSACTIONS_ERROR",
  FETCH_CARD_TRANSACTIONS_SUCCESS: "FETCH_CARD_TRANSACTIONS_SUCCESS",
  FETCH_CRYPTO_ADDRESSES: "FETCH_CRYPTO_ADDRESSES",
  FETCH_CRYPTO_ADDRESSES_ERROR: "FETCH_CRYPTO_ADDRESSES_ERROR",
  FETCH_CRYPTO_ADDRESSES_SUCCESS: "FETCH_CRYPTO_ADDRESSES_SUCCESS",
  FETCH_BENEFICIARIES: "FETCH_BENEFICIARIES",
  FETCH_BENEFICIARIES_ERROR: "FETCH_BENEFICIARIES_ERROR",
  FETCH_BENEFICIARIES_SUCCESS: "FETCH_BENEFICIARIES_SUCCESS",
  FETCH_SINGLE_BENEFICIARY: "FETCH_SINGLE_BENEFICIARY",
  FETCH_SINGLE_BENEFICIARY_ERROR: "FETCH_SINGLE_BENEFICIARY_ERROR",
  FETCH_SINGLE_BENEFICIARY_SUCCESS: "FETCH_SINGLE_BENEFICIARY_SUCCESS",
  ADD_BENEFICIARIES: "ADD_BENEFICIARIES",
  ADD_BENEFICIARIES_ERROR: "ADD_BENEFICIARIES_ERROR",
  ADD_BENEFICIARIES_SUCCESS: "ADD_BENEFICIARIES_SUCCESS",
  ADD_BULK_BENEFICIARIES: "ADD_BULK_BENEFICIARIES",
  DELETE_SINGLE_BENEFICIARIES: "DELETE_SINGLE_BENEFICIARIES",
  DELETE_ALL_BENEFICIARIES: "DELETE_ALL_BENEFICIARIES",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  ADD_USER: "ADD_USER",
  ADD_USER_ERROR: "ADD_USER_ERROR",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_IP_ADDRESS: "ADD_IP_ADDRESS",
  ADD_IP_ADDRESS_ERROR: "ADD_IP_ADDRESS_ERROR",
  ADD_IP_ADDRESS_SUCCESS: "ADD_IP_ADDRESS_SUCCESS",
  DELETE_IP_ADDRESS: "DELETE_IP_ADDRESS",
  DELETE_IP_ADDRESS_ERROR: "DELETE_IP_ADDRESS_ERROR",
  DELETE_IP_ADDRESS_SUCCESS: "DELETE_IP_ADDRESS_SUCCESS",
  CREATE_EXCHANGE_QUOTATION: "CREATE_EXCHANGE_QUOTATION",
  CREATE_EXCHANGE_QUOTATION_ERROR: "CREATE_EXCHANGE_QUOTATION_ERROR",
  CREATE_EXCHANGE_QUOTATION_SUCCESS: "CREATE_EXCHANGE_QUOTATION_SUCCESS",
  CREATE_PAYOUT: "CREATE_PAYOUT",
  DELETE_PAYOUT: "DELETE_PAYOUT",
  DELETE_PAYOUT_QUOTATION: "DELETE_PAYOUT_QUOTATION",
  CREATE_PAYOUT_ERROR: "CREATE_PAYOUT_ERROR",
  CREATE_PAYOUT_SUCCESS: "CREATE_PAYOUT_SUCCESS",
  CREATE_PAYOUT_QUOTATION: "CREATE_PAYOUT_QUOTATION",
  CREATE_PAYOUT_QUOTATION_ERROR: "CREATE_PAYOUT_QUOTATION_ERROR",
  CREATE_PAYOUT_QUOTATION_SUCCESS: "CREATE_PAYOUT_QUOTATION_SUCCESS",
  SUBMITTED_QUOTATION_SUCCESS: "SUBMITTED_QUOTATION_SUCCESS",
  SUBMITTED_QUOTATION_ERROR: "SUBMITTED_QUOTATION_ERROR",
  SUBMITTED_QUOTATION: "SUBMITTED_QUOTATION",
  FETCH_BANKS: "FETCH_BANKS",
  FETCH_BANKS_ERROR: "FETCH_BANKS_ERROR",
  FETCH_BANKS_SUCCESS: "FETCH_BANKS_SUCCESS",
  FETCH_COUNTRY: "FETCH_COUNTRY",
  FETCH_COUNTRY_ERROR: "FETCH_COUNTRY_ERROR",
  FETCH_COUNTRY_SUCCESS: "FETCH_COUNTRY_SUCCESS",
  FETCH_DESTINATION_COUNTRY: "FETCH_DESTINATION_COUNTRY",
  FETCH_DESTINATION_COUNTRY_ERROR: "FETCH_DESTINATION_COUNTRY_ERROR",
  FETCH_DESTINATION_COUNTRY_SUCCESS: "FETCH_DESTINATION_COUNTRY_SUCCESS",
  FETCH_SOURCE_COUNTRY: "FETCH_SOURCE_COUNTRY",
  FETCH_SOURCE_COUNTRY_ERROR: "FETCH_SOURCE_COUNTRY_ERROR",
  FETCH_SOURCE_COUNTRY_SUCCESS: "FETCH_SOURCE_COUNTRY_SUCCESS",
  FETCH_ROLE: "FETCH_ROLE",
  FETCH_ROLE_SUCCESS: "FETCH_ROLE_SUCCESS",
  FETCH_ROLE_ERROR: "FETCH_ROLE_ERROR",
  FETCH_LOGS: "FETCH_LOGS",
  FETCH_LOGS_SUCCESS: "FETCH_LOGS_SUCCESS",
  FETCH_LOGS_ERROR: "FETCH_LOGS_ERROR",
  RESET_QUOTATION_STATE: "RESET_QUOTATION_STATE",
  FETCH_ANALYTICS_BALANCE: "FETCH_ANALYTICS_BALANCE",
  FETCH_ANALYTICS_BALANCE_ERROR: "FETCH_ANALYTICS_BALANCE_ERROR",
  FETCH_ANALYTICS_BALANCE_SUCCESS: "FETCH_ANALYTICS_BALANCE_SUCCESS",
  FETCH_ANALYTICS_TRANSACTIONS: "FETCH_ANALYTICS_TRANSACTIONS",
  FETCH_ANALYTICS_TRANSACTIONS_ERROR: "FETCH_ANALYTICS_TRANSACTIONS_ERROR",
  FETCH_ANALYTICS_TRANSACTIONS_SUCCESS: "FETCH_ANALYTICS_TRANSACTIONS_SUCCESS",
  FETCH_ACTIVATE: "FETCH_ACTIVATE",
  FETCH_ACTIVATE_ERROR: "FETCH_ACTIVATE_ERROR",
  FETCH_ACTIVATE_SUCCESS: "FETCH_ACTIVATE_SUCCESS",
  FETCH_DEACTIVATE: "FETCH_DEACTIVATE",
  FETCH_DEACTIVATE_ERROR: "FETCH_DEACTIVATE_ERROR",
  FETCH_DEACTIVATE_SUCCESS: "FETCH_DEACTIVATE_SUCCESS",
  FETCH_REORDER: "FETCH_REORDER",
  FETCH_REORDER_ERROR: "FETCH_REORDER_ERROR",
  FETCH_REORDER_SUCCESS: "FETCH_REORDER_SUCCESS",
  FETCH_STATEMENT: "FETCH_STATEMENT",
  DELETE_STATEMENT: "DELETE_STATEMENT",
  FETCH_STATEMENT_ERROR: "FETCH_STATEMENT_ERROR",
  FETCH_STATEMENT_SUCCESS: "FETCH_STATEMENT_SUCCESS",
  FETCH_SINGLE_STATEMENT: "FETCH_SINGLE_STATEMENT",
  FETCH_SINGLE_STATEMENT_ERROR: "FETCH_SINGLE_STATEMENT_ERROR",
  FETCH_SINGLE_STATEMENT_SUCCESS: "FETCH_SINGLE_STATEMENT_SUCCESS",
  CHECK_SINGLE_BENEFICIARIES: "CHECK_SINGLE_BENEFICIARIES",
  CHECK_ALL_BENEFICIARIES: "CHECK_ALL_BENEFICIARIES",
  UNCHECK_SINGLE_BENEFICIARIES: "UNCHECK_SINGLE_BENEFICIARIES",
  UNCHECK_ALL_BENEFICIARIES: "UNCHECK_ALL_BENEFICIARIES",
  EDIT_SINGLE_BENEFICIARY: "EDIT_SINGLE_BENEFICIARY",
  DELETE_SINGLE_BENEFICIARIES_BY_INDEX: "DELETE_SINGLE_BENEFICIARIES_BY_INDEX",
  HIDE_UPLOAD_BENEFICIARY: "HIDE_UPLOAD_BENEFICIARY",
  SHOW_UPLOAD_BENEFICIARY: "SHOW_UPLOAD_BENEFICIARY",
  PAUSE_RESUME_BULK_UPLOAD_BENEFICIARIES:
    "PAUSE_RESUME_BULK_UPLOAD_BENEFICIARIES",
  MARK_BULK_BENEFICIARY_ENTRY_UPLOAD_STATUS:
    "MARK_BULK_BENEFICIARY_ENTRY_UPLOAD_STATUS",
  CURRENT_UPLOAD_BENEFICIARY: "CURRENT_UPLOAD_BENEFICIARY",
  PERCENTAGE_UPLOAD_BENEFICIARY: "PERCENTAGE_UPLOAD_BENEFICIARY",
  COMPLETED_STATUS_UPLOAD_BENEFICIARY: "COMPLETED_STATUS_UPLOAD_BENEFICIARY",
  RESET_UPLOAD_BENEFICIARY: "RESET_UPLOAD_BENEFICIARY",
  TOGGLE_EXCHANGE_MODAL: "TOGGLE_EXCHANGE_MODAL",
  TOGGLE_ADDMONEY_MODAL: "TOGGLE_ADDMONEY_MODAL",
  TOGGLE_SENDMONEY_MODAL: "TOGGLE_SENDMONEY_MODAL",
  TOGGLE_CREATECARD_MODAL: "TOGGLE_CREATECARD_MODAL",
  TOGGLE_SENDMONEY_BENEFICIARY_MODAL: "TOGGLE_SENDMONEY_BENEFICIARY_MODAL",
  CHANGE_API_SECRET: "CHANGE_API_SECRET",
  ADD_MONEY: "ADD_MONEY",
  ADD_MONEY_ERROR: "ADD_MONEY_ERROR",
  ADD_MONEY_SUCCESS: "ADD_MONEY_SUCCESS",
  FETCH_USER_BANKS: "FETCH_USER_BANKS",
  FETCH_USER_BANKS_ERROR: "FETCH_USER_BANKS_ERROR",
  FETCH_USER_BANKS_SUCCESS: "FETCH_USER_BANKS_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  RESET_CHANGE_PASSWORD: "RESET_CHANGE_PASSWORD",
  FETCH_USER_CRYPTO: "FETCH_USER_CRYPTO",
  FETCH_USER_CRYPTO_ERROR: "FETCH_USER_CRYPTO_ERROR",
  FETCH_USER_CRYPTO_SUCCESS: "FETCH_USER_CRYPTO_SUCCESS",
  RESET_FETCH_USER_CRYPTO: "RESET_FETCH_USER_CRYPTO",
  FETCH_CHAIN_CRYPTO: "FETCH_CHAIN_CRYPTO",
  FETCH_CHAIN_CRYPTO_ERROR: "FETCH_CHAIN_CRYPTO_ERROR",
  FETCH_CHAIN_CRYPTO_SUCCESS: "FETCH_CHAIN_CRYPTO_SUCCESS",
  RESET_FETCH_CHAIN_CRYPTO: "RESET_FETCH_CHAIN_CRYPTO",
  PERFORM_GLOBAL_SEARCH_SUCCESS: "PERFORM_GLOBAL_SEARCH_SUCCESS",
  SET_GLOBAL_SEARCH_QUERY: "SET_GLOBAL_SEARCH_QUERY",
  SET_NEW_FORM_FIELDS: "SET_NEW_FORM_FIELDS",
  RESET_NEW_FORM_FIELDS: "RESET_NEW_FORM_FIELDS",
};
