import React from 'react';
import _ from 'lodash';

const ChartLoader = props => {
	let legend = _.get(props, 'legend', null);

	if (!legend) {
		legend = [ 'Collections', 'Payouts' ];
	}

	return (
		<div className='dashboard-chart-loader-container'>
			<div>
				<span>0</span>
				<div>
					<ul>
						<li />
						<li />
						<li />
						<li />
					</ul>
					<span>Not enough data to render your graph for the selected period.</span>
				</div>
			</div>
			<div className='dashboard-chart-loader-legend-container'>{_.map(legend, (entry, index) => <span key={index}>{entry}</span>)}</div>
		</div>
	);
};

export default ChartLoader;
