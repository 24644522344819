import React, { useState, useEffect } from "react";
import { validateEmail } from "../../../utilities/validations";
import { forgotPasswordAction } from "../../../services/actions";
import { useDispatch, useSelector } from "../../../utilities/hooks";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import back from "../../../assets/images/back-button.svg";
import Loader from "../../../components/Loader";
import {
  forgotPasswordReset,
  resetForgotPasswordState,
} from "../../../services/selectors";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_forgot_password_btn",
  },
};

export default function ForgotPasswordView() {
  const navigate = useHistory();
  const { loading } = useSelector((state) => state.forgotPasswordReducer);

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const dispatch = useDispatch();

  function handleChange(event) {
    setEmail(event.target.value.trim());
  }

  function handleSubmit() {
    TagManager.dataLayer(tagManagerArgs);
    dispatch(forgotPasswordAction({ email }));
  }

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  useEffect(() => {
    dispatch(resetForgotPasswordState());
    dispatch(forgotPasswordReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: "10px" }}>
      <div className="form-group">
        <label htmlFor="email" className="label-form">
          Email address
        </label>

        <input
          type="email"
          placeholder="eg. johndoe@gmail.com"
          value={email}
          onChange={handleChange}
          className="auth-input"
        />
      </div>
      <br />
      <button
        type="submit"
        disabled={!email.trim() || !isValidEmail}
        // disabled={email === "frank@eversend.co" ? false : true}
        onClick={handleSubmit}
        className="submit-btn"
      >
        {loading ? (
          <span className="d-flex align-items-center justify-between">
            <Loader dark={false} />
          </span>
        ) : (
          "Reset password"
        )}
      </button>
      <br />
      <br />
      <div className="text-center">
        <button
          className="external-link"
          onClick={() => navigate.push("/login")}
        >
          <img src={back} alt="back to login" style={{ marginRight: "10px" }} />
          Back to sign in
        </button>
      </div>
    </div>
  );
}
