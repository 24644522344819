import React, { useState, useEffect } from "react";
import identifyImg from "../../../assets/images/illustration.svg";
import TagManager from "react-gtm-module";

const tagManagerArgsMati = {
  dataLayer: {
    event: "b2b_start_mati_identity_verification",
  },
};

export default function MetaMap({ user, handleNextTab }) {
  const [stateRef, setStateRef] = useState();
  const [matiView, setMatiView] = useState(true);

  useEffect(() => {
    if (stateRef) {
      stateRef.addEventListener("metamap:loaded", ({ detail }) => {
        TagManager.dataLayer(tagManagerArgsMati);
      });
      stateRef.addEventListener("metamap:userFinishedSdk", ({ detail }) => {
        setMatiView(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef]);

  useEffect(() => {
    if (user?.phoneVerified && user?.matiVerification === "none") {
      setMatiView(true);
    } else if (
      user?.phoneVerified &&
      user?.matiVerification === "verification_inputs_completed"
    ) {
      setMatiView(false);
    } else if (
      user.phoneVerified &&
      user?.matiVerification === "verification_completed" &&
      user?.matiStatus === "verified"
    ) {
      // if (localStorage.getItem("MATI_VERIFIED") === true) {
      //   console.log("mati verified");
      // } else {
      //   const clientId = localStorage.getItem("GA_LOCAL_STORAGE_KEY");
      //   if (clientId) {
      //     const payload = {
      //       client_id: localStorage.getItem("GA_LOCAL_STORAGE_KEY"),
      //       user_id: user?.email,
      //       // timestamp_micros: "1659510858899000",
      //       non_personalized_ads: false,
      //       events: [
      //         {
      //           name: "b2b_mati_identity_verified",
      //           params: { user_id: user?.email },
      //         },
      //       ],
      //     };
      //     googleAnalytics(payload);
      //   }
      // }
      handleNextTab(0);
    } else {
      setMatiView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="meta-map-onboard">
          {matiView ? (
            <div>
              <div className="text-center">
                <img src={identifyImg} alt="meta map" />
              </div>

              <h1>Individual proof of identity</h1>
              <p>
                To continue your registration process, please provide proof of
                your identity
              </p>
            </div>
          ) : (
            <div>
              <div className="text-center">
                <img src={identifyImg} alt="meta map" />
              </div>

              <h1>Individual proof of identity</h1>
              <p>
                We are currently reviewing your uploaded document(s). Once
                review is completed you will get an email notification.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="text-right">
        {matiView ? (
          <div className="mt-3">
            <metamap-button
              ref={(el) => setStateRef(el)}
              clientid={process.env.REACT_APP_METAMAP_CLIENT_ID}
              flowId={process.env.REACT_APP_METAMAP_FLOW_ID}
              color="#594FFE"
              metadata={`{"user": "${user.email}","isB2B":"true", "isTest":${
                process.env.REACT_APP_BACKEND_URL ===
                "https://staging-business-api.eversend.co/v1"
                  ? `true`
                  : `false`
              }}`}
            />
          </div>
        ) : (
          <button
            type="submit"
            className="custom-modal__close-button"
            disabled={true}
          >
            Verification in progress
          </button>
        )}
      </div>
    </div>
  );
}
