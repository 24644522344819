import makeAPICall from "../../utilities/apiUtils";
import {
  fetchUserBanks,
  fetchUserBanksError,
  fetchUserBanksSuccess,
} from "../selectors";

const fetchUserBanksAction = () => (dispatch) => {
  dispatch(fetchUserBanks());
  return makeAPICall({
    path: "transactions/collections/bankAccounts",
    method: "GET",
  })
    .then((res) => {
      const { data } = res;
      dispatch(fetchUserBanksSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchUserBanksError(err.message));
    });
};

export default fetchUserBanksAction;
