import * as _ from "lodash";
import ACTIONS from "../constants";

const initialState = {
  beneficiaries: [],
  singleBeneficiary: null,
  errorMessage: null,
  loading: false,
  bulkUpload: {
    showModal: false,
    paused: false,
    percentage: 0,
    currentCount: 1,
    isCompleted: true,
    entries: {
      toProcess: [],
      failed: [],
      success: [],
    },
  },
};

const beneficiariesReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_BENEFICIARIES:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        beneficiaries: payload,
        loading: false,
      };
    case ACTIONS.FETCH_SINGLE_BENEFICIARY:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_SINGLE_BENEFICIARY_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.FETCH_SINGLE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        singleBeneficiary: payload,
        loading: false,
      };
    case ACTIONS.FETCH_BENEFICIARIES_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.ADD_BENEFICIARIES:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.ADD_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        // beneficiaries: payload,
        loading: false,
      };
    case ACTIONS.ADD_BENEFICIARIES_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.ADD_BULK_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: payload,
          },
        },
      };
    case ACTIONS.DELETE_SINGLE_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.filter(
                (beneficiary, index) => !payload.includes(index)
              ),
            ],
          },
        },
      };
    case ACTIONS.DELETE_SINGLE_BENEFICIARIES_BY_INDEX:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.filter(
                (beneficiary) => beneficiary.index === payload
              ),
            ],
          },
        },
      };
    case ACTIONS.DELETE_ALL_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [],
          },
        },
      };

    case ACTIONS.CHECK_SINGLE_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.map((beneficiary, index) =>
                index === payload
                  ? {
                      ...beneficiary,
                      checked: true,
                    }
                  : beneficiary
              ),
            ],
          },
        },
      };
    case ACTIONS.CHECK_ALL_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.map((beneficiary) => ({
                ...beneficiary,
                checked: true,
              })),
            ],
          },
        },
      };
    case ACTIONS.UNCHECK_SINGLE_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.map((beneficiary, index) =>
                index === payload
                  ? {
                      ...beneficiary,
                      checked: false,
                    }
                  : beneficiary
              ),
            ],
          },
        },
      };
    case ACTIONS.UNCHECK_ALL_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.map((beneficiary) => ({
                ...beneficiary,
                checked: false,
              })),
            ],
          },
        },
      };
    case ACTIONS.EDIT_SINGLE_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: [
              ...state.bulkUpload.entries.toProcess.map((beneficiary) =>
                beneficiary.index === payload.index
                  ? {
                      ...beneficiary,
                      ...payload,
                    }
                  : beneficiary
              ),
            ],
          },
        },
      };
    case ACTIONS.SHOW_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          showModal: true,
        },
      };
    case ACTIONS.HIDE_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          showModal: false,
        },
      };
    case ACTIONS.CURRENT_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          currentCount: payload,
        },
      };
    case ACTIONS.PERCENTAGE_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          percentage: payload,
        },
      };
    case ACTIONS.COMPLETED_STATUS_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          isCompleted: true,
        },
      };
    case ACTIONS.RESET_UPLOAD_BENEFICIARY:
      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          paused: false,
          percentage: 0,
          currentCount: 1,
          isCompleted: false,
          entries: {
            ...state.bulkUpload.entries,
            failed: [],
            success: [],
          },
        },
      };
    case ACTIONS.PAUSE_RESUME_BULK_UPLOAD_BENEFICIARIES:
      return {
        ...state,
        bulkUpload: { ...state.bulkUpload, paused: !state.bulkUpload.paused },
      };

    case ACTIONS.MARK_BULK_BENEFICIARY_ENTRY_UPLOAD_STATUS:
      const { status, beneficiary } = payload;

      return {
        ...state,
        bulkUpload: {
          ...state.bulkUpload,
          entries: {
            ...state.bulkUpload.entries,
            toProcess: _.remove(
              _.concat([], state.bulkUpload.entries.toProcess),
              ({ index }) => index !== beneficiary.index
            ),
            ...(status === "failed"
              ? { failed: [...state.bulkUpload.entries.failed, beneficiary] }
              : {
                  success: [...state.bulkUpload.entries.success, beneficiary],
                }),
          },
        },
      };

    // case ACTIONS.BULK_UPLOAD_BENEFICIARY:
    //   return {
    //     ...state,
    //     bulkBeneficiaries: [...state.bulkBeneficiaries],
    //   };
    default:
      return state;
  }
};

export default beneficiariesReducer;
