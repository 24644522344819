import ACTIONS from "../constants";

const initialState = {
  addMoney: {},
  errorMessage: null,
  loading: false,
};

const addMoneyReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.ADD_MONEY:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.ADD_MONEY_SUCCESS:
      return {
        ...state,
        addMoney: payload,
        loading: false,
      };
    case ACTIONS.ADD_MONEY_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default addMoneyReducer;
