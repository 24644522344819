import React, { useState } from "react";
import { AutoComplete, Input, Tag } from "antd";
import { FiSearch } from "react-icons/all";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";

import { useDispatch, useSelector } from "../../utilities/hooks";
import { performGlobalSearchAction } from "../../services/actions";

export default function DashboardHeader() {
  const dispatch = useDispatch();
  const { query, results } = useSelector((state) => state.searchReducer);
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState(false);

  const renderTitle = (title) => (
    <span className="text-xs text-subtitle">{_.startCase(title)}</span>
  );

  const renderItem = (category, data) => {
    const {
      id,
      firstName,
      lastName,
      email,
      createdAt,
      currency,
      amount,
      transactionId,
      country,
      phoneNumber,
      role,
    } = data;

    const title =
      category === "transactions"
        ? `${transactionId}`
        : `${firstName} ${lastName}`;
    const subTitle =
      category === "transactions"
        ? moment(createdAt).format("Do MMM, YYYY HH:mm")
        : category === "users"
        ? `${email || ""}`
        : `${
            phoneNumber
              ? parsePhoneNumber(phoneNumber).formatInternational()
              : ""
          }`;
    const tag =
      category === "transactions"
        ? `${currency} ${amount}`
        : category === "users"
        ? _.startCase(role)
        : country;

    return {
      value: `${category}-${id}`,
      label: (
        <div className="header-search-results-item-container">
          <div>
            <span className="text-500 text-sm">{title}</span>
            <span className="text-400 text-subtitle text-xs">{subTitle}</span>
          </div>
          <div>{tag ? <Tag>{tag}</Tag> : <React.Fragment />}</div>
        </div>
      ),
    };
  };

  const onSearchQueryChange = (query) => {
    setIsSearching(true);
    setOptions([]);

    dispatch(
      performGlobalSearchAction(
        query,
        (resultsForQuery) => {
          setIsSearching(false);

          if (_.isEqual(resultsForQuery, query)) {
            setOptions(
              _.remove(
                _.map(_.keys(results), (title) => ({
                  label: renderTitle(title),
                  options: _.slice(
                    _.map(results[title] || [], (data) =>
                      renderItem(title, data)
                    ),
                    0,
                    3
                  ),
                })),
                (entry) => entry.options.length > 0
              )
            );
          }
        },
        () => setIsSearching(false)
      )
    );
  };

  return (
    <AutoComplete
      className="header-autocomple-container"
      onSearch={onSearchQueryChange}
      options={query && !isSearching ? options : []}
    >
      <Input
        prefix={<FiSearch />}
        placeholder="Search transactions, beneficiaries, users e.t.c."
        allowClear={isSearching ? false : true}
        suffix={isSearching ? <LoadingOutlined /> : null}
        autoComplete="off"
      />
    </AutoComplete>
  );
}
