import React, { useState } from "react";

export default function SecondForm({ goToNextStep, title, inputDetails }) {
  const [selectedOptionBiz, setSelectedOptionBiz] = useState(
    inputDetails?.businessType ? inputDetails?.businessType : ""
  );

  const handleChangeBiz = (e) => {
    setSelectedOptionBiz(e.target.value);
  };

  return (
    <div>
      <h2>{title}</h2>
      <div className="form-group">
        <label className="label-form">What type of business do you have?</label>
        <div className="custom-radio-group-two">
          <label className="custom-radio-two">
            <input
              type="radio"
              id="radio-input-1"
              name="radio-group"
              value="sp"
              onChange={handleChangeBiz}
              checked={selectedOptionBiz === "sp"}
            />
            <span className="checkmarktwo"></span>
            <span className="biz-title">Sole proprietorship</span>
            <br />
            <span className="biz-desc">
              You run the business by yourself, not a corporation
            </span>
          </label>
        </div>
        <div className="custom-radio-group-two">
          <label className="custom-radio-two">
            <input
              type="radio"
              id="radio-input-3"
              name="radio-group"
              value="llc"
              onChange={handleChangeBiz}
              checked={selectedOptionBiz === "llc"}
            />
            <span className="checkmarktwo"></span>
            <span className="biz-title">Limited liability (LLC)</span>
            <br />
            <span className="biz-desc">
              You have board of directors in your business.
            </span>
          </label>
        </div>
        <div className="custom-radio-group-two">
          <label className="custom-radio-two">
            <input
              type="radio"
              id="radio-input-5"
              name="radio-group"
              value="ngo"
              onChange={handleChangeBiz}
              checked={selectedOptionBiz === "ngo"}
            />
            <span className="checkmarktwo"></span>
            <span className="biz-title">
              Non governmental organisation (NGO)
            </span>
            <br />
            <span className="biz-desc">
              Your company serves a social cause, without the focus on profit
            </span>
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={!selectedOptionBiz}
        onClick={() => goToNextStep(selectedOptionBiz)}
        className="submit-reg"
      >
        Continue
      </button>
    </div>
  );
}
