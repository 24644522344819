import React from "react";
import { Alert, Button } from "antd";
import { WarningFilled, DeleteFilled } from "@ant-design/icons";

import StyledModal from "../styled-modal";

export default function DeleteBeneficiaryModal({
  show,
  onClose,
  item,
  onOk,
  onOkBulk,
  filter,
}) {
  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 pt-5"></div>
          {filter === undefined ? (
            <div className="col-sm-4 pt-5">
              <div className=" pt-5">
                <h5 className="mt-5 text-bold text-center">Delete data?</h5>
                <p className="mb-5">
                  Are you sure you want to delete "{item?.firstName}&nbsp;
                  {item?.lastName}” from the list? you would not be able to undo
                  this action.
                </p>

                <Alert
                  message="Error"
                  description={
                    "By deleting " +
                    item?.firstName +
                    " " +
                    item?.lastName +
                    " beneficiary entries will also be deleted."
                  }
                  type="error"
                  showIcon="true"
                  icon={<WarningFilled />}
                />

                <div className="flex justify-content-between mt-5">
                  <Button
                    style={{ width: "47%" }}
                    type="default"
                    size={"large"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{ width: "47%" }}
                    type="danger"
                    icon={<DeleteFilled />}
                    size={"large"}
                    onClick={onOk}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-sm-4 pt-5">
              <div className=" pt-5">
                <h5 className="mt-5 text-bold text-center">Delete data?</h5>
                <p className="mb-5">
                  Are you sure you want to delete all items selected from the
                  list? you would not be able to undo this action.
                </p>

                <Alert
                  message="Error"
                  description={
                    "By deleting all the items selected will also be deleted from selected entries."
                  }
                  type="error"
                  showIcon="true"
                  icon={<WarningFilled />}
                />

                <div className="flex justify-content-between mt-5">
                  <Button
                    style={{ width: "47%" }}
                    type="default"
                    size={"large"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{ width: "47%" }}
                    type="danger"
                    icon={<DeleteFilled />}
                    size={"large"}
                    onClick={onOkBulk}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledModal>
  );
}
