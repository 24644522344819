import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchCryptoAddressesAction,
  fetchProfileAction,
} from "../../services/actions/";
import { Typography, Spin } from "antd";
// import Loader from "../../components/loaders/loading";
import accessIcon from "../../assets/images/noAccess.svg";
import { useIntercom } from "react-use-intercom";
import { formatDate } from "../../utilities/helperFunctions";
import GeneralTable from "../../components/GeneralTable";
import moment from "moment";

const { Paragraph } = Typography;

export default function CryptoContainer() {
  const { show, boot } = useIntercom();
  const dispatch = useDispatch();
  const { cryptoAddress, loadingCryptoAddress } = useSelector(
    (state) => state.transactionsReducer
  );
  const { user } = useSelector((state) => state.profileReducer);
  const [hasKyc, setHasKyc] = useState(true);
  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  const filterOptions = [{ label: "Date", clickable: true }];
  // const filterOptions = [];
  const filterSelectOptions = {
    Date: [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };

  useEffect(() => {
    dispatch(fetchProfileAction());
    dispatch(fetchCryptoAddressesAction({}));
  }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
    }
  }, [user]);

  const cryptoAddressesColumns = [
    {
      title: "Crypto Address",
      dataIndex: "address",
      key: "address",
      render: (address) => {
        return (
          <Paragraph
            copyable={{
              text: address,
            }}
          >
            {address.substring(0, 9)}...
          </Paragraph>
        );
      },
    },
    {
      title: "Network",
      dataIndex: "coin",
      key: "coin",
    },
    {
      title: "Customer Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Customer Email",
      dataIndex: "destinationAddressDescription",
      key: "destinationAddressDescription",
    },

    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return formatDate(createdAt);
      },
    },
  ];

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    dispatch(fetchCryptoAddressesAction({ params }));
  };

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      const params = {
        from: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "This year"
            ? "year"
            : "today"
        ].from,
        to: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "This year"
            ? "year"
            : "today"
        ].to,
      };
      dispatch(fetchCryptoAddressesAction({ params }));
    } else if (value === "Custom") {
      const params = {
        from: custom.from,
        to: custom.to,
      };
      dispatch(fetchCryptoAddressesAction({ params }));
    } else {
      dispatch(fetchCryptoAddressesAction({}));
    }
  };

  const handleInputChange = (value) => {
    const params = {
      query: value,
    };
    // console.log(params);
    dispatch(fetchCryptoAddressesAction({ params }));
  };

  const isCryptoEmpty = !cryptoAddress;
  const isArrayEmpty =
    !cryptoAddress?.addresses || cryptoAddress?.addresses.length === 0;

  return (
    <div>
      <div>
        <h1 className="text-md color-bold text-bold mt-2">
          Cryptocurrency Addresses Log
        </h1>
      </div>
      {hasKyc ? (
        <div>
          <div>
            {isCryptoEmpty && isArrayEmpty ? (
              <div className="completed-kyc-state">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <img src={accessIcon} alt="" />
                    <h1>No Crypto Addresses Yet</h1>
                    <p>You have not created any addresses yet</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-table-h1">
                <Spin spinning={loadingCryptoAddress}>
                  <GeneralTable
                    columns={cryptoAddressesColumns}
                    placeHolder="Search addresses, name or email"
                    filter={filterOptions}
                    filterSelect={filterSelectOptions}
                    download={false}
                    filterTransactions={filterTransactions}
                    handleInputChange={handleInputChange}
                    pagination={cryptoAddress}
                    paginateAll={paginateAll}
                    data={cryptoAddress?.addresses}
                  />
                </Spin>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
