import makeAPICall from "../../utilities/apiUtils";

import {
  fetchCards,
  fetchCardsError,
  fetchCardsSuccess,
  fetchCardsReset,
  fetchCardAnalytics,
  fetchCardAnalyticError,
  fetchCardAnalyticSuccess,
} from "../selectors";

const fetchCardsAction =
  ({ params = null }) =>
  (dispatch) => {
    const actionController = new AbortController();

    dispatch(fetchCards({ controller: actionController }));
    return makeAPICall({
      path: "cards",
      method: "GET",
      params,
      useCustomController: actionController,
    })
      .then((res) => {
        dispatch(fetchCardsSuccess(res.data));
      })
      .catch((err) => {
        if (err.message === "The user aborted a request.") {
          return false;
        } else {
        }
        dispatch(fetchCardsError(err.message));
      })
      .finally(() => {
        dispatch(fetchCardsReset());
      });
  };

const fetchCardAnalyticsAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchCardAnalytics());
    return makeAPICall({
      path: "cards/analytics",
      method: "GET",
      params,
    })
      .then((res) => {
        dispatch(fetchCardAnalyticSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchCardAnalyticError(err.message));
      });
  };

export { fetchCardsAction, fetchCardAnalyticsAction };
