import makeAPICall from "../../utilities/apiUtils";

import { fetchLogs, fetchLogsSuccess, fetchLogsError } from "../selectors";

const fetchLogsAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchLogs());
    return makeAPICall({
      path: "settings/activities",
      method: "GET",
      params,
    })
      .then((res) => {
        dispatch(fetchLogsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchLogsError(err.message));
      });
  };

export { fetchLogsAction };
