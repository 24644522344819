import React, { useState, useEffect } from "react";
import { Button, Progress, message } from "antd";
import {
  PauseCircleTwoTone,
  PlayCircleTwoTone,
  ShrinkOutlined,
} from "@ant-design/icons";
import {
  hideUploadBeneficiaryAction,
  uploadBulkBeneficiariesAction,
} from "../../../services/actions";
import { useDispatch, useSelector } from "../../../utilities/hooks";
import MinimizedModal from "../minimized-modal";
import StyledModal from "../styled-modal";

export default function FinishBeneficiaryBulkUpload({ show }) {
  const dispatch = useDispatch();

  const { bulkUpload } = useSelector((state) => state.beneficiariesReducer);
  const { percentage, currentCount, isCompleted } = bulkUpload;
  const { toProcess: beneficiaries, success, failed } = bulkUpload.entries;

  const [minimized, setMinimized] = useState(false);

  const [localBeneficiary, setLocalBeneficiary] = useState([]);
  const [isPaused, setIsPaused] = useState(false);

  function handleCancel() {
    setIsPaused(true);
    localStorage.setItem("bulkUploadBeneficiariesPaused", "true");
    message.warning({
      content: "Beneficiary Upload Paused by user",
      duration: 4,
    });
  }

  function handleResume() {
    setIsPaused(false);
    localStorage.removeItem("bulkUploadBeneficiariesPaused");
    dispatch(uploadBulkBeneficiariesAction({ beneficiaries, type: "resume" }));
    setLocalBeneficiary(beneficiaries);
    message.info({
      content: "Beneficiary Upload Resumed by user",
      duration: 4,
    });
  }

  function handleClose() {
    window.localStorage.removeItem("bulkUploadBeneficiariesPaused");
    setIsPaused(true);
    dispatch(hideUploadBeneficiaryAction());
  }

  useEffect(() => {
    dispatch(uploadBulkBeneficiariesAction({ beneficiaries, type: "start" }));
  }, [dispatch, beneficiaries]);

  useEffect(() => {
    setLocalBeneficiary(beneficiaries);
  }, [beneficiaries]);

  return (
    <>
      {!minimized ? (
        <StyledModal show={show} onClose={handleClose}>
          <div className="beneficiaries__list" style={{ maxHeight: "100vh" }}>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 offset-3 mt-5">
                  <div className="p-5 mt-5">
                    <h5>
                      <b>Uploading file </b>
                    </h5>
                    <p>This should take only a few minutes</p>
                    <p>Beneficiary upload progress</p>
                    <div className="flex justify-content-between">
                      <div>
                        <p>
                          {currentCount} / {localBeneficiary.length}(
                          {percentage}
                          %)
                        </p>
                      </div>
                      <div>
                        {!isCompleted ? (
                          <>
                            {!isPaused ? (
                              <Button
                                type="link"
                                className="text-bold btn-primary"
                                icon={<PauseCircleTwoTone />}
                                style={{ color: "black" }}
                                onClick={handleCancel}
                              >
                                Pause
                              </Button>
                            ) : (
                              <Button
                                type="link"
                                className="text-bold btn-primary"
                                icon={<PlayCircleTwoTone />}
                                style={{ color: "black" }}
                                onClick={handleResume}
                              >
                                Resume
                              </Button>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <Progress
                      strokeLinecap="square"
                      percent={percentage}
                      showInfo={false}
                    />
                    <div className="flex justify-content-center mt-4">
                      <Button
                        size="large"
                        className="text-bold btn-primary"
                        icon={<ShrinkOutlined />}
                        onClick={() => {
                          setMinimized(true);
                        }}
                      >
                        Minimize page
                      </Button>
                    </div>
                    {isCompleted ? (
                      <div className="mt-5 flex justify-content-center">
                        <b className="text-center">
                          {success.length} was Completed out of &nbsp;
                          {success.length + failed.length}
                        </b>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledModal>
      ) : (
        <MinimizedModal maximize={() => setMinimized(false)}>
          <div>
            <p className="m-0">
              {currentCount} / {localBeneficiary.length}( {percentage}%)
            </p>
          </div>
          <div className="flex justify-content-between">
            <Progress
              strokeLinecap="square"
              percent={percentage}
              showInfo={false}
              className="mt-1"
            />
            <div>
              {!isCompleted ? (
                <>
                  {!isPaused ? (
                    <Button
                      type="link"
                      className="text-bold btn-primary"
                      icon={<PauseCircleTwoTone />}
                      style={{ color: "black" }}
                      onClick={handleCancel}
                    >
                      Pause
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      className="text-bold btn-primary"
                      icon={<PlayCircleTwoTone />}
                      style={{ color: "black" }}
                      onClick={handleResume}
                    >
                      Resume
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </MinimizedModal>
      )}
    </>
  );
}
