import React from "react";
import { Layout, Dropdown, Menu, Avatar, Tag } from "antd";
import {
  UserOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { IoSettingsOutline, RiLogoutCircleRLine } from "react-icons/all";
import { Link } from "react-router-dom";
import { useSelector } from "../../utilities/hooks";
import { maskEmail } from "../../utilities/helperFunctions";
import Search from "../layouts/search";
import { SESSION_HASH } from "../../utilities/constants";
import makeAPICall from "../../utilities/apiUtils";
import history from "../../utilities/history";
import Question from "../../assets/images/question_image.svg";

const { Header } = Layout;

export default function NavHeader({ openModal }) {
  const { user } = useSelector((state) => state.profileReducer);
  const data = window.localStorage.getItem(SESSION_HASH);

  const logUserOut = () => {
    history.push("/login");
    return makeAPICall({
      path: `auth/logout?session=${data}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          window.localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        window.localStorage.clear();
        window.location.reload();
      });
  };

  // const onChange = (checked) => {
  //   setSwitchLive(checked)
  // };

  const userMenu = (
    <Menu className="header-dropdown-user-container">
      <div className="header-dropdown-user-details-container text-sm">
        {user?.avatar ? (
          <Avatar src={user?.avatar} size={50} />
        ) : (
          <Avatar icon={<UserOutlined />} size={50} />
        )}
        {/* <Avatar icon={<UserOutlined />} size={50} /> */}
        <div>
          <div>{maskEmail(user.email)}</div>
          {user?.account?.isVerified ? (
            <Tag
              icon={<CheckCircleOutlined />}
              color="success"
              className="text-xs"
            >
              Account verified
            </Tag>
          ) : (
            <Tag
              icon={<ExclamationCircleOutlined />}
              color="warning"
              className="text-xs"
            >
              Verify your account
            </Tag>
          )}
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to="/settings">
          <IoSettingsOutline />
          Settings
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="#" onClick={logUserOut} className="text-error">
          <RiLogoutCircleRLine />
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );

  const faqOnboarding = (
    <Menu className="header-dropdown-user-container">
      <div className="header-dropdown-user-details-container-faq text-sm">
        <div style={{ cursor: "pointer" }} onClick={() => openModal(0)}>
          <h1>FAQ</h1>
          <p>Find answers to questions you might have about Eversend B2B.</p>
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => openModal(1)}>
          <h1>Documents to submit</h1>
          <p>
            Learn more about documents you will need to submit in other to
            increase transaction limits.
          </p>
        </div>
      </div>
    </Menu>
  );

  return (
    <Header className="site-header">
      <div className="dashboard-header-container-head">
        <div>
          <Search />
        </div>
        {/* <div style={{marginRight: "10px"}}>
        <Switch checked={switchLive} onChange={onChange} />
        </div> */}
        {user?.account && user.account.isVerified ? (
          <div style={{ marginRight: "30px", cursor: "pointer" }}>
            <Dropdown
              overlay={faqOnboarding}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <img src={Question} alt="faq dropdown" />
            </Dropdown>
          </div>
        ) : null}

        <div>
          <div className="header-avatar-container">
            <Dropdown
              overlay={userMenu}
              trigger={["click"]}
              placement="bottomRight"
              arrow
            >
              <span className="ant-dropdown-link">
                {user?.avatar ? (
                  <Avatar src={user?.avatar} size={36} />
                ) : (
                  <Avatar icon={<UserOutlined />} size={36} />
                )}
                {/* <Avatar icon={<UserOutlined />} size={36} /> */}
              </span>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
}
