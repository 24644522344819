import ACTIONS from "../constants";

const initialState = {
  chains: null,
  errorMessage: null,
  loading: false,
};

const cryptoChainReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_CHAIN_CRYPTO:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_CHAIN_CRYPTO_SUCCESS:
      return {
        ...state,
        chains: payload,
        loading: false,
      };
    case ACTIONS.FETCH_CHAIN_CRYPTO_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
      case ACTIONS.RESET_FETCH_CHAIN_CRYPTO:
        return {
          ...state,
          errorMessage: null,
          loading: false,
          chains:null,
        };
    default:
      return state;
  }
};

export default cryptoChainReducer;
