import { useEffect, useState } from "react";
// import schedule from "../../../assets/images/schedule.svg";
import { Button, message, Spin } from "antd";
import { formatBalance } from "../../../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../../../utilities/hooks";
import {
  createPayoutAction,
  createPayoutQuotationAction,
} from "../../../../services/actions";
import Countdown from "react-countdown";

// import { AuthError } from "../../../../components/Errors/AuthError";

export default function Third({
  goBack,
  onClick,
  currencyDetails,
  profile,
  paymentType,
  payOutData,
  difference,
}) {
  const { payout, loadingPayout } = useSelector(
    (state) => state.transactionsReducer
  );

  const { payoutQuotation, loadingQuotation: loadingPayQuotation } =
    useSelector((state) => state.transactionsReducer);
  const dispatch = useDispatch();
  const { wallets } = useSelector((state) => state.walletsReducer);

  const [usedNumber, setUsedNumber] = useState(difference.endTime - Date.now());
  const [isShown, setIsShown] = useState(true);
  const [countdownApi, setCountDownApi] = useState(null);

  const renderer = ({ seconds }) => <b> Confirm and Send ({seconds})</b>;

  const createPayout = () => {
    handlePauseClick();
    const data = {
      beneficiaryId: profile.id,
      token: payoutQuotation.token,
    };
    dispatch(createPayoutAction(data));
  };

  useEffect(() => {
    if (!isShown) {
      setUsedNumber(30000);
      setIsShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  useEffect(() => {
    if (payout) {
      message.success("Payout request created successfully");
      onClick(payout);
    }
  }, [payout, onClick]);

  const walletAmount = wallets.filter((wallet) => {
    return wallet.currency === currencyDetails?.quotation?.sourceCurrency;
  });

  const handleComplete = (e) => {
    setIsShown(false);
    dispatch(createPayoutQuotationAction(payOutData));
  };

  function handlePause(value) {
    setUsedNumber(value.total);
  }

  function handlePauseClick() {
    countdownApi && countdownApi.pause();
  }

  function setRef(countdown) {
    if (countdown) {
      setCountDownApi(countdown.getApi());
    }
  }

  return (
    <div>
      <h1 className="text-md color-bold text-bold mt-2">
        Review your payout details
      </h1>
      <br />

      <div className="d-flex">
        <p className="pay-summary-h1">Payout details</p>
        <p className="ml-auto">&nbsp;</p>
      </div>
      <br />
      <div className="d-flex">
        <p>You are sending</p>
        <p className="ml-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.sourceAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>Total fees</p>
        <p className="ml-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {currencyDetails.quotation.totalFees}
        </p>
      </div>
      <div className="d-flex">
        <p>Amount we will convert</p>
        <p className="ml-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.sourceAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>
          {profile.firstName}&nbsp; {profile.lastName} Gets
        </p>
        <p className="ml-auto">
          {currencyDetails.quotation.destinationCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.destinationAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>Currency balance after payout</p>
        <p className="ml-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(
            walletAmount[0].amount - currencyDetails.quotation.totalAmount
          )}
        </p>
      </div>
      <br />
      <div className="d-flex">
        <p className="pay-summary-h1">Receipient details</p>
        <p className="ml-auto">&nbsp;</p>
      </div>
      <div className="d-flex">
        <p>Name</p>
        <p className="ml-auto">
          {profile.firstName}&nbsp; {profile.lastName}
        </p>
      </div>
      <div className="d-flex">
        <p>Send to</p>
        <p className="ml-auto">
          {paymentType.charAt(0).toUpperCase() + paymentType.slice(1)}
        </p>
      </div>
      <div className="d-flex">
        <p>Phone number</p>
        <p className="ml-auto">{profile.phoneNumber}</p>
      </div>
      {paymentType === "bank" ? (
        <>
          <div className="d-flex">
            <p>Bank name</p>
            <p className="ml-auto">
              {profile.firstName}&nbsp; {profile.lastName}
            </p>
          </div>
          <div className="d-flex">
            <p>Account number</p>
            <p className="ml-auto">{profile.bankAccountNumber}</p>
          </div>
        </>
      ) : null}

      <br />
      {/* <div className="d-flex">
              <p>
                <img src={schedule} alt="" width="20px" />
                &nbsp; Schedule
              </p>
              <p className="ml-auto">
                <span>
                  <Switch size="medium" />
                </span>
              </p>
            </div> */}

      {loadingPayQuotation ? (
        <Spin tip="Loading...">
          <hr />
        </Spin>
      ) : (
        <hr />
      )}

      <br />
      <div className="row">
        <Button
          size="large"
          type="default"
          className="grey-btn"
          onClick={goBack}
          style={{ width: "49%" }}
          disabled={loadingPayQuotation}
        >
          Cancel and go back
        </Button>

        <Button
          size="large"
          type="default"
          className="pay-blue-btn"
          onClick={createPayout}
          disabled={!isShown || loadingPayQuotation}
          loading={loadingPayout}
          style={{ width: "49%", marginLeft: "5px" }}
        >
          {loadingPayQuotation || !isShown ? (
            <b>Confirm and Send </b>
          ) : (
            <Countdown
              date={Date.now() + usedNumber}
              renderer={renderer}
              onComplete={handleComplete}
              onPause={(value) => handlePause(value)}
              ref={(countdown) => setRef(countdown)}
            />
          )}
        </Button>
      </div>
    </div>
  );
}
