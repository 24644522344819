import React, { useState, useEffect } from "react";
import { useDispatch } from "../../../utilities/hooks";
import {
  updateProfileAction,
  fetchProfileAction,
} from "../../../services/actions";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";
import makeAPICall from "../../../utilities/apiUtilsFetch";
import { message } from "antd";
import deleteIcon from "../../../assets/images/deleteIcon.png";

export default function SettingsProfile({ user, loading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });

  const [profile, setProfile] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    phonePrefix: null,
  });

  useEffect(() => {
    setLoadingState(loading);
  }, [loading]);

  useEffect(() => {
    if (user)
      setProfile(
        (({ firstName, lastName, email, phone, phonePrefix }) => ({
          firstName,
          lastName,
          email,
          phone,
          phonePrefix,
        }))(user)
      );
  }, [user]);

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitForm = () => {
    const { firstName, lastName } = profile;
    dispatch(updateProfileAction({ firstName, lastName }));
  };

  const handleChange = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/gif", "image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setImage({
          preview: URL.createObjectURL(uploadFile),
          raw: uploadFile,
        });
        const formData = new FormData();
        formData.append("file", uploadFile);
        // console.log(...formData, 'yrryr')
        return makeAPICall({
          path: "profile/avatar",
          payload: formData,
          method: "POST",
        })
          .then((res) => {
            // console.log(res);
            message.success("Picture uploaded successfully", 5);
            dispatch(fetchProfileAction());
          })
          .catch((err) => {
            message.error(err.message, 5);
          });
      } else {
        message.error("Please select an image to upload");
      }
    }
  };

  const handleDelete = () => {
    if (user?.avatar) {
      makeAPICall({
        path: "profile/avatar",
        method: "DELETE",
      })
        .then((res) => {
          // console.log(res);
          message.success("Picture deleted successfully", 5);
          dispatch(fetchProfileAction());
        })
        .catch((err) => {
          message.error(err.message, 5);
        });
    } else {
      message.error("You have no logo to delete");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1
            className="main-settings"
            onClick={() => history.push("/settings")}
          >
            Settings &nbsp; <i className="fa fa-angle-right"></i> &nbsp; &nbsp;
            <span className="text-black-50">Profile settings</span>
          </h1>
        </div>
      </div>
      <br />
      <div className="profile-tab-settings">
        <div className="row">
          <div className="col-md-12 settings-tabs-settings">
            {/* <h1>{user?.role}</h1> */}
            <span className="role-title">{user?.role}</span>

            <hr />
            <br />
            <div className="row">
              <div className="col-md-12 settings-picture">
                <label className="label-auth">Profile image</label>
                <div className="d-flex">
                  {image.preview ? (
                    <div className="no-uploads">
                      <img
                        src={image.preview}
                        style={{ borderRadius: "50%" }}
                        alt={user?.firstName}
                        width="70"
                        height="70"
                      />
                    </div>
                  ) : (
                    <div className="profilepic-holder">
                      {user?.avatar === null ? (
                        <div className="no-uploads">
                          <div className="no-uploads-child">
                            {user?.firstName.charAt(0)}
                          </div>
                        </div>
                      ) : (
                        <div className="no-uploads">
                          <img
                            src={user?.avatar}
                            style={{ borderRadius: "50%" }}
                            alt={user?.firstName}
                            width="70"
                            height="70"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="align-self-center">
                    {user?.avatar === null ? (
                      <label htmlFor="upload-button" className="label-profile">
                        Upload icon
                      </label>
                    ) : (
                      <label htmlFor="upload-button" className="label-profile">
                        Replace image
                      </label>
                    )}

                    <input
                      type="file"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="align-self-center">
                    <img
                      src={deleteIcon}
                      alt=""
                      className="deleteIcon"
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-6">
                    <label className="label-auth">First Name</label>
                    <input
                      name="firstName"
                      value={profile.firstName}
                      onChange={onChangeInputValue}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="label-auth">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      value={profile.lastName}
                      onChange={onChangeInputValue}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-6">
                    <label className="label-auth">Email</label>
                    <input
                      name="email"
                      value={profile.email}
                      onChange={onChangeInputValue}
                      type="email"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="label-auth">Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      value={profile.phonePrefix + profile.phone}
                      onChange={onChangeInputValue}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3 text-right">
                    <button onClick={onSubmitForm}>
                      {isLoading ? <Loader dark={false} /> : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
