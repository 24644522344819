import ACTIONS from "../constants";

const initialState = {
  errorMessage: null,
  loading: false,
  password: null,
};

const changePassword = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ACTIONS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        password: payload,
      };
    case ACTIONS.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
      case ACTIONS.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        password: null,
      };
    default:
      return state;
  }
};

export default changePassword;
