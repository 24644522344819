import React, { useState, useEffect } from "react";
import { Button, Input, Form, Row, Col, Select, message } from "antd";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { addUserAction, fetchUsersAction } from "../../../services/actions";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import StyledModal from "../styled-modal";

export default function AddUserModal({ show, onClose }) {
  // const [showPassword, setShowPassword] = useState(false);
  // const [passwordField, setPasswordField] = useState("password");
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [passwordFieldConfirm, setPasswordConfirmField] = useState("password");

  const { addUser, loading } = useSelector((state) => state.usersReducer);
  const { roles } = useSelector((state) => state.RoleReducer);
  const { countries } = useSelector((state) => state.CountryReducer.countries);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");

  const [profile, setProfile] = useState({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
  });

  const [incomplete, setIncomplete] = useState(true);

  const countryArray = countries?.map((item) => item?.country.toLowerCase());

  const otherCountries = ["us", "gb"];
  countryArray?.push(...otherCountries);
  // console.log(countryArray, "tetsts");

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  function handleOnChangePhoneInput(value) {
    setPhone(value);
  }

  const onSelectChange = (value, name) => {
    if (value === "administrator") value = "admin";
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const onSubmitForm = () => {
    const { firstName, lastName, email, role } = profile;
    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email.toLowerCase(),
      role: role,
    };

    if (role === "owner") {
      message.error("You cannot assign owner role to a user");
      return;
    } else {
      dispatch(addUserAction(data));
    }
  };

  useEffect(() => {
    if (addUser) {
      dispatch(fetchUsersAction());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUser]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIncomplete(false);

    if (!profile.firstName || profile.firstName === "") setIncomplete("true");
    if (!profile.lastName || profile.lastName === "") setIncomplete("true");
    if (!phone || phone === "") setIncomplete("true");
    if (!profile.email || profile.email === "") setIncomplete("true");
    if (!profile.role || profile.role === "") setIncomplete("true");
  }, [profile, phone]);

  // console.log(roles.roles)

  const myData = Object.keys(roles?.roles).map((key) => {
    return roles?.roles[key];
  });

  // myData = myData.shift();

  // console.log(myData);

  return (
    <StyledModal centered show={show} footer={null} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-3 mt-5">
            <div>
              <div className="mt-4">
                <p className="text-bold color-bold text-md mb-1">Add User</p>
              </div>
              <Form layout="vertical">
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="First Name">
                        <Input
                          name="firstName"
                          value={profile.firstName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Last Name">
                        <Input
                          name="lastName"
                          value={profile.lastName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Email">
                        <Input
                          name="email"
                          value={profile.email}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Phone">
                        <ReactPhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                            className: "formInput",
                          }}
                          countryCodeEditable={false}
                          value={phone}
                          onChange={handleOnChangePhoneInput}
                          // enableSearch={true}
                          // preferredCountries={countryArray}
                          onlyCountries={countryArray}
                          disableCountryCode={false}
                          placeholder="0800 000 0000"
                          autoFormat={true}
                          enableClickOutside={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                <Form.Item label="User Role">
                  <Select
                    className="form__select mt-1"
                    name="role"
                    placeholder="Choose Users Role"
                    value={profile.role}
                    onChange={(value) => onSelectChange(value, "role")}
                  >
                    {myData
                      ?.filter((data) => data !== "Owner")
                      .map((role, index) => (
                        <Select.Option
                          value={role.toLowerCase().replaceAll(" ", "_")}
                          key={index}
                        >
                          {role}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Button
                  type="primary"
                  className="simple-block-btn text-bold"
                  size="large"
                  block
                  loading={isLoading}
                  disabled={incomplete}
                  onClick={onSubmitForm}
                >
                  Send Invite
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
