import React from "react";
import { Modal, Button, Form, Input } from "antd";
// import { Link } from "react-router-dom";

export default function ResendOTPRegisterModal({ show, setShow, closeModal }) {
  return (
    <Modal centered visible={show} onOk={setShow} onCancel={closeModal}>
      <div>
        <p className="text-bold color-bold text-md">Re-enter your email</p>
        {/* <p>There was probably something wrong with the email you entered.</p> */}
      </div>
      <div>
        <Form layout="vertical">
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="eg. Johndoe@gmail.com " />
          </Form.Item>
          <Form.Item className="mt-5">
            <Button type="primary" className="resend-modal-btn">
              Resend OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
