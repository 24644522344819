import React, { useState, useEffect } from "react";
import { Button, Steps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchBeneficiariesAction,
  addBeneficiariesAction,
  fetchBanksAction,
  fetchCountryAction,
  deleteAllBeneficiariesAction,
} from "../../services/actions";
import EmptyState from "../../components/emptyState";
import EmptyStateIcon from "../../assets/images/Group 195.svg";
import AddBeneficiaryModal from "../../components/modals/beneficiaries/AddBeneficiary.jsx";
import AddBeneficiariesPreview from "./AddBeneficiariesPreview";
import BeneficiariesList from "./BeneficiariesList";
// import Loader from "../../components/loaders/loading";
import accessIcon from "../../assets/images/noAccess.svg";
import { useIntercom } from "react-use-intercom";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";

const { Step } = Steps;

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_add_beneficiary_btn",
  },
};

export default function BeneficiaryOverview() {
  const { show, boot } = useIntercom();
  const {
    beneficiaries,
    loading: beneficiariesLoading,
    bulkUpload,
  } = useSelector((state) => state.beneficiariesReducer);
  const { user } = useSelector((state) => state.profileReducer);
  const { toProcess: beneficiariesToAdd } = bulkUpload.entries;

  // const { loading: banksLoading } = useSelector((state) => state.BanksReducer);
  // const { loading: countryLoading } = useSelector(
  //   (state) => state.CountryReducer
  // );
  const { roles } = useSelector((state) => state.RoleReducer);

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  const dispatch = useDispatch();
  const [hasKyc, setHasKyc] = useState(true);
  // const [isLoading, setLoadingState] = useState(false);
  const [page, setPage] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const [showAddBeneficiaryModal, setShowAddBeneficiaryModal] = useState(false);

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  // useEffect(() => {
  //   dispatch(fetchProfileAction());
  // }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
    }
  }, [user]);

  function handleOpenModal() {
    TagManager.dataLayer(tagManagerArgs);
    setShowAddBeneficiaryModal(true);
  }

  function handleEditMode() {
    setEditMode(!editMode);
  }

  function handleCloseModal() {
    setShowAddBeneficiaryModal(false);
  }

  function addBeneficiaries(beneficiaries) {
    dispatch(addBeneficiariesAction(beneficiaries));
    handleCloseModal();
  }

  function onChange() {}

  function clearBulkPreview() {
    setPage(1);
    dispatch(deleteAllBeneficiariesAction());
    setShowAddBeneficiaryModal(true);
  }

  useEffect(() => {
    if (beneficiaries?.length === 0) {
      dispatch(fetchBeneficiariesAction({}));
    } else {
      dispatch(fetchBeneficiariesAction({}));
    }
    dispatch(fetchBanksAction({}));
    dispatch(fetchCountryAction({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(() => {
  //   setLoadingState(beneficiariesLoading || banksLoading || countryLoading);
  // }, [beneficiariesLoading, banksLoading, countryLoading]);

  const isBeneficiariesEmpty = !beneficiaries;
  const isArrayEmpty =
    !beneficiaries?.totalBeneficiaries ||
    beneficiaries?.totalBeneficiaries.length === 0;

  return (
    <>
      <h1 className="text-md color-bold text-bold mt-2">Beneficiaries</h1>

      {hasKyc ? (
        <div>
          {beneficiariesToAdd.length > 0 ? (
            <div className="beneficiary-progress-test">
              <Steps
                current={2}
                onChange={onChange}
                labelPlacement={"vertical"}
              >
                <Step title="Download XLSX" />
                <Step title="Upload XLSX" />
                <Step title="Review" />
                <Step title="Success" />
              </Steps>
              <br />
              <h5 className="text-bold">Beneficiary</h5>
              <br />
              <AddBeneficiariesPreview
                addBeneficiaries={addBeneficiaries}
                beneficiaries={beneficiariesToAdd}
                clearBulkPreview={clearBulkPreview}
                editMode={editMode}
                onEditMode={handleEditMode}
              />
            </div>
          ) : isBeneficiariesEmpty && isArrayEmpty ? (
            <>
              <div className="bg-white">
                <div className="mt-5 empty__wrap--center">
                  <EmptyState
                    img={EmptyStateIcon}
                    title="Add a new beneficiary"
                    description="You have not performed any transactions or added a beneficiary"
                    btnLabel="Add beneficiary"
                    btnProps={{ maxWidth: "236px" }}
                    onClick={handleOpenModal}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between align-items-baseline">
                <h1 className="text-md color-bold text-bold mt-2">&nbsp;</h1>
                {checkAccess("beneficiaries").granted ? (
                  <Button
                    className="text-bold btn-primary"
                    type="primary"
                    size="large"
                    onClick={handleOpenModal}
                  >
                    <PlusOutlined />
                    Add beneficiary
                  </Button>
                ) : null}
              </div>

              <BeneficiariesList
                beneficiaries={beneficiaries}
                loading={beneficiariesLoading}
                user={user}
              />
            </>
          )}

          <AddBeneficiaryModal
            show={showAddBeneficiaryModal}
            onClose={handleCloseModal}
            page={page}
            addBeneficiaries={addBeneficiaries}
          />
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
