import { useState, useEffect } from "react";
import _ from "lodash";

const trimZerosOffAmount = (amount) => {
  if (_.includes(`${amount}`, ".")) {
    return _.trimEnd(`${amount}`, "0");
  }

  return `${amount}`;
};

export const formatBalance = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    const returnValue =
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "");

    return trimZerosOffAmount(_.replace(`${returnValue}`, ".00", ""));
  } catch (e) {}
};

export const formatBalanceCrypto = (
  amount,
  decimalCount = 8,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 8 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    const returnValue =
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "");

    return trimZerosOffAmount(_.replace(`${returnValue}`, ".00000000", ""));
  } catch (e) {}
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : ""
      ).replace(/\.00$/, "")
    );
  } catch (e) {}
};

export const formatDate = (data) => {
  const d = new Date(data);
  const year = d.getFullYear();
  const date = d.getDate();
  const time = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = months[d.getMonth()];
  const formatted = `${monthName} ${date}, ${year} - ${time}`;
  return formatted;
};

export const formatDateTransaction = (data) => {
  const d = new Date(data);
  const year = d.getFullYear();
  const date = d.getDate();
  const time = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = months[d.getMonth()];
  const formatted = `${monthName} ${date}, ${year} at ${time}`;
  return formatted;
};

export const formatTime = (data) => {
  const d = new Date(data);
  const time = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const formatted = `${time}`;
  return formatted;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const maskEmail = (email) => {
  try {
    let skipFirstChars = 3;
    let firstThreeChar = email.slice(0, skipFirstChars);

    let domainIndexStart = email.lastIndexOf("@");
    let domain = email.slice(domainIndexStart, email.length);

    let maskedEmail = email.slice(skipFirstChars, domainIndexStart);
    maskedEmail = _.join(_.slice(maskedEmail.replace(/./g, "*"), 0, 8), "");

    return firstThreeChar.concat(maskedEmail).concat(domain);
  } catch (e) {}
};

export const maskMiddleDigits = (number) => {
  const length = number.length;

  if (length <= 5) {
    return "*".repeat(length);
  }

  const maskCount = Math.min(5, length - 2);
  const leftSide = Math.ceil((length - maskCount) / 2);
  const rightSide = length - maskCount - leftSide;

  return (
    number.slice(0, leftSide) +
    "*".repeat(maskCount) +
    number.slice(length - rightSide)
  );
};

export const maskCardNumber = (cardNumber) => {
  // Remove any spaces and validate the length
  const cleanedCardNumber = cardNumber.replace(/\s+/g, "");
  if (cleanedCardNumber.length !== 16) {
    return "Invalid card number length";
  }

  // Extract the last 4 digits
  const lastFourDigits = cleanedCardNumber.slice(-4);

  // Return masked card number
  return `*** ${lastFourDigits}`;
};

export const maskCardMiddleNumbers = (cardNumber) => {
  // Remove any spaces and validate the length
  const cleanedCardNumber = cardNumber.replace(/\s+/g, "");
  if (cleanedCardNumber.length !== 16) {
    return "Invalid card number length";
  }

  // Extract the first 4 and last 4 digits
  const firstFourDigits = cleanedCardNumber.slice(0, 4);
  const lastFourDigits = cleanedCardNumber.slice(-4);

  // Return the masked card number
  return `${firstFourDigits} **** **** ${lastFourDigits}`;
};

export const excludedCurrencies = ["USD", "EUR", "GBP", "ZAR"];

export const addMoneyCountries = [
  {
    country: "GH",
    addMoney: ["momo", "eversend"],
  },
  {
    country: "KE",
    addMoney: ["momo", "eversend"],
  },
  {
    country: "NG",
    name: "Nigeria",
    addMoney: ["bank", "eversend"],
  },
  {
    country: "RW",
    name: "Rwanda",
    addMoney: ["momo", "eversend"],
  },
  {
    country: "UG",
    name: "Uganda",
    addMoney: ["momo", "eversend"],
  },
  { country: "CM", name: "Cameroon", addMoney: ["momo", "eversend"] },
  { country: "CI", name: "Cote D_Ivoire", addMoney: ["momo", "eversend"] },
  // { country: "SN", name: "Senegal", addMoney: ["momo", "eversend"] },
  {
    country: "TZ",
    name: "Tanzania, United Republic of",
    addMoney: ["momo", "eversend"],
  },
];

export const allMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCurrentYear = function (date) {
  const newDate = date ? new Date(date) : new Date();
  let year = newDate.getFullYear();

  return year;
};

export const getMonthName = function (date = new Date()) {
  return allMonth[new Date(date).getMonth()];
};

export const key = "AllGet";

export function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear();
  let years = [];

  for (let i = startYear; i <= endDate; i++) {
    years.push(startYear);
    startYear++;
  }
  return years;
}

function capitalizeFLetter(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export { capitalizeFLetter };
