import React from 'react';
import { Col, Row } from 'antd';

import AccountValue from './AccountValue';
import AnalyticsOverview from '../analytics/Analytics.container';

export default function AccountSummaryOverView({ wallets, summary, accountLoader, showBal, handleShowBalance }) {
	const gutter = { xs: 16, sm: 16, md: 16, lg: 24 };

	return (
		<Row gutter={[ gutter, gutter ]} className='dashboard-overview-container'>
			<Col xs={24} lg={10} xl={8} xxl={7}>
				<AccountValue summary={summary} accountLoader={accountLoader} wallets={wallets} showBal={showBal} handleShowBalance={handleShowBalance} />
			</Col>
			<Col xs={24} lg={14} xl={16} xxl={17}>
				<AnalyticsOverview wallet={wallets} />
			</Col>
		</Row>
	);
}
