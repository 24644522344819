import React, { useState, useEffect } from "react";
import { Button, Input, Form, Row, Col } from "antd";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { addIPAddressAction } from "../../../services/actions";

import "react-phone-input-2/lib/style.css";
import StyledModal from "../styled-modal";

export default function AddIPAddress({ show, onClose }) {
  const { wasUpdated, loading } = useSelector(
    (state) => state.ipAddressReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [iPAddress, setIPAddress] = useState({
    label: null,
    address: null,
  });

  const [incomplete, setIncomplete] = useState(true);

  const onChangeInputValue = (event) => {
    setIPAddress({
      ...iPAddress,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitForm = () => {
    dispatch(addIPAddressAction(iPAddress));
  };

  useEffect(() => {
    if (wasUpdated) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasUpdated]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIncomplete(false);
    if (
      !iPAddress.address ||
      iPAddress.address === ""
    )
      setIncomplete(true);
    if (!iPAddress.label || iPAddress.label === "") setIncomplete(true);
  }, [iPAddress]);

  return (
    <StyledModal centered show={show} footer={null} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-3 mt-5">
            <div>
              <div className="mt-4">
                <p className="text-bold color-bold text-md mb-1">
                  Add IP Address
                </p>
              </div>
              <Form layout="vertical">
                <Input.Group size="large">
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Label">
                        <Input
                          name="label"
                          value={iPAddress.label}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="IP Address">
                        <Input
                          name="address"
                          value={iPAddress.address}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>

                <Button
                  type="primary"
                  className="simple-block-btn text-bold"
                  size="large"
                  block
                  loading={isLoading}
                  disabled={incomplete}
                  onClick={onSubmitForm}
                >
                  Add IP Address
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
