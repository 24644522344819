import { useState } from "react";
import plus from "../../assets/images/Add_plain.svg";
import close from "../../assets/images/Close.svg";

const AccordionDoc = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item-other">
      <div className="d-flex mb-2" onClick={handleClick}>
        <img
          src={isOpen ? close : plus}
          alt="open faq"
        />
        <div className="accordion-item-title-other">
          <h1>{title}</h1>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-item-content-other">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionDoc;
