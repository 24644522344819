import ACTIONS from "../constants";

const initialState = {
  transactions: [],
  cryptoAddress: [],
  cryptoTransactions: [],
  cardTransactions: [],
  errorCrypto: null,
  errorCard: null,
  loadingCrypto: false,
  loadingCard: false,
  errorMessage: null,
  errorCryptoAddress: null,
  loadingCryptoAddress: false,
  loading: false,
  payout: null,
  errorMessagePayout: null,
  loadingPayout: false,
  payoutQuotation: null,
  errorMessageQuotation: null,
  loadingQuotation: false,
  controller: null, // Ensure you have a controller property in your initial state
};

const transactionsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_TRANSACTIONS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload,
        loading: false,
      };
    case ACTIONS.FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.FETCH_CARD_TRANSACTIONS:
      return {
        ...state,
        errorCard: null,
        loadingCard: true,
      };
    case ACTIONS.FETCH_CARD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        cardTransactions: payload,
        loadingCard: false,
      };
    case ACTIONS.FETCH_CARD_TRANSACTIONS_ERROR:
      return {
        ...state,
        errorCard: payload,
        loadingCard: false,
      };
    case ACTIONS.FETCH_CRYPTO_TRANSACTIONS:
      return {
        ...state,
        errorCrypto: null,
        loadingCrypto: true,
      };
    case ACTIONS.FETCH_CRYPTO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        cryptoTransactions: payload,
        loadingCrypto: false,
      };
    case ACTIONS.FETCH_CRYPTO_TRANSACTIONS_ERROR:
      return {
        ...state,
        errorCrypto: payload,
        loadingCrypto: false,
      };

    case ACTIONS.FETCH_CRYPTO_ADDRESSES:
      return {
        ...state,
        controller: action.controller,
        errorCryptoAddress: null,
        loadingCryptoAddress: true,
      };
    case ACTIONS.FETCH_CRYPTO_ADDRESSES_SUCCESS:
      return {
        ...state,
        cryptoAddress: payload,
        loadingCryptoAddress: false,
        controller: null,
      };
    case ACTIONS.FETCH_CRYPTO_ADDRESSES_ERROR:
      return {
        ...state,
        errorCryptoAddress: payload,
        loadingCryptoAddress: false,
        controller: null,
      };
    case ACTIONS.CREATE_PAYOUT:
      return {
        ...state,
        errorMessagePayout: null,
        loadingPayout: true,
      };
    case ACTIONS.CREATE_PAYOUT_SUCCESS:
      return {
        ...state,
        payout: payload,
        loadingPayout: false,
      };
    case ACTIONS.CREATE_PAYOUT_ERROR:
      return {
        ...state,
        errorMessagePayout: payload,
        loadingPayout: false,
      };

    case ACTIONS.CREATE_PAYOUT_QUOTATION:
      return {
        ...state,
        errorMessagePayout: null,
        loadingQuotation: true,
      };
    case ACTIONS.CREATE_PAYOUT_QUOTATION_SUCCESS:
      return {
        ...state,
        payoutQuotation: payload,
        loadingQuotation: false,
      };
    case ACTIONS.CREATE_PAYOUT_QUOTATION_ERROR:
      return {
        ...state,
        errorMessageQuotation: payload,
        loadingQuotation: false,
        payoutQuotation: null,
      };
    case ACTIONS.DELETE_PAYOUT:
      return {
        ...state,
        payout: null,
        errorMessagePayout: null,
        loadingPayout: false,
      };
    case ACTIONS.DELETE_PAYOUT_QUOTATION:
      return {
        ...state,
        payoutQuotation: null,
        errorMessageQuotation: null,
        loadingQuotation: false,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
