import React from "react";
import logo from "../../assets/images/Eversend conf.4 for white bcgrnd.svg";
import "./layout.css";

export default function SharedAuthLayout({ title, paragraph, children }) {
  return (
    <div className="body-div">
      <img src={logo} alt="Logo" className="logo" />

      <div className="login-form">
        {/* <h1 style={{ fontSize: "16px", textAlign: "center" }}>
          Sorry, our systems are down at the moment due to maintenance work.
        </h1>
        <br /> */}
        <h1 className="title">{title}</h1>
        <p className="paragraph">{paragraph}</p>
        {children}
      </div>

      <div className="auth-footer">
        <div className="under-auth-footer">
          <p>© Eversend</p>
          <p>Contact</p>
          <p>
            <a href="https://eversend.co/terms-of-service">Privacy & terms</a>
          </p>
        </div>
      </div>
    </div>
  );
}
