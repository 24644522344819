import ACTIONS from '../../constants';

const initialState = {
  user: null,
  errorMessage: null,
  loading: false,
};

const verifyPhoneCodeReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.VERIFY_PHONE_CODE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.VERIFY_PHONE_CODE_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.VERIFY_PHONE_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
      case ACTIONS.RESET_VERIFY_PHONE_CODE:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        user: null,
      };
    default: return state;
  }
};

export default verifyPhoneCodeReducer;
