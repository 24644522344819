import React, { useState, useEffect } from "react";
import { Button, Input, Form, message, Select, Spin } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik } from "formik";
import * as Yup from "yup";

import DeleteBeneficiaryModal from "./DeleteBeneficiary";

import { useSelector } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";
import StyledModal from "../styled-modal";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_saved_beneficiary",
    beneficiary_operation: "update",
  },
};

const { Option } = Select;

const validationSchema = Yup.object().shape({
  bankName: Yup.string().min(3).label("Bank Name").nullable(),
  country: Yup.string().required().label("Country"),
  bankAccountNumber: Yup.string().min(8).label("Account Number").nullable(),
  bankAccountName: Yup.string().min(3).label("Account Name").nullable(),
  phoneNumber: Yup.string().required().min(8).label("Phone Number"),
  firstName: Yup.string().required().min(3).label("First Name"),
  lastName: Yup.string().required().min(3).label("Last Name"),
});

const validationSchemaMomo = Yup.object().shape({
  phoneNumber: Yup.string().required().min(3).label("Phone Number"),
  firstName: Yup.string().required().min(3).label("First Name"),
  lastName: Yup.string().required().min(3).label("Last Name"),
  country: Yup.string().required().label("Country"),
});

export default function EditBeneficiariesModal({ show, onClose, item }) {
  // console.log(item, "test");
  const { banks } = useSelector((state) => state.BanksReducer);
  const { countries } = useSelector((state) => state.CountryReducer.countries);
  // console.log(countries)

  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] =
    useState(false);
  const [banksSelect, setBanksSelect] = useState([]);
  const [country, setCountry] = useState(item.country);

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    item.phoneNumber.replace("+", "")
  );

  const [bankName, setBankName] = useState(item.bankName);
  const [bankCode, setBankCode] = useState(item.bankCode);

  //countries with paymentType response object of bank should be able to add bank records
  const filteredCountry = countries.find(
    (country) => country.country === item.country
  );

  const countryArray = countries?.map((item) => item?.country.toLowerCase());

  //use this to check if a country has paymentType response object of bank and then if it doesn't don't show bank details form
  const bankCountry = filteredCountry.paymentTypes.find(
    (element) => element === "bank"
  );

  function handleOnChangePhoneInput(value) {
    setPhoneNumber(value);
  }

  function handleSubmit(values, setSubmitting) {
    values.phoneNumber = phoneNumber.replace("+", "");
    let payload = values;
    payload.bankCode = bankName ? bankCode : null;

    setSubmitting(true);
    setLoading(true);
    return makeAPICall({
      path: `beneficiaries/${item.id}`,
      payload,
      method: "PATCH",
    })
      .then((res) => {
        TagManager.dataLayer(tagManagerArgs);
        message.success("Beneficiary information Updated Successfully", 10);
        setLoading(false);
        setSubmitting(false);
        onClose();
      })
      .catch((err) => {
        message.error(err.message, 10);
        setLoading(false);
        setSubmitting(false);
      });
  }

  function handleOk() {
    // dispatch(deleteSingleBeneficiariesAction([itemPosition]));
    // console.log("Am called");
    // setShowDeleteBeneficiaryModal(false);
    // onClose();
  }

  function handleFieldChange(value, setSubmitting) {
    setSubmitting(true);
    if (item.country === "NG") {
      let params = {
        countryCode: item.country,
        bankCode: bankCode,
        accountNumber: value.target.value,
      };

      setLoading(true);
      return makeAPICall({
        path: "/settings/banks/accounts",
        params,
        method: "GET",
      })
        .then((res) => {
          message.success("Beneficiary Fetched Successfully", 10);
          setLoading(false);
          setSubmitting(false);
        })
        .catch((err) => {
          message.error(err.message, 10);
          setLoading(false);
          setSubmitting(false);
        });
    }
  }

  useEffect(() => {
    setBanksSelect(banks[country] || []);
  }, [country, banks]);

  useEffect(() => {
    let bankInfo = banksSelect.filter((data) => data.name === bankName);
    if (bankInfo.length > 0) setBankCode(bankInfo[0].code);
  }, [bankName, banksSelect]);

  if (Object.keys(item).length === 0) {
    return null;
  }

  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="beneficiaries__list" style={{ maxHeight: "100vh" }}>
        <div className="container">
          <div className="row">
            {bankCountry === "bank" ? (
              <div className="col-sm-6 offset-3  mt-5">
                <p>Edit imported beneficiary field</p>

                <div className="flex align-center mt-5">
                  <span className="beneficiary__avatar">
                    {/* country flag */}
                    {item.firstName[0]}
                    {item.lastName[0]}
                  </span>
                  <span className="ml-3">
                    <p className="color-bold text-bold text-smd mb-0">
                      {item.firstName} {item.lastName}
                    </p>
                  </span>
                </div>
                <br />
                <Formik
                  initialValues={{
                    firstName: item.firstName,
                    lastName: item.lastName,
                    bankName: item.bankName,
                    country: item.country,
                    bankAccountNumber: item.bankAccountNumber,
                    phoneNumber: phoneNumber,
                    bankAccountName: item.bankAccountName,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                  }) => (
                    <Form layout="vertical">
                      <Form.Item label="First Name">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                      </Form.Item>
                      <p className="text-danger">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </p>
                      <Form.Item label="Last Name">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </Form.Item>
                      <p className="text-danger">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </p>

                      <Form.Item>
                        <label className="text-smd mb-1">Phone number</label>
                        <ReactPhoneInput
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                            autoFocus: true,
                            className: "formInput",
                          }}
                          countryCodeEditable={false}
                          value={phoneNumber}
                          onChange={handleOnChangePhoneInput}
                          enableSearch={true}
                          onlyCountries={countryArray}
                          disableCountryCode={false}
                          country={values.country.toLowerCase()}
                          placeholder="0800 000 0000"
                          autoFormat={true}
                          enableClickOutside={true}
                          disabled={true}
                        />
                        <span></span>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </p>

                      <Form.Item label="Country">
                        <Select
                          size="large"
                          name="country"
                          value={values.country}
                          onChange={(value) => {
                            setFieldValue("country", value);
                            setCountry(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {countries.map((data) => (
                            <Option key={data.country} value={data.country}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.country && touched.country && errors.country}
                      </p>

                      <Form.Item label="Bank Name">
                        <Select
                          size="large"
                          name="bankName"
                          value={values.bankName}
                          onChange={(value) => {
                            setFieldValue("bankName", value);
                            setBankName(value);
                          }}
                          onBlur={handleBlur}
                          showSearch
                          placeholder="Select Bank"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {banksSelect.map((data) => (
                            <Option key={data.id} value={data.name}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.bankName && touched.bankName && errors.bankName}
                      </p>

                      <Form.Item label="Account Number">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="bankAccountNumber"
                          onChange={(value) => {
                            handleChange(value);
                          }}
                          onBlur={(value) => {
                            if (item.country !== "NG") {
                              // If the condition is not met, prevent onBlur action and return
                              value.preventDefault();
                              return;
                            }
                            handleChange(value);
                            handleFieldChange(value, setSubmitting);
                          }}
                          value={values.bankAccountNumber}
                        />
                      </Form.Item>
                      <div className="flex justify-content-end">
                        {loading ? <Spin /> : null}
                      </div>
                      <p className="text-danger">
                        {errors.bankAccountNumber &&
                          touched.bankAccountNumber &&
                          errors.bankAccountNumber}
                      </p>

                      <Form.Item label="Account Name">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="bankAccountName"
                          onChange={handleChange}
                          value={values.bankAccountName}
                          disabled={values.country === "NG"}
                        />
                      </Form.Item>

                      <p className="text-danger">
                        {errors.bankAccountName &&
                          touched.bankAccountName &&
                          errors.bankAccountName}
                      </p>

                      <div className="flex justify-content-between mt-5">
                        <Button
                          type="primary"
                          size={"large"}
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          className="btn-primary"
                          block
                        >
                          Update Beneficiary
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <div className="col-sm-6 offset-3  mt-5">
                <p>Edit Beneficiary Information</p>
                <div className="flex align-center mt-5">
                  <span className="beneficiary__avatar">
                    {/* country flag */}
                    {item.firstName[0]}
                    {item.lastName[0]}
                  </span>
                  <span className="ml-3">
                    <p className="color-bold text-bold text-smd mb-0">
                      {item.firstName} {item.lastName}
                    </p>
                  </span>
                </div>
                <br />
                <Formik
                  initialValues={{
                    firstName: item.firstName,
                    lastName: item.lastName,
                    phoneNumber: phoneNumber,
                    country: item.country,
                  }}
                  validationSchema={validationSchemaMomo}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form layout="vertical">
                      <Form.Item label="First Name">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                      </Form.Item>
                      <p className="text-danger">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </p>
                      <Form.Item label="Last Name">
                        <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </Form.Item>
                      <p className="text-danger">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </p>

                      <Form.Item>
                        <label className="text-smd mb-1">Phone number</label>
                        <ReactPhoneInput
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                            autoFocus: true,
                            className: "formInput",
                          }}
                          countryCodeEditable={false}
                          value={phoneNumber}
                          onChange={handleOnChangePhoneInput}
                          enableSearch={true}
                          onlyCountries={countryArray}
                          disableCountryCode={false}
                          country={values.country.toLowerCase()}
                          placeholder="0800 000 0000"
                          autoFormat={true}
                          enableClickOutside={true}
                          disabled={true}
                        />
                        {/* <Input
                          style={{ height: "20px" }}
                          size="small"
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                        /> */}
                      </Form.Item>
                      <p className="text-danger">
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </p>

                      <Form.Item label="Country">
                        <Select
                          size="large"
                          name="country"
                          value={values.country}
                          onChange={(value) => {
                            setFieldValue("country", value);
                            setCountry(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {countries.map((data) => (
                            <Option key={data.country} value={data.country}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="text-danger">
                        {errors.country && touched.country && errors.country}
                      </p>

                      <div className="flex justify-content-between mt-5">
                        <Button
                          type="primary"
                          className="btn-primary"
                          size={"large"}
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          block
                        >
                          Update Beneficiary
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteBeneficiaryModal
        show={showDeleteBeneficiaryModal}
        onClose={() => setShowDeleteBeneficiaryModal(false)}
        onOk={handleOk}
        item={item}
      />
    </StyledModal>
  );
}
