import React, {useState} from "react";
import { Button, Form } from "antd";
import VerifyPhoneNumberModalImage from "../../assets/images/Component 2.svg";
import { MediumText } from "../texts";
import StyledModal from "./styled-modal";
import ButtonComponent from "../otpInput";
import Countdown from "react-countdown";

export default function VerifyPhoneNumberModal({
  show,
  onClose,
  onClick,
  userNumber,
  handlePhonePinChange,
  phoneDisabled,
  loadingState,
  loading,
  resendOtp,
  trueState,
}) {

  // eslint-disable-next-line no-unused-vars
  const [countdownApi, setCountDownApi] = useState(null);

  function setRef(countdown) {
    if (countdown) {
      setCountDownApi(countdown.getApi());
    }
  }

  const Completionist = () => (
    <span
      className="color-primary text-bold cursor-pointer"
      onClick={resendOtp}
    >
      Resend OTP
    </span>
  );

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
      // return null;
    } else {
      // Render a countdown
      return <span>{seconds} seconds remaining</span>;
    }
  };

  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="flex justify-center flex-column align-center">
        <img src={VerifyPhoneNumberModalImage} alt="three stars" />
        <MediumText
          style={{ marginTop: "16px", marginBottom: "5px" }}
          value="Verify phone number"
        />
        <p className="mb-1">We sent a code to your number</p>
        <p>{userNumber} </p>
      </div>
      <Form layout="vertical">
        <Form.Item>
          <div className="otp-css">
            <ButtonComponent
              secret
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              inputStyle={{
                border: "none",
                borderBottom: "2px solid #707070",
                color: "#949494",
              }}
              focus
              handlePincomplete={handlePhonePinChange}
              length={6}
            />
          </div>
        </Form.Item>
        <div className="mt-5 flex mx-auto" style={{ maxWidth: "230px" }}>
          <Button
            type="primary"
            className="resend-modal-btn text-bold text-smd e__btn--primary"
            disabled={phoneDisabled}
            onClick={onClick}
            loading={loading}
          >
            {loadingState ? "" : "Verify"}
          </Button>
        </div>
        <p className="text-center mt-3">
          Didn't get code?&nbsp;
          {trueState === true ? (
            <Countdown  ref={(countdown) => setRef(countdown)} date={Date.now() + 30000} renderer={renderer} />
          ) : null}
        </p>
      </Form>
    </StyledModal>
  );
}
