import makeAPICall from "../../utilities/apiUtils";
import { fetchRoles, fetchRolesSuccess, fetchRolesError } from "../selectors";

const fetchRoleAction = () => (dispatch) => {
  dispatch(fetchRoles());
  return makeAPICall({
    path: "settings/roles",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchRolesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchRolesError(err.message));
    });
  // dispatch(fetchRolesSuccess(data));
};

export { fetchRoleAction };
