import React from "react";
import { Modal, Button } from "antd";
import { ReactComponent as BlueCheckIcon } from "../../assets/images/big-check-blue.svg";

export default function WelcomeModal({
  setShowWelcomeModal,
  showWelcomeModal,
}) {
  return (
    <Modal
      centered
      visible={showWelcomeModal}
      onOk={() => setShowWelcomeModal(false)}
      onCancel={() => setShowWelcomeModal(false)}
      footer={null}
    >
      <div className="flex align-center justify-center mt-4">
        <BlueCheckIcon />
      </div>
      <div className="mt-4">
        <p className="text-bold color-bold text-md text-center mb-0">
          Welcome to Eversend
        </p>
        <p className="text-center color-text text-smd">
          Your account has been created successfully
        </p>
      </div>
      <div className="mb-4 mt-4">
        <Button type="primary" className="welcome-modal-btn text-bold text-smd">
          Continue to dashboard
        </Button>
      </div>
    </Modal>
  );
}
