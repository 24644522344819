import React, { useState } from 'react';
import _ from 'lodash';

import AccountSummaryOverView from './accountSummary/Overview';
import AvailableAccountBalance from './accountSummary/AccountBalance';

export default function Summary({ wallets, accountSummary, loading, accountLoader, user }) {
	const [ showAccSummary, setShowAccSummary ] = useState(false);

	function toggleAccSummaryDisplay() {
		setShowAccSummary(!showAccSummary);
	}

	return (
		<div>
			<AccountSummaryOverView summary={accountSummary} accountLoader={accountLoader} wallets={wallets} showBal={showAccSummary} handleShowBalance={toggleAccSummaryDisplay} />
			<AvailableAccountBalance loading={loading} wallets={_.remove(_.concat([], wallets), ({ enabled }) => enabled)} showBal={showAccSummary} user={user} />
		</div>
	);
}
