import _ from "lodash";
import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

import { formatMoney, useWindowSize } from "../../utilities/helperFunctions";

const fontFamily =
  '"eFont", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

const Chart = (props) => {
  const { state, suffix } = props;

  const windowWidth = useWindowSize().width;

  return (
    <Bar
      className="dashboard-analytics-graph"
      data={state}
      height={windowWidth < 768 ? 200 : ""}
      options={{
        responsive: true,
        type: "bar",
        layout: {
          padding: 0,
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              padding: 0,
              font: {
                family: fontFamily,
                size: 15,
              },
            },
            display: true,
            position: "bottom",
            align: "end",
            title: {
              display: true,
              padding: {
                top: 5,
              },
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = _.trim(context.dataset.label || "");

                if (context.parsed.y !== null) {
                  label += ": " + formatMoney(context.parsed.y) + `${suffix}`;
                }

                return label;
              },
              title: (context) => {
                const { graphType, dates } = context[0].dataset;
                const index = context[0].dataIndex;

                let format = "D MMMM, YYYY";

                if (graphType === "weekly") {
                  format = "w, YYYY";
                } else if (graphType === "monthly") {
                  format = "MMMM, YYYY";
                } else if (graphType === "yearly") {
                  format = "YYYY";
                }

                return `${graphType === "weekly" ? "Week " : ""}${moment(
                  dates[index]
                ).format(format)}`;
              },
            },
            titleFont: {
              family: fontFamily,
              size: 15,
            },
            bodyFont: {
              family: fontFamily,
              size: 14,
            },
            padding: 10,
            titleMarginBottom: 5,
            caretSize: 8,
            cornerRadius: 5,
            displayColors: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                family: fontFamily,
                size: 14,
              },
              autoSkip: true,
              maxTicksLimit:
                windowWidth < 768
                  ? 4
                  : windowWidth < 1200
                  ? 6
                  : windowWidth < 1600
                  ? 7
                  : 7,
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                family: fontFamily,
                size: 14,
              },
              callback: (value, index, ticks) => {
                return formatMoney(value) + `${suffix}`;
              },
              autoSkip: true,
              maxTicksLimit: windowWidth < 768 ? 3 : 4,
            },
          },
        },
      }}
    />
  );
};

export default Chart;
