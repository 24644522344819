import { message } from "antd";

import makeAPICall from "../../utilities/apiUtils";
import {
  fetchUsers,
  fetchUsersError,
  fetchUsersSuccess,
  fetchIPAddress,
  fetchIPAddressError,
  fetchIPAddressSuccess,
  updateProfile,
  updateProfileError,
  addUser,
  addUserError,
  addUserSuccess,
  addIPAddress,
  addIPAddressError,
  addIPAddressSuccess,
  deleteIPAddress,
  deleteIPAddressError,
  deleteIPAddressSuccess,
} from "../selectors";
import { fetchProfileAction } from "./authActions/fetchProfileAction";

const key = "updatable";

const updateProfileAction = (profile) => (dispatch) => {
  dispatch(updateProfile());
  return makeAPICall({
    path: "profile",
    payload: profile,
    method: "PUT",
  })
    .then((res) => {
      // const { profile } = res.data;
      // dispatch(updateProfileSuccess(profile));
      dispatch(fetchProfileAction());
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(updateProfileError(err.message));
    });
};

const updateAccountAction = (account) => (dispatch) => {
  message.loading({ content: "Loading...", key });
  dispatch(updateProfile());
  return makeAPICall({
    path: "account",
    payload: account,
    method: "PUT",
  })
    .then((res) => {
      message.success({
        content: "Account info updated successfully",
        key,
        duration: 4,
      });

      dispatch(fetchProfileAction());
    })
    .catch((err) => {
      message.error({ content: err.message, key, duration: 4 });
      dispatch(updateProfileError(err.message));
    });
};

const fetchUsersAction = () => (dispatch) => {
  dispatch(fetchUsers());
  return makeAPICall({
    path: "users",
    method: "GET",
  })
    .then((res) => {
      const { users } = res.data;
      dispatch(fetchUsersSuccess(users));
    })
    .catch((err) => {
      dispatch(fetchUsersError(err.message));
    });
};

const addUserAction = (user) => (dispatch) => {
  // message.loading({ content: "Loading...", key });
  dispatch(addUser());
  return makeAPICall({
    path: "users",
    payload: user,
    method: "POST",
  })
    .then((res) => {
      message.success({
        content: "User added successfully",
        key,
        duration: 4,
      });
      const { user } = res.data;
      dispatch(addUserSuccess(user));
    })
    .catch((err) => {
      message.error({ content: err.message, key, duration: 4 });
      dispatch(addUserError(err.message));
    });
};

const fetchIPAddressAction = () => (dispatch) => {
  dispatch(fetchIPAddress());
  return makeAPICall({
    path: "account/whitelist",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchIPAddressSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchIPAddressError(err.message));
    });
};

const addIPAddressAction = (data) => (dispatch) => {
  message.loading({ content: "Loading...", key });
  dispatch(addIPAddress());
  return makeAPICall({
    path: "account/whitelist",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      message.success({
        content: "Ip address whitelisted",
        key,
        duration: 4,
      });
      const { user } = res.data;
      dispatch(addIPAddressSuccess(user));
      dispatch(fetchIPAddressAction());
    })
    .catch((err) => {
      message.error({ content: err.message, key, duration: 4 });
      dispatch(addIPAddressError(err.message));
    });
};

const deleteIPAddressAction = (ipAddressId) => (dispatch) => {
  message.loading({ content: "Loading...", key });
  dispatch(deleteIPAddress());
  return makeAPICall({
    path: `account/whitelist/${ipAddressId}`,
    payload: {},
    method: "DELETE",
  })
    .then((res) => {
      message.success({
        content: "IP Address Blacklisted",
        key,
        duration: 4,
      });
      const { ip_address } = res.data;
      dispatch(deleteIPAddressSuccess(ip_address));
      dispatch(fetchIPAddressAction());
    })
    .catch((err) => {
      message.error({ content: err.message, key, duration: 4 });
      dispatch(deleteIPAddressError(err.message));
    });
};

export {
  updateProfileAction,
  updateAccountAction,
  fetchUsersAction,
  addUserAction,
  fetchIPAddressAction,
  addIPAddressAction,
  deleteIPAddressAction,
};
