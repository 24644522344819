import { message } from "antd";
import makeAPICall from "../../../utilities/apiUtils";
import {
  fetchActivateWallet,
  fetchActivateWalletSuccess,
  fetchActivateWalletError,
  fetchDeactivateWallet,
  fetchDeactivateWalletSuccess,
  fetchDeactivateWalletError,
  fetchReOrderWallet,
  fetchReOrderWalletSuccess,
  fetchReOrderWalletError,
} from "../../selectors";
import { fetchWalletsAction } from "../../../services/actions";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_deactivated_walllet",
  },
};

const tagManagerArgsActivate = {
  dataLayer: {
    event: "b2b_activated_walllet",
  },
};

const activateWalletAction = (data) => (dispatch) => {
  dispatch(fetchActivateWallet());

  return makeAPICall({
    path: "wallets/activate",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      message.success(`Wallet activated successfully`);
      TagManager.dataLayer(tagManagerArgsActivate);
      dispatch(fetchActivateWalletSuccess(res));
      dispatch(fetchWalletsAction());
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(fetchActivateWalletError(err.message));
    });
};

const deactivateWalletAction = (data) => (dispatch) => {
  dispatch(fetchDeactivateWallet());

  return makeAPICall({
    path: "wallets/deactivate",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      message.success(`Wallet deactivated successfully`);
      TagManager.dataLayer(tagManagerArgs);
      dispatch(fetchDeactivateWalletSuccess(res));
      dispatch(fetchWalletsAction());
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(fetchDeactivateWalletError(err.message));
    });
};

const reorderWalletAction = (data) => (dispatch) => {
  dispatch(fetchReOrderWallet());

  return makeAPICall({
    path: "wallets/reorder",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      // console.log(res, 'reorder');
      const { user } = res.data;
      dispatch(fetchReOrderWalletSuccess(user));
      dispatch(fetchWalletsAction());
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(fetchReOrderWalletError(err.message));
    });
};

export { activateWalletAction, deactivateWalletAction, reorderWalletAction };
