import makeAPICall from "../../../utilities/apiUtils";
import {
  verifyPasswordReset,
  verifyPasswordResetError,
  verifyPasswordResetSuccess,
} from "../../selectors";
import { message } from "antd";

const verifyPasswordResetAction = (data) => (dispatch) => {
  const { code } = data;
  dispatch(verifyPasswordReset());
  return makeAPICall({
    path: "auth/verifyPasswordResetCode",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      dispatch(verifyPasswordResetSuccess(code));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(verifyPasswordResetError(err.message));
    });
};

export default verifyPasswordResetAction;
