import makeAPICall from "../../../utilities/apiUtils";
import {
  businessInfo,
  businessInfoError,
  businessInfoSuccess,
  businessInfoReset,
  businessGetInfo,
  businessGetInfoError,
  businessGetInfoSuccess,
} from "../../selectors";

const businessInfoAction = (data) => (dispatch) => {
  dispatch(businessInfo());

  return makeAPICall({
    path: "account",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      // console.log(res);
      const { account } = res.data;
      dispatch(businessInfoSuccess(account));
    })
    .catch((err) => {
      dispatch(businessInfoError(err.message));
    });
};

const businessInfoGetAction = () => (dispatch) => {
  dispatch(businessGetInfo());

  return makeAPICall({
    path: "account",
    method: "GET",
  })
    .then((res) => {
      const { account } = res.data;
      dispatch(businessGetInfoSuccess(account));
    })
    .catch((err) => {
      dispatch(businessGetInfoError(err.message));
    });
};

const businessInfoActionReset = () => (dispatch) => {
  dispatch(businessInfoReset());
};

export { businessInfoAction, businessInfoGetAction, businessInfoActionReset };
