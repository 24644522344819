import ACTIONS from "../constants";

const initialState = {
  quotation: null,
  errorMessage: null,
  loading: false,
  submitQuotations: null,
  submitErrorMessage: null,
  submitLoading: false,
};

const quotationsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.CREATE_EXCHANGE_QUOTATION:
      return {
        ...state,
        errorMessage: null,
        loading: true,
        submitErrorMessage: null,
      };
    case ACTIONS.CREATE_EXCHANGE_QUOTATION_SUCCESS:
      return {
        ...state,
        quotation: payload,
        loading: false,
        errorMessage: null,
      };
    case ACTIONS.CREATE_EXCHANGE_QUOTATION_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
        quotation: null,
      };
    case ACTIONS.SUBMITTED_QUOTATION:
      return {
        ...state,
        submitErrorMessage: null,
        submitQuotations: null,
        submitLoading: true,
      };
    case ACTIONS.SUBMITTED_QUOTATION_SUCCESS:
      return {
        ...state,
        submitQuotations: payload,
        submitLoading: false,
        submitErrorMessage: null,
      };
    case ACTIONS.SUBMITTED_QUOTATION_ERROR:
      return {
        ...state,
        submitErrorMessage: payload,
        submitLoading: false,
        submitQuotations: null,
      };
    case ACTIONS.RESET_QUOTATION_STATE:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        quotation: null,
        submitQuotations: null,
        submitErrorMessage: null,
        submitLoading: false,
      };
    default:
      return state;
  }
};

export default quotationsReducer;
