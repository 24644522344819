import ACTIONS from '../constants';

const initialState = {
  users: [],
  errorMessage: null,
  loading: false,
  addUser: null,
};

const usersReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_USERS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case ACTIONS.FETCH_USERS_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.ADD_USER:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.ADD_USER_SUCCESS:
      return {
        ...state,
        addUser: payload,
        loading: false,
      };
    case ACTIONS.ADD_USER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default: return state;
  }
};

export default usersReducer;
