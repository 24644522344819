import ACTIONS from "../../constants";

const initialState = {
  user: {},
  errorMessage: null,
  loading: false,
};

const loginReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.LOGIN_USER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
