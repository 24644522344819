import React from "react";
import close from "../../assets/images/Close.svg";

export default function StyledModal({ children, show, onClose }) {
  return (
    <>
      {show ? (
        <div className="styled-modal-overlay"
        >
          <div 
            style={{
              position: "relative",
              top: "0px",
              left: "0px",
              width: "100vw",
              height: "10vh",
              zIndex: "1000",
            }}
          >
            <div
              onClick={onClose}
              style={{
                position: "absolute",
                right: "10px",
                top: "40px",
                cursor: "pointer",
                padding: "4px",
                width: "32px",
                height: "32px",
                background: "#ffffff",
                borderRadius: "6px",
              }}
            >
              <img src={close} alt="close modal" />
            </div>
          </div>
          <div
            style={{
              top: "0px",
              left: "0px",
              minWidth: "100vw",
              width: "100%",
              minHeight: "90vh",
              height: "80vh",
              borderRadius: "12px 12px 0 0",
              zIndex: "1000",
              backgroundColor: "#fff",
              // overflow: "auto",
              // overflowX: "scroll",
              overflowY: "auto",
            }}
          >
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
}
