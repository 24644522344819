import React, { useState, useEffect } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Table,
  Tag,
  Space,
  Radio,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import Loader from "../../components/loaders/loading";
import bankIcon from "../../assets/images/bank-pay.svg";
import momo from "../../assets/images/momo_payment.svg";
import gears from "../../assets/images/filter-gears.svg";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchTransactionsAction,
} from "../../services/actions/";
import accessIcon from "../../assets/images/noAccess.svg";
import { useIntercom } from "react-use-intercom";

export default function PayoutsHistory() {
  const { boot } = useIntercom();
  const history = useHistory();
  const dispatch = useDispatch();

  const { transactions, loading: isLoading } = useSelector(
    (state) => state.transactionsReducer
  );
  const { user } = useSelector((state) => state.profileReducer);

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    "isB2B": "B2B",
  });

  // console.log(transactions);
  const { wallets } = useSelector((state) => state.walletsReducer);

  const [dateValue, setDateValue] = useState("thirtydays");
  const [fromDateValueMoment, setFromDateValueMoment] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMoment, setToDateValueMoment] = useState(moment());

  //const [isFilled, setIsFilled] = useState(false);
  const [hasKyc, setHasKyc] = useState(true);
  //const [isLoadingState, setIsLoadingState] = useState(false);

  const [filters, setFilters] = useState({
    type: "payout",
  });
  // const [pagination, setPagination] = useState();

  // useEffect(() => {
  //   dispatch(fetchProfileAction());
  // }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
    }
  }, [user]);

  // function onChange(value) {}

  function handleDatePickerChangeFrom(date) {
    setFromDateValueMoment(date);
    setFilters({
      ...filters,
      from: date,
    });
  }

  function handleDatePickerChangeTo(date) {
    setToDateValueMoment(date);
    setFilters({
      ...filters,
      to: date,
    });
  }

  function handlePagination(item) {
    dispatch(
      fetchTransactionsAction({
        params: { ...filters, limit: item.pageSize, page: item.current },
      })
    );
  }

  function handleSelectStatus(selectedItem) {
    setFilters({
      ...filters,
      status: selectedItem,
    });
  }

  function handleSelectCurrency(selectedItem) {
    setFilters({
      ...filters,
      currency: selectedItem,
    });
  }

  function getData() {
    dispatch(
      fetchTransactionsAction({
        params: {
          ...filters,
          ...{
            limit: 10,
            page: 1,
          },
        },
      })
    );
  }

  function clearFilters() {
    setFilters({
      type: "payout",
    });
  }

  const plainOptions = [
    { label: "Successful", value: "successful" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
  ];
  const { Option } = Select;

  const changeDateValue = (e) => {
    setDateValue(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMoment(moment().subtract(30, "days"));
      setToDateValueMoment(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMoment(moment().startOf("day"));
      setToDateValueMoment(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMoment(moment().subtract(7, "days"));
      setToDateValueMoment(moment());
    } else {
      setFromDateValueMoment(moment());
      setToDateValueMoment(moment());
    }
  };

  // const downloadStatement = (e) => {
  //   e.preventDefault();

  //   // dispatch(fetchStatementAction({ params: { from: fromDateValueMoment.format("YYYY-MM-DD"), to: toDateValueMoment.format("YYYY-MM-DD"), currencies: currencies  } }));
  // };

  const getRow = (item) => {
    history.push({
      pathname: `/transaction/details/${item.transactionId}`,
      // state: `${JSON.stringify({ ...item })}`,
    });
  };

  const transactionColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "100px",
      render: (type) => {
        if (type === "bank") {
          return (
            <div>
              <img src={bankIcon} alt="bank" style={{ width: "13px" }} />
              &nbsp; {type}
            </div>
          );
        } else {
          return (
            <div>
              <img src={momo} alt="momo" style={{ width: "13px" }} />
              &nbsp; {type}
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (status) => {
        if (status === "successful") {
          return <Tag color="green">{status}</Tag>;
        } else {
          return <Tag color="volcano">{status}</Tag>;
        }
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Receipent",
      dataIndex: ["beneficiary"],
      render: (beneficiary) =>
        `${beneficiary?.firstName} ${beneficiary?.lastName}`,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, row) => `${row.currency} ${Math.abs(amount)}`,
    },
    {
      title: "Date Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MMM D, YYYY - h:mm A"),
    },
  ];

  const filterMenu = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValue} onChange={changeDateValue}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeFrom}
              value={fromDateValueMoment}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeTo}
              value={toDateValueMoment}
            />
          </Space>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Status</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Checkbox.Group
            options={plainOptions}
            onChange={handleSelectStatus}
            value={filters.status}
          />
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={handleSelectCurrency}
            value={filters.currency}
          >
            {wallets.map((option, index) => (
              <Option value={option.currency} key={index}>
                {option.currency}
              </Option>
            ))}
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button type="default" onClick={clearFilters}>
            Clear
          </Button>
          <Button className="btn-primary" type="primary" onClick={getData}>
            Filter
          </Button>
        </div>
      </div>
    </Menu>
  );

  // const statementMenu = (
  //   <Menu>
  //     <div className="playground-filter">
  //       <div className="pt-3 px-3">
  //         <h1>Filter your download</h1>
  //       </div>
  //       <hr />
  //       <div className="pt-1 pb-1 px-3">
  //         <h1>Date</h1>
  //       </div>
  //       <div className="dateHandle py-3 px-3">
  //         <Radio.Group value={dateValue} onChange={changeDateValue}>
  //           <Radio.Button value="today">Today</Radio.Button>
  //           <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
  //           <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
  //           <Radio.Button value="custom">Custom</Radio.Button>
  //         </Radio.Group>
  //       </div>
  //       <div className="pt-3 pb-1 px-3">
  //         <h1>Range</h1>
  //       </div>
  //       <div className="dateHandle py-3 px-3">
  //         <Space size={12}>
  //           <DatePicker
  //             style={{ width: "180px" }}
  //             onChange={handleDatePickerChangeFrom}
  //             value={fromDateValueMoment}
  //           />
  //           <DatePicker
  //             style={{ width: "180px" }}
  //             onChange={handleDatePickerChangeTo}
  //             value={toDateValueMoment}
  //           />
  //         </Space>
  //       </div>

  //       <div className="pt-3 pb-1 px-3">
  //         <h1>Currency</h1>
  //       </div>
  //       <div className="dateHandle py-3 px-3">
  //         <Select
  //           // showSearch
  //           style={{ width: "100%" }}
  //           placeholder="All Currency"
  //           onChange={onChange}
  //           mode="multiple"
  //           allowClear
  //         >
  //           {wallets.map((option, index) => (
  //             <Option value={option.currency} key={index}>
  //               {option.currency}
  //             </Option>
  //           ))}
  //         </Select>
  //       </div>
  //       <div className="pt-3 pb-2 px-3 text-right">
  //         <Button
  //           type="primary"
  //           size="large"
  //           onClick={downloadStatement}
  //           disabled={isFilled === false ? true : false}
  //           loading={isLoadingState}
  //         >
  //           {isLoadingState ? "" : "Download Statement"}
  //         </Button>
  //       </div>
  //     </div>
  //   </Menu>
  // );

  useEffect(() => {
    dispatch(
      fetchTransactionsAction({
        params: { type: "payout" },
      })
    );
  }, [dispatch]);

  return (
    <>
      <div>
        <h1 className="singleWallet-h1">
          <span
            onClick={() => {
              history.push("/payouts");
            }}
          >
            Payouts
          </span>
          &nbsp;
          <i className="fa fa-angle-right"></i>&nbsp; Payouts history
        </h1>
      </div>
      {hasKyc ? (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!transactions || transactions.transactions?.length === 0 ? (
                <div className="completed-kyc-state">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img src={accessIcon} alt="" />
                      <h1>No payouts</h1>
                      <p>You have not perfomed any transactions yet</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white p-2" style={{ position: "relative" }}>
                  <div className="transaction-filters">
                    <Space wrap>
                      <Dropdown overlay={filterMenu} placement="bottomRight">
                        <Button>
                          <span>
                            <img src={gears} alt="" />
                          </span>
                          &nbsp; Filter by <DownOutlined />
                        </Button>
                      </Dropdown>
                      {/* <Dropdown.Button
overlay={statementMenu}
icon={<DownOutlined />}
placement="bottomRight"
style={{
backgroundColor: "#E5E5E5",
borderRadius: "0px 4px 4px 0px",
}}
>
Download
</Dropdown.Button> */}
                    </Space>
                  </div>
                  <Table
                    columns={transactionColumns}
                    dataSource={transactions.transactions}
                    rowKey="transactionId"
                    pagination={{
                      pageSize: transactions.limit,
                      pageSizeOptions: [10, 20, 30],
                      showSizeChanger: true,
                      total: transactions.total,
                      position: ["topLeft"],
                      current: transactions.page,
                      showTitle: true,
                    }}
                    scroll={{ y: 500 }}
                    onChange={(pagination) => {
                      handlePagination(pagination);
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          getRow(record);
                        }, // click row
                      };
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button>Complete KYB</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
