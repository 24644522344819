import React from "react";
import notify from "../../../assets/images/notify-icon.png";

export default function Third({reference, closeModal}) {
  return (
    <>
      <div className="continue-to-wallet">
        <div className="text-center">
          <img src={notify} alt="" />
        </div>
        <h1 className="naija-account-number-h1 text-center">
          Transfer notification sent
        </h1>
        <p className="desc-p text-center">
          Your bank transfer notification has been sent to the Eversend team and
          would be added to your wallet shortly after we receive the transfer
          you made
        </p>
        <div className="d-flex greyed-out">
          <div>
            <p className="naija-account-number-p">Transaction Reference</p>
          </div>
          <div className="ml-auto align-self-center">
            <p className="naija-account-number-h1">{reference}</p>
          </div>
        </div>
        <br />
        <div className="text-center">
          <button className="btn-btn-btn" onClick={closeModal}>
            Continue to wallets
          </button>
        </div>
      </div>
    </>
  );
}
