import React from "react";
import { ReactComponent as ExcelImage } from "../../../assets/images/Rectangle 483.svg";
import { Row, Col } from "antd";
// import { Link } from "react-router-dom";

export default function AddBulkBeneficiaries({ onChangeViewShow }) {
  return (
    <div>
      <div>
        <ExcelImage />
      </div>
      <div className="flex flex-column justify-center mt-4 bulk__description--text">
        <p className="text-smd text-center">
          To import Multiple beneficiary details into Eversend, you would need
          to use an .XLSX document.
        </p>
        <p className="text-center">
          Download the template here or import directly if you already have a
          filled template
        </p>
      </div>
      <Row>
        <Col span={24} className="flex justify-content-start">
          <a
            type="primary"
            className="btn btn-primary"
            download
            href="https://storage.googleapis.com/eversend-business/assets/files/Eversend_Beneficiaries.xlsx"
            rel="noreferrer"
            target="_blank"
            onClick={onChangeViewShow}
            style={{ width: "100%" }}
          >
            Download Sample
          </a>
        </Col>
      </Row>
      <div className="flex justify-center mt-5">
        <p
          onClick={onChangeViewShow}
          className="text-bold color-primary cursor-pointer"
        >
          I already have an existing template
        </p>
      </div>
    </div>
  );
}
