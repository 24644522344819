import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";

import { useSelector, useDispatch } from "../../../../utilities/hooks";
import { formatMoney } from "../../../../utilities/helperFunctions";
import {
  Button,
  Input,
  Form,
  Select,
  InputNumber,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import { createPayoutQuotationAction } from "../../../../services/actions";
import { ReactComponent as FadingElipses } from "../../../../assets/images/fading-elipse.svg";
import exchangeLogo from "../../../../assets/images/exch.svg";
import * as ctzc from "country-tz-currency";

export default function Second({
  goBack,
  profile,
  onClick,
  paymentType,
  onNumberChange,
  onSetPayOutData,
  payOutData,
}) {
  const { wallets } = useSelector((state) => state.walletsReducer);

  const { accountSummary } = useSelector((state) => state.analyticsReducer);
  const { payoutQuotation, loadingQuotation: loadingPayQuotation } =
    useSelector((state) => state.transactionsReducer);

  const dispatch = useDispatch();
  const [exchangeFields, setExchangeFields] = useState({
    from: accountSummary?.currency,
    amount: 100,
  });

  let filterTimeout;
  let filterTimeoutTwo;
  const limit = 700;

  const toAmount = ctzc.getCountryByCode(profile.country).currencyCode;

  const [inputAmount, setInputAmount] = useState(0);
  const [isShown, setIsShown] = useState(false);
  const [usedNumber, setUsedNumber] = useState(30000);

  const { Option } = Select;

  useEffect(() => {
    setUsedNumber(30000);
    onNumberChange({
      startTime: Date.now(),
      endTime: Date.now() + 30000,
    });
    setIsShown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  function onValueChange(value, name) {
    setExchangeFields({
      ...exchangeFields,
      [name]: value,
    });

    if (name === "from") {
      handleChangeInput(value);
    }
  }

  const handleChangeInput = (e) => {
    const from = e;
    const { amount } = exchangeFields;

    onSetPayOutData({
      sourceWallet: from,
      amount: amount.toString(),
      type: paymentType,
      destinationCountry: profile?.country,
      destinationCurrency: toAmount,
      amountType: "SOURCE",
    });

    const data = {
      sourceWallet: from,
      amount: amount.toString(),
      type: paymentType,
      destinationCountry: profile?.country,
      destinationCurrency: toAmount,
      amountType: "SOURCE",
    };

    dispatch(createPayoutQuotationAction(data));
  };

  const updateQueryOne = (value) => {
    // A search query api call.
    clearTimeout(filterTimeout);
    if (!value)
      return setExchangeFields({
        ...exchangeFields,
        amount: 0,
      });
    const { from } = exchangeFields;

    filterTimeout = setTimeout(() => {
      // console.log("=====> source", value);
      if (value) {
        setExchangeFields({
          ...exchangeFields,
          amount: value,
        });

        onSetPayOutData({
          sourceWallet: from,
          amount: value.toString(),
          type: paymentType,
          destinationCountry: profile?.country,
          destinationCurrency: toAmount,
          amountType: "SOURCE",
        });

        const data = {
          sourceWallet: from,
          amount: value.toString(),
          type: paymentType,
          destinationCountry: profile?.country,
          destinationCurrency: toAmount,
          amountType: "SOURCE",
        };

        dispatch(createPayoutQuotationAction(data));
      }
    }, limit);
  };

  const updateQuery = (value) => {
    clearTimeout(filterTimeoutTwo);
    if (!value) return setInputAmount(0);
    const { from } = exchangeFields;
    const walletDetails = wallets.filter((wallet) => {
      return wallet.currency === exchangeFields.from;
    });
    filterTimeoutTwo = setTimeout(() => {
      if (
        value / payoutQuotation?.quotation?.exchangeRate >
        walletDetails[0]?.amount
      ) {
        return message.error(
          "Please enter amount less than your wallet balance"
        );
      } else {
        setInputAmount(value);

        onSetPayOutData({
          sourceWallet: from,
          amount: value.toString(),
          type: paymentType,
          destinationCountry: profile?.country,
          destinationCurrency: toAmount,
          amountType: "DESTINATION",
        });

        const data = {
          sourceWallet: from,
          amount: value.toString(),
          type: paymentType,
          destinationCountry: profile?.country,
          destinationCurrency: toAmount,
          amountType: "DESTINATION",
        };

        dispatch(createPayoutQuotationAction(data));
      }
    }, limit);
  };

  const walletAmount = wallets.filter((wallet) => {
    return wallet.currency === exchangeFields.from;
  });

  useEffect(() => {
    if (payoutQuotation?.quotation) {
      const { sourceAmount, destinationAmount } = payoutQuotation?.quotation;
      setExchangeFields({
        ...exchangeFields,
        amount: sourceAmount,
      });
      setInputAmount(destinationAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  const showBankForm = () => {
    if (walletAmount[0]?.amount < payoutQuotation?.quotation?.totalAmount) {
      return message.error("Insufficient Amount");
    } else {
      onClick(payoutQuotation);
    }
  };

  const renderer = ({ seconds }) => <b>Continue ({seconds})</b>;

  const handleComplete = (e) => {
    setIsShown(false);
    dispatch(createPayoutQuotationAction(payOutData));
  };

  return (
    <div className="paySomeone">
      <div className="exchange-select">
        <p>How much are you sending</p>

        <Form>
          <Input.Group compact style={{ width: "100%" }}>
            <Select
              style={{ width: "30%" }}
              name="from"
              onChange={(value) => onValueChange(value, "from")}
              // disabled={loadingWallets}
              value={exchangeFields.from}
            >
              {wallets
                .filter((data) => data.enabled && data.isCrypto === false)
                .map((wallet) => {
                  return (
                    <Option key={wallet.currency} value={wallet.currency}>
                      <img src={wallet.icon} alt="flag" />
                      {wallet.currency}
                    </Option>
                  );
                })}
            </Select>
            <InputNumber
              style={{ width: "70%" }}
              defaultValue="100.00"
              value={exchangeFields.amount}
              min="0"
              precision="2"
              name="amount"
              onChange={(value) => updateQueryOne(value)}
              // onBlur={handleChanges}
            />
          </Input.Group>
          <br />
          {payoutQuotation?.quotation?.sourceCurrency ? (
            <p className="text-black-50">
              {exchangeFields.from} balance:&nbsp;
              {formatMoney(walletAmount[0]?.amount.toFixed(2))}
            </p>
          ) : null}

          <hr />

          <Row type="flex" align="middle" className="mt-4">
            <Col style={{ marginRight: "40px" }}>
              {/* <RotateIcon width="48px" /> */}
              <img src={exchangeLogo} alt="" />
            </Col>
            <Col style={{ marginRight: "20px" }}>
              <FadingElipses />
            </Col>
            <Col>
              <div>
                <span>Exchange Rate </span>
              </div>
              <div className="mt-2">
                {payoutQuotation?.quotation?.sourceCurrency ? (
                  <h6>
                    <b>
                      {payoutQuotation?.quotation?.sourceCurrency}&nbsp;1
                      &nbsp;&nbsp; =&nbsp;&nbsp;
                      {payoutQuotation?.quotation?.exchangeRate}&nbsp;
                      {payoutQuotation?.quotation?.destinationCurrency}
                    </b>
                  </h6>
                ) : null}
              </div>
              <span>
                Fees:
                <>
                  {payoutQuotation?.quotation?.totalFees ? (
                    <>
                      {payoutQuotation.quotation.sourceCurrency}&nbsp;
                      {formatMoney(payoutQuotation.quotation.totalFees)}
                    </>
                  ) : null}
                </>
              </span>
            </Col>
          </Row>

          <div className="pt-4">
            <p className="">Reciever would get</p>
          </div>

          {loadingPayQuotation ? (
            <Spin tip="Loading...">
              <Input.Group compact>
                <Select
                  style={{ width: "30%" }}
                  name="toAmount"
                  defaultValue={toAmount}
                  disabled
                >
                  {wallets
                    .filter((data) => data.enabled)
                    .map((wallet) => {
                      return (
                        <Option key={wallet.currency} value={wallet.currency}>
                          <img src={wallet.icon} alt="flag" />
                          {wallet.currency}
                        </Option>
                      );
                    })}
                </Select>

                <InputNumber
                  value={inputAmount}
                  style={{ width: "70%" }}
                  min="1"
                  precision="2"
                  // onBlur={handleChangesTwo}
                  onChange={(value) => updateQuery(value)}
                />
              </Input.Group>
            </Spin>
          ) : (
            <>
              <Input.Group compact>
                <Select
                  style={{ width: "30%" }}
                  name="toAmount"
                  defaultValue={toAmount}
                  disabled
                >
                  {wallets
                    .filter((data) => data.enabled)
                    .map((wallet) => {
                      return (
                        <Option key={wallet.currency} value={wallet.currency}>
                          <img src={wallet.icon} alt="flag" />
                          {wallet.currency}
                        </Option>
                      );
                    })}
                </Select>

                <InputNumber
                  value={inputAmount}
                  style={{ width: "70%" }}
                  min="1"
                  precision="2"
                  // onBlur={handleChangesTwo}
                  onChange={(value) => updateQuery(value)}
                />
              </Input.Group>
            </>
          )}

          <br />
          <Form.Item>
            <Button
              size="large"
              type="default"
              className="grey-btn"
              onClick={goBack}
              style={{ width: "49%" }}
            >
              Go Back
            </Button>
            <Button
              size="large"
              type="default"
              className="pay-blue-btn"
              onClick={showBankForm}
              disabled={
                loadingPayQuotation ||
                !payoutQuotation?.quotation ||
                walletAmount[0]?.amount <
                  payoutQuotation?.quotation?.totalAmount
              }
              style={{ width: "49%", marginLeft: "5px" }}
            >
              {loadingPayQuotation ||
              !payoutQuotation?.quotation ||
              walletAmount[0]?.amount <
                payoutQuotation?.quotation?.totalAmount ||
              !isShown ? (
                <b>Continue</b>
              ) : (
                <Countdown
                  date={Date.now() + usedNumber}
                  renderer={renderer}
                  onComplete={handleComplete}
                />
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
