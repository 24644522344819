import jwt_decode from "jwt-decode";
import { AUTH_TOKEN, USER_DATA } from "./constants";
import history from "./history";

export const isLogin = () => {
  let token = window.localStorage.getItem(AUTH_TOKEN);
  return !!token && !isSessionExpired(token);
};

export const isSessionExpired = (token) => {
  if (!token) return false;
  let decodedToken = jwt_decode(token);
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  }
};

export const logout = () => {
  history.push("/login");
  window.localStorage.clear();
  window.location.reload(true);
};

export const getUser = () => {
  const user = window.localStorage.getItem(USER_DATA);
  if (!user) return logout();
  return JSON.parse(user);
};
