import makeAPICall from "../../../utilities/apiUtils";
import {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,
} from "../../selectors";
import { message } from "antd";

const resetPasswordAction = (data) => (dispatch) => {
  dispatch(resetPassword());
  return makeAPICall({
    path: "auth/resetPassword",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      dispatch(resetPasswordSuccess());
      message.success("password reset successful", 5);
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(resetPasswordError(err.message));
    });
};

export { resetPasswordAction };
