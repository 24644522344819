import ACTIONS from "../constants";

const initialState = {
  ip_address: [],
  errorMessage: null,
  loading: false,
  wasUpdated: false,
};

const ipAddressReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_IP_ADDRESS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        ip_address: payload,
        loading: false,
        wasUpdated: false,
      };
    case ACTIONS.FETCH_IP_ADDRESS_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.ADD_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        wasUpdated: true,
      };
    case ACTIONS.DELETE_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        wasUpdated: true,
      };
    default:
      return state;
  }
};

export default ipAddressReducer;
