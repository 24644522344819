import React, { useState, useEffect } from "react";
import { Tabs, Table, Button, Radio, Space, Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { fetchBeneficiariesAction } from "../../../../services/actions";
import { useSelector, useDispatch } from "../../../../utilities/hooks";
import Loader from "../../../../components/loaders/loading";
import bank from "../../../../assets/images/bank_payment.svg";
import momo from "../../../../assets/images/momo_payment.svg";
import eversend from "../../../../assets/images/eversend-ben.png";

export default function First({ onClick, selectedBeneficiary, isFirstTime }) {
  const { TabPane } = Tabs;
  const { beneficiaries, loading: beneficiariesLoading } = useSelector(
    (state) => state.beneficiariesReducer
  );

  //   console.log(beneficiaries.beneficiaries);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [modalTrue, setModalTrue] = useState(false);
  const [beneficiary, setBeneficiary] = useState();
  const benefactors = beneficiaries?.beneficiaries;
  const eversendBeneficiaries = benefactors?.filter(
    (beneficiary) => beneficiary.isEversend
  );
  useEffect(() => {
    if (beneficiaries?.length === 0) {
      const params = {
        limit: 10,
        page: 1,
      };
      dispatch(fetchBeneficiariesAction({ params }));
    }
  }, [dispatch, beneficiaries]);

  useEffect(() => {
    setLoadingState(beneficiariesLoading);
  }, [beneficiariesLoading]);

  useEffect(() => {
    if (selectedBeneficiary !== null && isFirstTime) {
      // console.log("selected beneficiary", selectedBeneficiary);
      // console.log("first time", isFirstTime);
      setBeneficiary(selectedBeneficiary);
      setModalTrue(true);
      showModal();
    } else {
      return false;
    }
  }, [selectedBeneficiary, isFirstTime]);

  function callback(key) {
    // console.log(key);
    if (key === "2") {
      const params = {
        type: "eversend",
        limit: 10,
        page: 1,
      };
      dispatch(fetchBeneficiariesAction({ params }));
    } else {
      const params = {
        limit: 10,
        page: 1,
      };
      dispatch(fetchBeneficiariesAction({ params }));
    }
  }

  function handleChangeSearch(event) {
    // console.log(event.target.value);
    setSearch(event.target.value);
  }

  useEffect(() => {
    if (search) {
      dispatch(fetchBeneficiariesAction({ params: { search: search } }));
    }
  }, [dispatch, search]);

  const rowSelection = (record, index) => {
    // console.log(record, "match");
    setBeneficiary(record);
    setModalTrue(true);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showPageTwo = () => {
    onClick(paymentType, beneficiary);
  };

  function onChangeBank(e) {
    setPaymentType(e.target.value);
  }

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      width: "5%",
      render: (firstName, row) => {
        return (
          <div className="table-in">
            {firstName.charAt(0) + row.lastName.charAt(0)}
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "firstName",
      width: "50%",
      render: (firstName, row) => {
        if (row.isMomo === true && row.isBank === true) {
          return (
            <div>
              {firstName}&nbsp; {row.lastName}
              <br />
              Momo, Bank
            </div>
          );
        }
        if (row.isMomo === true && row.isBank === false) {
          return (
            <div>
              {firstName}&nbsp; {row.lastName}
              <br />
              Momo
            </div>
          );
        }
        if (row.isMomo === false && row.isBank === true) {
          return (
            <div>
              {firstName}&nbsp; {row.lastName}
              <br />
              Bank
            </div>
          );
        }
      },
    },
  ];

  const columnEversend = [
    {
      title: "First Name",
      dataIndex: "firstName",
      width: "5%",
      render: (firstName, row) => {
        return (
          <div className="table-in">
            {firstName.charAt(0) + row.lastName.charAt(0)}
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "firstName",
      width: "50%",
      render: (firstName, row) => {
        if (row.isEversend === true) {
          return (
            <div>
              {firstName}&nbsp; {row.lastName}
              <br />
              Eversend
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className="first-payout">
      <Input
        size="large"
        placeholder="Search beneficiary name / phone number"
        prefix={<SearchOutlined className="faded" />}
        className="header-search"
        style={{ height: "100%", maxWidth: "100%" }}
        onChange={handleChangeSearch}
      />
      <br />
      <Tabs defaultActiveKey="1" onChange={callback} centered>
        <TabPane tab="All beneficiaries" key="1">
          <p>Recent beneficiaries</p>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      rowSelection(record, rowIndex);
                    }, // click row
                  };
                }}
                showHeader={false}
                columns={columns}
                dataSource={beneficiaries.beneficiaries}
                pagination={false}
              />
            </div>
          )}
        </TabPane>
        <TabPane tab="Eversend" key="2">
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      rowSelection(record, rowIndex);
                    }, // click row
                  };
                }}
                showHeader={false}
                columns={columnEversend}
                dataSource={eversendBeneficiaries}
                pagination={false}
              />
            </div>
          )}
        </TabPane>
        {/* <TabPane tab="Group" key="3"></TabPane> */}
      </Tabs>
      {modalTrue && (
        <Modal
          centered
          footer={null}
          visible={isModalVisible}
          onCancel={handleCancel}
        >
          <div className="in-houseModal">
            <h1>Choose preferred payment method</h1>
            <br />
            <div className="text-center">
              <div className="table-in" style={{ margin: "auto" }}>
                {beneficiary.firstName.charAt(0) +
                  beneficiary.lastName.charAt(0)}
              </div>
              <br />
              <p>
                {beneficiary.firstName}&nbsp;
                {beneficiary.lastName}
              </p>

              <Radio.Group onChange={onChangeBank} value={paymentType}>
                <Space>
                  {beneficiary.isBank === true && (
                    <Radio value="bank">
                      <img
                        src={bank}
                        alt=""
                        style={{
                          marginRight: "10px",
                          width: "12px",
                          marginTop: "-4px",
                        }}
                      />
                      Bank
                    </Radio>
                  )}
                  {beneficiary.isMomo === true && (
                    <Radio value="momo">
                      <img
                        src={momo}
                        style={{
                          marginRight: "7px",
                          width: "11px",
                          marginTop: "-4px",
                        }}
                        alt=""
                      />
                      Momo
                    </Radio>
                  )}
                  {beneficiary.isEversend === true && (
                    <Radio value="eversend">
                      <img
                        src={eversend}
                        alt=""
                        style={{
                          marginRight: "10px",
                          width: "12px",
                          marginTop: "-4px",
                        }}
                      />
                      Eversend
                    </Radio>
                  )}
                </Space>
              </Radio.Group>
            </div>
            <br />
            <div className="text-center">
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                onClick={showPageTwo}
                disabled={!paymentType}
                style={{ width: "49%", marginLeft: "5px" }}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
