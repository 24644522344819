import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./services/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { unregister } from "./serviceWorkerRegistration";
import { IntercomProvider } from "react-use-intercom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import TagManager from "react-gtm-module";

import "./index.css";

import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";

const tagManagerArgs = {
  gtmId: "GTM-TNX9SR8",
};
TagManager.initialize(tagManagerArgs);

let persistor = persistStore(store);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// H.init('2d12kler', {
// 	tracingOrigins: true,
// 	networkRecording: {
// 		enabled: true,
// 		recordHeadersAndBody: true,
// 	},
// });

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
          <App />
        </IntercomProvider>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
