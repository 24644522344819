import React, { useState, useEffect } from "react";

export default function BusinessName({ user, onFormChange, handleSubTab}) {
  const [inputValues, setInputValues] = useState({
    name: "",
    bvn: "",
  });

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleFormChange () {
    onFormChange(inputValues)
    handleSubTab(1, 0); //first sub tab, pass the active tab index and active sub tab index, it checks and move to the next tab
  }

  useEffect(() => {
    if (user?.account) {
      handleSubTab(1, 0); //first sub tab, pass the active tab index and active sub tab index, it checks and move to the next tab
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="business-form-fields">
          <h1>Business information</h1>
          <p>Provide more information about your business.</p>
          <div className="form-group">
            <label className="label-form">Business name</label>
            <input
              type="text"
              name="name"
              value={inputValues.name}
              onChange={handleChangeInput}
              placeholder="Company name"
              className="reg-input"
            />
          </div>
          {user.businessCountry === "NG" && (
            <div className="form-group">
              <label className="label-form">BVN</label>
              <input
                type="number"
                name="bvn"
                value={inputValues.bvn}
                onChange={handleChangeInput}
                placeholder="00000000000"
                className="reg-input"
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-right">
        <button
          type="submit"
          disabled={!(inputValues.name.trim())}
          className="custom-modal__close-button"
          onClick={handleFormChange}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
