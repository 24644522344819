import React, { useEffect, useState } from "react";
import history from "../../../utilities/history";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { resetPasswordAction } from "../../../services/actions";
// import { ReactComponent as Check } from "../../../assets/images/password-check-active.svg";
// import { ReactComponent as Uncheck } from "../../../assets/images/password-check-inactive.svg";
import PasswordStrengthBar from "react-password-strength-bar";
import back from "../../../assets/images/back-button.svg";
import Loader from "../../../components/Loader";
import ClosedEye from "../../../assets/images/Eye_Closed.svg";
import OpenEye from "../../../assets/images/Eye_Open.svg";

export default function CreateNewPasswordView() {
  const { resetSuccessful, email, code } = useSelector(
    (state) => state.forgotPasswordReducer
  );
  const { loading } = useSelector((state) => state.resetPassword);

  const dispatch = useDispatch();

  const [passwordValues, setPasswordValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [inputType, setInputType] = useState("password");
  const [inputTypeOne, setInputTypeOne] = useState("password");
  const [isPasswordMatch, setIsPasswordMatch] = useState("idle");
  // const [isFilled, setIsFilled] = useState(false);
  const [isMin, setIsMin] = useState(false);
  const [isAlphaNum, setIsAlphNum] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function handleChange(event) {
    setPasswordValues({
      ...passwordValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleSubmit() {
    dispatch(
      resetPasswordAction({
        email,
        code,
        password: passwordValues.newPassword,
      })
    );
  }

  function goToDashboard() {
    history.push("/login");
  }

  useEffect(() => {
    if (
      passwordValues.confirmNewPassword !== "" &&
      passwordValues.newPassword === passwordValues.confirmNewPassword
    ) {
      setIsPasswordMatch("matched");
    } else if (passwordValues.confirmNewPassword === "") {
      setIsPasswordMatch("idle");
    } else {
      setIsPasswordMatch("noMatch");
    }
  }, [passwordValues.newPassword, passwordValues.confirmNewPassword]);

  // useEffect(() => {
  //   if (
  //     passwordValues.newPassword !== "" &&
  //     passwordValues.confirmNewPassword !== "" &&
  //     isPasswordMatch === "matched"
  //   ) {
  //     setIsFilled(true);
  //   } else {
  //     setIsFilled(false);
  //   }
  // }, [
  //   passwordValues.newPassword,
  //   passwordValues.confirmNewPassword,
  //   isPasswordMatch,
  // ]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const reg = /[0-9]/g;
    const test = reg.test(pass);
    if (test) {
      setIsAlphNum(true);
    } else {
      setIsAlphNum(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    if (pass.length < 8) {
      setIsMin(false);
    } else {
      setIsMin(true);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const uppercaseReg = /[A-Z]/g;
    const uppercaseTest = uppercaseReg.test(pass);
    if (uppercaseTest) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const lowercaseReg = /[a-z]/g;
    const lowercaseTest = lowercaseReg.test(pass);
    if (lowercaseTest) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const reg = /[!@#$%^&*(),.?":{}|<>]/g;
    const test = reg.test(pass);
    if (test) {
      setIsSpecialCharacter(true);
    } else {
      setIsSpecialCharacter(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    if (
      passwordValues.newPassword !== "" &&
      passwordValues.confirmNewPassword !== "" &&
      passwordValues.newPassword === passwordValues.confirmNewPassword
    ) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  }, [passwordValues.newPassword, passwordValues.confirmNewPassword]);

  useEffect(() => {
    if (
      isMin &&
      isAlphaNum &&
      isMatch &&
      isLowerCase &&
      isSpecialCharacter &&
      isUpperCase
    ) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [
    isMin,
    isAlphaNum,
    isMatch,
    isLowerCase,
    isSpecialCharacter,
    isUpperCase,
  ]);

  const togglePasswordVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const togglePasswordVisibilityOne = () => {
    setInputTypeOne(inputTypeOne === "password" ? "text" : "password");
  };

  return (
    <>
      <div>
        {!resetSuccessful ? (
          <div>
            <h1 className="title">Create new password</h1>
            <p className="paragraph">
              Your new password must be different from your previously used
              password.
            </p>
            <div className="form-group">
              <label
                htmlFor="password"
                // style={{
                //   color: isPasswordMatch === "noMatch" ? "#B11030" : "#1C223C",
                // }}
                className="label-form"
              >
                Create password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputTypeOne}
                  value={passwordValues.newPassword}
                  onChange={handleChange}
                  name="newPassword"
                  placeholder="Create secure password"
                  className="auth-input"
                />
                <button
                  onClick={togglePasswordVisibilityOne}
                  style={{
                    outline: "none",
                    background: "transparent",
                    position: "absolute",
                    right: "10px",
                    top: "15px",
                    width: "20px",
                    border: "none",
                  }}
                >
                  {inputTypeOne === "password" ? (
                    <img src={ClosedEye} alt="close" />
                  ) : (
                    <img src={OpenEye} alt="open" />
                  )}
                </button>
              </div>
            </div>
            <PasswordStrengthBar
              className="password__checker"
              password={passwordValues.newPassword}
            />
            {/* {passwordValues.newPassword.length > 0 && (
              <ul className="card-check color-black no-card--check">
                <li className="text-xlbold mb-2">
                  Your password must contain:
                </li>
                <li
                  className={`flex password__check--text ${
                    isMin ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isMin ? <Check /> : <Uncheck />}
                  </span>
                  8 characters minimum
                </li>
                <li
                  className={`flex password__check--text ${
                    isMin ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isUpperCase ? <Check /> : <Uncheck />}
                  </span>
                  A capital / uppercase letter
                </li>
                <li
                  className={`flex password__check--text ${
                    isMin ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isLowerCase ? <Check /> : <Uncheck />}
                  </span>
                  A lowercase letter
                </li>
                <li
                  className={`flex password__check--text ${
                    isAlphaNum ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isAlphaNum ? <Check /> : <Uncheck />}
                  </span>
                  One number
                </li>
                <li
                  className={`flex password__check--text ${
                    isMatch ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isMatch ? <Check /> : <Uncheck />}
                  </span>
                  Match with your confirm password
                </li>
                <li
                  className={`flex password__check--text ${
                    isAlphaNum ? "isTrue" : ""
                  }`}
                >
                  <span className="mr-2">
                    {isSpecialCharacter ? <Check /> : <Uncheck />}
                  </span>
                  A special character e.g, %, @, $
                </li>
              </ul>
            )} */}
            <div className="form-group">
              <label
                htmlFor="passwordTwo"
                // style={{
                //   color: isPasswordMatch === "noMatch" ? "#B11030" : "#1C223C",
                // }}
                className="label-form"
              >
                Confirm password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputType}
                  name="confirmNewPassword"
                  value={passwordValues.confirmNewPassword}
                  onChange={handleChange}
                  placeholder="Create secure password"
                  className="auth-input"
                />
                <button
                  onClick={togglePasswordVisibility}
                  style={{
                    outline: "none",
                    background: "transparent",
                    position: "absolute",
                    right: "10px",
                    top: "15px",
                    width: "20px",
                    border: "none",
                  }}
                >
                  {inputType === "password" ? (
                    <img src={ClosedEye} alt="close" />
                  ) : (
                    <img src={OpenEye} alt="open" />
                  )}
                </button>
              </div>
            </div>
            {isPasswordMatch === "noMatch" ? (
              <p
                className="color-negative text-sm text-normal"
                style={{ color: "#B11030" }}
              >
                Passwords do not match
              </p>
            ) : null}
            <br />
            <button
              type="submit"
              disabled={!isAuthorized}
              onClick={handleSubmit}
              className="submit-btn"
            >
              {loading ? (
                <span className="d-flex align-items-center justify-between">
                  <Loader dark={false} />
                </span>
              ) : (
                "Create new password"
              )}
            </button>
            <br />
            <br />
            <div className="text-center">
              <button
                className="external-link"
                onClick={() => history.push("/login")}
              >
                <img
                  src={back}
                  alt="back to login"
                  style={{ marginRight: "10px" }}
                />
                Back to sign in
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4 mt-4">
              <h1 className="title">Password reset</h1>
              <p className="paragraph">
                Your password has been reset successfully
              </p>
              <button
                type="submit"
                className="submit-btn"
                onClick={goToDashboard}
              >
                Go to login
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
