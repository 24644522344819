import React, { useEffect } from "react";
import { Button, message } from "antd";
import { formatBalance } from "../../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { createPayoutAction } from "../../../services/actions";

export default function Third({
  goBack,
  onClick,
  accountType,
  accountDetails,
  currencyDetails,
  profile,
}) {
  const { payout, loadingPayout } = useSelector(
    (state) => state.transactionsReducer
  );
  const dispatch = useDispatch();
  const { wallets } = useSelector((state) => state.walletsReducer);

  const createPayout = () => {
    if (accountType === "bank") {
      const data = {
        phoneNumber: accountDetails.phoneNumber,
        bankName: accountDetails.bankName,
        bankCode: accountDetails.bankCode,
        bankAccountName: accountDetails.bankAccountName,
        bankAccountNumber: accountDetails.bankAccountNumber,
        token: currencyDetails.token,
        country: accountDetails.country,
        firstName: profile.firstName,
        lastName: profile.lastName,
      };
      dispatch(createPayoutAction(data));
    } else {
      const data = {
        phoneNumber: accountDetails.phoneNumber,
        firstName: profile.firstName,
        lastName: profile.lastName,
        token: currencyDetails.token,
        country: accountDetails.country,
      };
      dispatch(createPayoutAction(data));
    }
  };

  useEffect(() => {
    if (payout) {
      message.success("Payout request created successfully");
      onClick(payout);
    }
  }, [payout, onClick]);

  const walletAmount = wallets.filter((wallet) => {
    return wallet.currency === currencyDetails.quotation.sourceCurrency;
  });

  return (
    <div>
      <>
        <h1 className="text-md color-bold text-bold mt-2">
          Review your payout details
        </h1>
        <br />
        <div className="d-flex">
          <p className="pay-summary-h1">Payout details</p>
          <p className="ml-auto">&nbsp;</p>
        </div>
        <br />
        <div className="d-flex">
          <p>You are sending</p>
          <p className="ml-auto">
            {currencyDetails.quotation.sourceCurrency}&nbsp;
            {formatBalance(currencyDetails.quotation.sourceAmount)}
          </p>
        </div>
        <div className="d-flex">
          <p>Total fees</p>
          <p className="ml-auto">
            {currencyDetails.quotation.sourceCurrency}&nbsp;
            {currencyDetails.quotation.totalFees}
          </p>
        </div>
        <div className="d-flex">
          <p>Amount we will convert</p>
          <p className="ml-auto">
            {currencyDetails.quotation.sourceCurrency}&nbsp;
            {formatBalance(currencyDetails.quotation.sourceAmount)}
          </p>
        </div>
        <div className="d-flex">
          <p>
            {profile.firstName}&nbsp; {profile.lastName} Gets
          </p>
          <p className="ml-auto">
            {currencyDetails.quotation.destinationCurrency}&nbsp;
            {formatBalance(currencyDetails.quotation.destinationAmount)}
          </p>
        </div>
        <div className="d-flex">
          <p>Currency balance after payout</p>
          <p className="ml-auto">
            {currencyDetails.quotation.sourceCurrency}&nbsp;
            {formatBalance(
              walletAmount[0].amount - currencyDetails.quotation.totalAmount
            )}
          </p>
        </div>
        <br />
        <div className="d-flex">
          <p className="pay-summary-h1">Receipient details</p>
          <p className="ml-auto">&nbsp;</p>
        </div>
        <div className="d-flex">
          <p>Name</p>
          <p className="ml-auto">
            {profile.firstName}&nbsp; {profile.lastName}
          </p>
        </div>
        <div className="d-flex">
          <p>Send to</p>
          <p className="ml-auto">
            {accountType.charAt(0).toUpperCase() + accountType.slice(1)}
          </p>
        </div>
        <div className="d-flex">
          <p>Phone number</p>
          <p className="ml-auto">{accountDetails.phoneNumber}</p>
        </div>
        {accountType === "bank" ? (
          <>
            <div className="d-flex">
              <p>Bank name</p>
              <p className="ml-auto">{accountDetails.bankName}</p>
            </div>
            <div className="d-flex">
              <p>Account number</p>
              <p className="ml-auto">{accountDetails.bankAccountNumber}</p>
            </div>
          </>
        ) : null}

        <br />
        {/* <div className="d-flex">
              <p>
                <img src={schedule} alt="" width="20px" />
                &nbsp; Schedule
              </p>
              <p className="ml-auto">
                <span>
                  <Switch size="medium" />
                </span>
              </p>
            </div> */}

        <hr />
        <br />
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4 align-self-center">
            <Button
              style={{ border: "none", background: "transparent" }}
              onClick={goBack}
            >
              Cancel and go back
            </Button>
          </div>
          <div className="col-md-4">
            <Button
              size="large"
              type="primary"
              className="pay-blue-btn"
              onClick={createPayout}
              loading={loadingPayout}
            >
              {loadingPayout ? "" : "Confirm and send"}
            </Button>
          </div>
        </div>
      </>
    </div>
  );
}
