import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  DatePicker,
  Radio,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

import { useSelector, useDispatch } from "../../../../utilities/hooks";
import { fetchTransactionSummaryAction } from "../../../../services/actions";
import {
  formatMoney,
  useWindowSize,
} from "../../../../utilities/helperFunctions";

import ChartLoader from "../../../../components/BarChartLoadr";
import Chart from "../../../../components/Chart";

import gears from "../../../../assets/images/filter-gears.svg";
import left from "../../../../assets/images/left_circle.svg";
import right from "../../../../assets/images/right_circle.svg";

export default function AnalyticsUI({ wallet }) {
  const { user } = useSelector((state) => state.profileReducer);
  const dispatch = useDispatch();
  const { transactionsSummary, transCall, change } = useSelector(
    (state) => state.analyticsReducer
  );
  const { wallets } = useSelector((state) => state.walletsReducer);

  const [selected, setSelected] = useState(null);
  const [graphType, setGraphType] = useState("daily");
  const [graphPeriod, setGraphPeriod] = useState("week");

  const [fromDateValueMoment, setFromDateValueMoment] = useState(
    moment().subtract(1, "week")
  );
  const [toDateValueMoment, setToDateValueMoment] = useState(moment());

  const [showFilterModal, setShowFilterModal] = useState(false);
  const windowWidth = useWindowSize().width;

  const graphTypeGroupByValues = {
    daily: "day",
    weekly: "week",
    monthly: "month",
    yearly: "year",
  };

  const graphPeriodValues = {
    today: "Today",
    week: "Last 7 days",
    month: "Last 30 days",
    year: "Last 1 year",
  };

  useEffect(() => {
    if (wallets && user && user.account && user.account.currency) {
      const selectedCurrency = _.find(wallets, (currencyItem) => {
        return currencyItem.currency === user.account.currency;
      });
      setSelected(selectedCurrency);
    }
  }, [wallets, user]);

  useEffect(
    () => {
      const dateFormat = "YYYY-MM-DD";
      if (!selected) {
        return false;
      }
      dispatch(
        fetchTransactionSummaryAction({
          params: {
            from: fromDateValueMoment
              ? fromDateValueMoment.format(dateFormat)
              : moment().format(dateFormat),
            to: toDateValueMoment
              ? toDateValueMoment.format(dateFormat)
              : moment().format(dateFormat),
            currency: selected?.currency,
            groupBy: graphTypeGroupByValues[graphType],
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fromDateValueMoment, toDateValueMoment, selected, graphType]
  );

  useEffect(() => {
    const now = moment();

    const dateLimits = {
      today: { from: moment().startOf("day"), to: now },
      week: { from: moment().subtract(1, "week").startOf("day"), to: now },
      month: { from: moment().subtract(1, "month").startOf("day"), to: now },
      year: { from: moment().subtract(1, "year").startOf("day"), to: now },
    };

    if (graphPeriod) {
      setFromDateValueMoment(dateLimits[graphPeriod].from);
      setToDateValueMoment(dateLimits[graphPeriod].to);
    }
  }, [graphPeriod]);

  const divisors = {
    1: "",
    1000: "K",
    1000000: "M",
    1000000000: "B",
  };

  const onGraphTypeChange = (e) => {
    setGraphType(e.target.value);
  };

  const onGraphPeriodChange = (e) => {
    setGraphPeriod(e.target.value);
  };

  // function handleDatePickerChange(date) {
  // 	setFromDateValueMoment(date);
  // }

  // function handleDatePickerChangeTo(date) {
  // 	setToDateValueMoment(date);
  // }

  const onFilterMenuClick = () => {
    setShowFilterModal(!showFilterModal);
  };

  const onManualPeriodChange = (dates) => {
    setGraphPeriod("");

    setFromDateValueMoment(dates[0]);
    setToDateValueMoment(dates[1]);
  };

  const onClearFilter = () => {
    setGraphType("daily");
    setGraphPeriod("week");

    setShowFilterModal(false);
  };

  const onFilterData = () => setShowFilterModal(false);

  const blues = {
    normal: "#BAD5F9",
    highlight: "#2F80ED",
  };

  const pinks = {
    normal: "#FECCE0",
    highlight: "#FD66A3",
  };

  let years = [],
    dates = [];

  const values = _.transform(
    transactionsSummary,
    (values, { date, collections, payouts }) => {
      values.push(collections);
      values.push(payouts);

      years.push(moment(date).format("YYYY"));
      dates.push(date);
    },
    []
  );

  years = _.uniq(years);

  const maxValue = _.max(values) || 0;
  // const minValue = _.min(values) || 0;

  let daysInBetween = 0;

  if (transactionsSummary.length > 1) {
    daysInBetween = moment(
      transactionsSummary[transactionsSummary.length - 1].date
    ).diff(transactionsSummary[0].date, "days");
  }

  const divisor = maxValue >= 1000000 ? 1000000 : maxValue >= 1000 ? 1000 : 1;

  const suffix = divisors[divisor];

  const state = {
    labels: transactionsSummary.map(function (item) {
      let dateFormats = {
        daily:
          daysInBetween === 0
            ? `MMM D${years.length > 1 ? ", 'YY" : ""}`
            : daysInBetween < 8
            ? "ddd"
            : `MMM D${years.length > 1 ? ", 'YY" : ""}`,
        weekly: `w${years.length > 1 ? ", 'YY" : ""}`,
        monthly: `MMM${years.length > 1 ? ", 'YY" : ""}`,
        yearly: "YYYY",
      };

      return `${
        graphType === "weekly" ? "Week " : ""
      }${moment(item.date).format(dateFormats[graphType])}`;
    }),
    datasets: [
      {
        label: "Collections      ",
        backgroundColor: blues.highlight,
        borderColor: blues.highlight,
        borderWidth: 1,
        hoverBackgroundColor: blues.highlight,
        hoverBorderColor: blues.highlight,
        data: transactionsSummary.map(function (item) {
          return item.collections / divisor;
        }),
        barPercentage: 1.0,
        dates,
        graphType,
      },

      {
        label: "Payouts",
        backgroundColor: pinks.highlight,
        borderColor: pinks.highlight,
        borderWidth: 1,
        hoverBackgroundColor: pinks.highlight,
        hoverBorderColor: pinks.highlight,
        data: transactionsSummary.map(function (item) {
          return item.payouts / divisor;
        }),
        barPercentage: 1.0,
        dates,
        graphType,
      },
    ],
  };

  const FilterMenu = (
    <Menu>
      <div className="dashboard-dropdown-container">
        <h3>Filter by</h3>
        <div className="dashboard-dropdown-body-container">
          <div className="dashboard-dropdown-section">
            <h4>Type</h4>
            <div>
              <Radio.Group
                value={graphType}
                onChange={onGraphTypeChange}
                className="dashboard-filter-type-options"
              >
                {_.map(_.keys(graphTypeGroupByValues), (entry) => (
                  <Radio.Button key={entry} value={entry}>
                    {_.startCase(entry)}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="dashboard-dropdown-section">
            <h4>Period</h4>
            <div>
              <ul>
                <li>
                  <Radio.Group
                    value={graphPeriod}
                    onChange={onGraphPeriodChange}
                    className="dashboard-filter-period-options"
                  >
                    {_.map(_.keys(graphPeriodValues), (key) => (
                      <Radio.Button key={key} value={key}>
                        {graphPeriodValues[key]}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </li>
                <li>
                  <h5>Custom</h5>
                  <DatePicker.RangePicker
                    value={[fromDateValueMoment, toDateValueMoment]}
                    placement="topLeft"
                    inputReadOnly={true}
                    onChange={onManualPeriodChange}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="dashboard-dropdown-footer-container">
          <Button onClick={onClearFilter}>Clear filter</Button>
          <Button type="primary" onClick={onFilterData}>
            Filter data
          </Button>
        </div>
      </div>
    </Menu>
  );

  const WalletMenu = (
    <Menu className="dashboard-dropdown-wallet-list">
      {_.sortBy(
        wallet.filter((data) => data.enabled),
        "currency"
      ).map((data, index) => {
        return (
          <Menu.Item
            key={_.get(data, "currency", "")}
            onClick={(event) =>
              setSelected(
                _.find(
                  wallet,
                  (entry) => _.get(entry, "currency", "") === event.key
                )
              )
            }
          >
            <span className="ant-avatar ant-avatar-circle ant-avatar-image avatar-normal">
              <img src={_.get(data, "icon", "")} alt="flag" />
            </span>
            <span>{data.currency}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const collectionsPercentChange = parseFloat(
    _.get(change, "collections.change.percentage", 0)
  );

  const payoutsPercentChange = parseFloat(
    _.get(change, "payouts.change.percentage", 0)
  );

  return (
    <Card className="dashboard-analytics-container">
      <div className="dashboard-analytics-header-container">
        <div>
          <h3>Analytics</h3>
        </div>
        <div className="filter-analytics">
          <Dropdown
            visible={showFilterModal}
            overlay={FilterMenu}
            overlayClassName={`filter-dropdown-menu ${
              windowWidth < 768 ? "mobile" : ""
            }`}
            placement="bottomRight"
            trigger="click"
          >
            <Button type="text" onClick={onFilterMenuClick}>
              <img src={gears} alt="" />
              <span>Filter by</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className="wallet-selector">
          <Dropdown
            overlay={WalletMenu}
            placement="bottomRight"
            trigger="click"
          >
            <Button type="text">
              <span className="ant-avatar ant-avatar-circle ant-avatar-image avatar-normal">
                <img src={_.get(selected, "icon", "")} alt="flag" />
              </span>
              <span>{_.get(selected, "currency", "")}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <Row>
        <Col xs={24} md={6} lg={24} xl={6} xxl={4}>
          <div className="dashboard-analytics-summary-container">
            <div>
              <img src={left} alt="" />
              <div>
                <h5>Collections</h5>
                <p>
                  {_.get(selected, "currency", "")}&nbsp;
                  {formatMoney(
                    _.get(change, "collections.currentAmount", 0) / divisor
                  )}
                  {suffix}
                </p>
                <span
                  className={`${
                    collectionsPercentChange > 0
                      ? "postive-percentage-change"
                      : collectionsPercentChange === 0
                      ? ""
                      : "negative-percentage-change"
                  }`}
                >
                  {collectionsPercentChange}%
                </span>
              </div>
            </div>
            <div>
              <img src={right} alt="" />
              <div>
                <h5>Payouts</h5>
                <p>
                  {_.get(selected, "currency", "")}
                  {formatMoney(
                    _.get(change, "payouts.currentAmount", 0) / divisor
                  )}
                  {suffix}
                </p>
                <span
                  className={`${
                    payoutsPercentChange > 0
                      ? "postive-percentage-change"
                      : payoutsPercentChange === 0
                      ? ""
                      : "negative-percentage-change"
                  }`}
                >
                  {payoutsPercentChange}%
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} md={18} lg={24} xl={18} xxl={20}>
          {transCall === 1 || maxValue === 0 ? (
            <ChartLoader />
          ) : (
            <Chart state={state} suffix={suffix} divisor={divisor} />
          )}
        </Col>
      </Row>
    </Card>
  );
}
