import makeAPICall from "../../../utilities/apiUtils";
import { verifyAccount, verifyAccountError } from "../../selectors";
import history from "../../../utilities/history";
import { message } from "antd";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_phase2_registration",
  },
};


const verifyAccountAction = (data) => (dispatch) => {
  dispatch(verifyAccount());

  return makeAPICall({
    path: "auth/verifyAccount",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      TagManager.dataLayer(tagManagerArgs);
      history.push("/success");
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(verifyAccountError(err.message));
    });
};

export default verifyAccountAction;
