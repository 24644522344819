import React, { useState } from "react";
import { Button, Input, Form, message} from "antd";

import StyledModal from "../styled-modal";
import { useSelector } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";

export default function WebHookModal({ show, onClose, onCallProfile }) {
  const { user: accountUser } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);

  const key = "changeWebhook";

  const onFinish = (values) => {
    if (values.webhook === accountUser.account.webhook) return;
    setIsLoading(true);
    message.loading({ content: "Loading...", key });
    return makeAPICall(
      {
        path: "/account/webhook",
        method: "PUT",
      },
      { body: JSON.stringify(values) }
    )
      .then((res) => {
        onCallProfile();
        onClose();
        setIsLoading(false);
        message.success({
          content: "New Webhook Url Generated",
          key,
          duration: 4,
        });
      })
      .catch((err) => {
        message.error({ content: err.message, key, duration: 4 });
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledModal centered show={show} footer={null} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-3 mt-5">
            <div>
              <div className="mt-4">
                <p className="text-bold color-bold text-md mb-1">
                  Change Webhook Url
                </p>
              </div>
              <br />
              <Form
                name="basic"
                initialValues={{ webhook: accountUser.account.webhook }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="webhook"
                  rules={[
                    {
                      required: true,
                      // pattern: `^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$`,
                      type: "url",
                      message: "Please input a valid URL!",
                    },
                  ]}
                >
                  <Input placeholder="Webhook URL" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    className="simple-block-btn text-bold"
                    size="large"
                    block
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
