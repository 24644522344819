import makeAPICall from "../../utilities/apiUtils";

import { fetchBanks, fetchBanksError, fetchBanksSuccess } from "../selectors";

const fetchBanksAction = () => (dispatch) => {
  dispatch(fetchBanks());
  return makeAPICall({
    path: "settings/banks",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchBanksSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchBanksError(err.message));
    });
};

export { fetchBanksAction };
