import ACTIONS from "../constants";

const initialState = {
  statement: null,
  errorMessage: null,
  loading: false,
  singleStatement: null,
  errorMessageSingle: null,
  loadingSingle: false,
};

const statementReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_STATEMENT:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_STATEMENT_SUCCESS:
      return {
        ...state,
        statement: payload,
        loading: false,
      };
    case ACTIONS.FETCH_STATEMENT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.FETCH_SINGLE_STATEMENT:
      return {
        ...state,
        errorMessageSingle: null,
        loadingSingle: true,
      };
    case ACTIONS.FETCH_SINGLE_STATEMENT_SUCCESS:
      return {
        ...state,
        singleStatement: payload,
        loadingSingle: false,
      };
    case ACTIONS.FETCH_SINGLE_STATEMENT_ERROR:
      return {
        ...state,
        errorMessageSingle: payload,
        loadingSingle: false,
      };
    case ACTIONS.DELETE_STATEMENT:
      return {
        ...state,
        statement: null,
        errorMessage: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default statementReducer;
