import React, { useState, useEffect } from "react";
import { Button, message, Spin, Dropdown, Menu } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  formatBalance,
  formatBalanceCrypto,
  excludedCurrencies,
  addMoneyCountries,
} from "../../utilities/helperFunctions";
import {
  deactivateWalletAction,
  fetchWalletsAction,
  reorderWalletAction,
  fetchBalanceSummaryAction,
  toggleAddMoneyModalAction,
  toggleSendMoneyModalAction,
} from "../../services/actions";
import viewOne from "../../assets/images/view1.png";
import viewTwo from "../../assets/images/view2.png";
import viewGrey from "../../assets/images/grey-grid.png";
import viewList from "../../assets/images/color-grid.png";
import regroup from "../../assets/images/regroup.svg";
import bankIcon from "../../assets/images/radio-buttons/bankDropdown.svg";
import mobileMoneyIcon from "../../assets/images/radio-buttons/mobileMoneyDropdown.svg";
import requestIcon from "../../assets/images/radio-buttons/requestMoney.svg";
import deleteNow from "../../assets/images/delete.svg";
import add from "../../assets/images/add-icon-new.svg";
import addList from "../../assets/images/list-add.svg";
import send from "../../assets/images/send.svg";
import accessIcon from "../../assets/images/noAccess.svg";
import AddWallet from "./addWallet";
import Loader from "../../components/loaders/loading";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";
import * as ctzc from "iso-country-currency";

const tagManagerArgsAdd = {
  dataLayer: {
    event: "b2b_add_money_btn",
  },
};

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

export default function WalletsContainer() {
  const { show, boot } = useIntercom();
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { roles } = useSelector((state) => state.RoleReducer);
  const { user: userInfo } = useSelector((state) => state.profileReducer);

  boot({
    name: userInfo?.account?.name,
    email: userInfo?.account?.email,
    user_id: userInfo?.account?.id,
    isB2B: "B2B",
  });

  const { accountSummary } = useSelector((state) => state.analyticsReducer);
  const { reorderWalletLoading, inactiveWalletLoading } = useSelector(
    (state) => state.activateDeactiveReducer
  );
  const dispatch = useDispatch();
  const [showAccSummary, setShowAccSummary] = useState(true);
  const [views, setViews] = useState(true);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [addView, setAddView] = useState(false);
  const [dragId, setDragId] = useState();
  const [hasKyc, setHasKyc] = useState(true);
  const [currentWallet, setCurrentWallet] = useState();
  const [isLoadingWallet, setIsLoadingWallet] = useState({
    loading: false,
    key: 0,
  });

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(userInfo?.role).createAny(value);
    return permission;
  }

  function toggleAccSummaryDisplay() {
    setShowAccSummary(!showAccSummary);
  }

  function handleOpenModal(walletDefault, item) {
    // TagManager.dataLayer(tagManagerArgsAdd);
    // dispatch(toggleAddMoneyModalAction(walletDefault));
    TagManager.dataLayer(tagManagerArgsAdd);
    dispatch(toggleAddMoneyModalAction(walletDefault, item));
  }

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  useEffect(() => {
    if (userInfo.account && userInfo.account.isVerified === false) {
      setHasKyc(false);
    } else {
      dispatch(fetchBalanceSummaryAction());
      dispatch(fetchWalletsAction());
      setHasKyc(true);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (wallets) {
      setCurrentWallet(wallets);
    }
  }, [wallets]);

  const handleDrag = (ev) => {
    // console.log(ev.currentTarget.id);
    setDragId(ev.currentTarget.id);
  };

  //function to handle drag and drop
  const handleDrop = (ev) => {
    const newWallet = JSON.parse(JSON.stringify(currentWallet));
    // console.log(newWallet, 'testWallet')
    const dragBox = newWallet.find((wallet) => wallet.currency === dragId);
    const dropBox = newWallet.find(
      (wallet) => wallet.currency === ev.currentTarget.id
    );

    const dragBoxOrder = dragBox.position;
    const dropBoxOrder = dropBox.position;

    const newBoxState = newWallet.map((wallet) => {
      if (wallet.currency === dragId) {
        wallet.position = dropBoxOrder;
      }
      if (wallet.currency === ev.currentTarget.id) {
        wallet.position = dragBoxOrder;
      }
      return wallet;
    });

    const enabledWallets = newBoxState.filter((wallet) => {
      return wallet.enabled === true;
    });
    const returnWallet = enabledWallets.map((datum) => {
      return {
        currency: datum.currency,
        position: datum.position,
      };
    });

    setCurrentWallet(enabledWallets);

    const data = {
      wallets: returnWallet,
    };

    // console.log(data, 'data to reorder')
    dispatch(reorderWalletAction(data));
  };

  const goBack = () => {
    setViews(true);
    // setSingleView(false);
    setAddView(false);
  };

  const showGrid = () => {
    setGridView(true);
    setListView(false);
  };

  const showList = () => {
    setGridView(false);
    setListView(true);
  };

  const gridColor = gridView === true ? viewOne : viewGrey;
  const listColor = listView === true ? viewList : viewTwo;

  const enabledWallets = currentWallet?.filter((wallet) => {
    return wallet.enabled === true;
  });

  const addWalletView = addView ? (
    <AddWallet
      goBack={goBack}
      user={userInfo}
      enabled={enabledWallets}
      wallets={wallets}
    />
  ) : null;

  const viewAddWallet = () => {
    setViews(false);
    setAddView(true);
  };

  const deactivateWallet = (wallet, position) => {
    setIsLoadingWallet({ loading: true, key: position });
    dispatch(deactivateWalletAction({ currency: wallet.currency }));
  };

  const userMenu = (item) => hasAvailableOption(item);

  function hasAvailableOption(item) {
    // const countryCode = ctzc
    //   .getAllISOByCurrencyOrSymbol("currency", item?.currency);
    let country;
    if (item.currency === "XAF") {
      country = addMoneyCountries.find((c) => c?.country === "CM");
    } else if (item.currency === "XOF") {
      country = addMoneyCountries.find((c) => c?.country === "CI");
    } else {
      const countryCode = ctzc
        .getAllISOByCurrencyOrSymbol("currency", item?.currency)
        .toString();
      country = addMoneyCountries.find((c) => c?.country === countryCode);
    }

    //   const countryCode = ctzc
    //       .getAllISOByCurrencyOrSymbol("currency", item?.currency)
    //       .toString();
    //  const country = addMoneyCountries.find((c) => c?.country === countryCode);

    if (country) {
      const availableOptions = country?.addMoney;
      return (
        <Menu className="header-dropdown-user-container-action-buttons">
          {availableOptions?.map((option, index) => (
            <Menu.Item
              key={index}
              className="d-flex align-items-center mb-3"
              onClick={() => handleOpenModal(item, option)}
            >
              <img
                src={
                  option === "bank"
                    ? bankIcon
                    : option === "momo"
                    ? mobileMoneyIcon
                    : requestIcon
                }
                alt="bank"
              />
              <p className="ml-3">
                {option === "bank"
                  ? "Bank"
                  : option === "momo"
                  ? "Mobile money"
                  : "Eversend"}
              </p>
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return false; // Country not found in the array
  }

  return (
    <React.Fragment>
      <div>
        <h1 className="text-md color-bold text-bold mt-2">Wallets</h1>
      </div>

      {hasKyc ? (
        <React.Fragment>
          {reorderWalletLoading ? (
            <React.Fragment>
              <Loader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {views === true ? (
                <React.Fragment>
                  <section className="wallet-new-design">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <h1>All accounts value in UGX</h1>
                        <p>
                          {accountSummary?.currency}&nbsp;
                          {!showAccSummary
                            ? formatBalance(accountSummary?.amount)
                            : "*********"}
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="row">
                      <div className="col-md-8 align-self-center">
                        <div className="d-flex">
                          <p className="left-par">
                            {enabledWallets?.length} active wallets
                          </p>
                          <p className="left-par ml-auto">
                            <img
                              src={gridColor}
                              style={{ cursor: "pointer" }}
                              onClick={showGrid}
                              alt=""
                            />
                            &nbsp;
                            <img
                              src={listColor}
                              style={{ cursor: "pointer" }}
                              onClick={showList}
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {showAccSummary ? (
                          <button
                            type="button"
                            onClick={toggleAccSummaryDisplay}
                            className="wallet-hide-bal"
                          >
                            <span>
                              <EyeInvisibleOutlined />
                            </span>
                            Show Balance
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={toggleAccSummaryDisplay}
                            className="wallet-hide-bal"
                          >
                            <span>
                              <EyeOutlined />
                            </span>
                            Hide Balance
                          </button>
                        )}

                        <Button
                          className="btn-primary"
                          size="large"
                          type="primary"
                          onClick={viewAddWallet}
                        >
                          Add new wallet
                        </Button>
                      </div>
                    </div>
                  </section>

                  <br />
                  {gridView && (
                    <section className="current-wallet-active">
                      <div className="flex-row-container">
                        {currentWallet
                          ?.sort((a, b) => a.position - b.position)
                          ?.map((wallet, index) =>
                            wallet.enabled === true ? (
                              <div
                                className="flex-row-item hoverFirst"
                                key={wallet.currency}
                                draggable={true}
                                id={wallet.currency}
                                onDragOver={(ev) => ev.preventDefault()}
                                onDragStart={handleDrag}
                                onDrop={handleDrop}
                              >
                                <div className="wallet-card">
                                  <div className="more-wallet-option">
                                    <div className="d-flex">
                                      <div draggable={true}>
                                        <div className="deleteTooltip">
                                          <span>
                                            <img src={regroup} alt="regroup" />
                                          </span>
                                          <span className="tooltip-ex-text">
                                            <span>Drag</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="ml-auto">
                                        {wallet.currency !==
                                        accountSummary?.currency ? (
                                          <div className="deleteTooltip">
                                            {checkAccess("wallets").granted ? (
                                              <span
                                                onClick={() =>
                                                  deactivateWallet(
                                                    wallet,
                                                    index
                                                  )
                                                }
                                              >
                                                {inactiveWalletLoading &&
                                                isLoadingWallet.loading &&
                                                isLoadingWallet.key ===
                                                  index ? (
                                                  <Spin size="small" />
                                                ) : (
                                                  <React.Fragment>
                                                    <img
                                                      src={deleteNow}
                                                      alt="Deactivate wallet"
                                                    />
                                                  </React.Fragment>
                                                )}
                                              </span>
                                            ) : null}

                                            <span className="tooltip-ex-text">
                                              <span>Delete Wallet</span>
                                            </span>
                                          </div>
                                        ) : null}
                                        <div className="deleteTooltip">
                                          {checkAccess("payouts").granted ? (
                                            <span
                                              onClick={handleOpenPayoutModal}
                                            >
                                              <img src={send} alt="regroup" />
                                            </span>
                                          ) : null}

                                          <span className="tooltip-ex-text">
                                            <span>Send Money</span>
                                          </span>
                                        </div>

                                        <div className="deleteTooltip">
                                          {excludedCurrencies.includes(
                                            wallet.currency
                                          ) ? (
                                            checkAccess("collections")
                                              .granted ? (
                                              <span
                                                onClick={() =>
                                                  message.error(
                                                    "You can not add money to this wallet"
                                                  )
                                                }
                                              >
                                                <img src={add} alt="regroup" />
                                              </span>
                                            ) : null
                                          ) : checkAccess("collections")
                                              .granted ? (
                                            wallet.isCrypto ? (
                                              <span
                                                onClick={() =>
                                                  handleOpenModal(
                                                    wallet,
                                                    "crypto"
                                                  )
                                                }
                                              >
                                                <img src={add} alt="regroup" />
                                              </span>
                                            ) : (
                                              <Dropdown
                                                overlay={userMenu(wallet)}
                                                trigger={["click"]}
                                                placement="bottomRight"
                                              >
                                                <span>
                                                  <img
                                                    src={add}
                                                    alt="regroup"
                                                  />
                                                </span>
                                              </Dropdown>
                                            )
                                          ) : null}
                                          <span className="tooltip-ex-text">
                                            <span>Fund wallet</span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pad-Wallet">
                                    <Link
                                      to={{
                                        pathname: `/wallets/currency/${wallet.currency}`,
                                        // state: `${JSON.stringify({ ...wallet })}`,
                                      }}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={wallet.icon}
                                          alt=""
                                          className="align-self-center curImage"
                                        />

                                        <p className="">
                                          <span>{wallet.name}</span>
                                          <br />
                                          <br />
                                          {wallet.currency}&nbsp;
                                          {!showAccSummary
                                            ? wallet.isStableCoin === false &&
                                              wallet.isCrypto
                                              ? formatBalanceCrypto(
                                                  wallet.amount
                                                )
                                              : formatBalance(wallet.amount)
                                            : "*********"}
                                        </p>
                                        <div className="ml-auto align-self-center">
                                          <i className="fa fa-angle-right showNow"></i>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>
                    </section>
                  )}
                  {listView && (
                    <React.Fragment>
                      {currentWallet
                        ?.sort((a, b) => a.position - b.position)
                        ?.map((wallet, index) =>
                          wallet.enabled === true ? (
                            <div className="list-view" key={index}>
                              <div className="row">
                                <div className="col-md-4">
                                  <Link
                                    to={{
                                      pathname: `/wallets/currency/${wallet.currency}`,
                                      state: `${JSON.stringify({ ...wallet })}`,
                                    }}
                                  >
                                    <div className="d-flex list-view-image">
                                      <img
                                        src={wallet.icon}
                                        alt=""
                                        className="align-self-center"
                                      />
                                      <p>
                                        <span>{wallet.name}</span>
                                        <br />
                                        <br />
                                        {wallet.currency}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-md-4 align-self-center">
                                  <Link
                                    to={{
                                      pathname: `/wallets/currency/${wallet.currency}`,
                                      state: `${JSON.stringify({ ...wallet })}`,
                                    }}
                                  >
                                    <p>
                                      {!showAccSummary
                                        ? formatBalance(wallet.amount)
                                        : "*********"}
                                    </p>
                                  </Link>
                                </div>

                                <div className="col-md-4 align-self-center ml-auto">
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      {excludedCurrencies.includes(
                                        wallet.currency
                                      ) ? (
                                        checkAccess("collections").granted ? (
                                          <span
                                            className="inline-list-items"
                                            onClick={() =>
                                              message.error(
                                                "You can not add money to this wallet"
                                              )
                                            }
                                          >
                                            <span>
                                              <img src={addList} alt="" />
                                            </span>
                                            &nbsp;
                                            <span className="p-tag">
                                              Fund wallet
                                            </span>
                                          </span>
                                        ) : null
                                      ) : checkAccess("collections").granted ? (
                                        wallet.isCrypto ? (
                                          <span
                                            className="inline-list-items"
                                            onClick={() =>
                                              handleOpenModal(wallet, "crypto")
                                            }
                                          >
                                            <span>
                                              <img src={addList} alt="" />
                                            </span>
                                            &nbsp;
                                            <span className="p-tag">
                                              Fund wallet
                                            </span>
                                          </span>
                                        ) : (
                                          <Dropdown
                                            overlay={userMenu(wallet)}
                                            trigger={["click"]}
                                            placement="bottomRight"
                                          >
                                            <span className="inline-list-items">
                                              <span>
                                                <img src={addList} alt="" />
                                              </span>
                                              &nbsp;
                                              <span className="p-tag">
                                                Fund wallet
                                              </span>
                                            </span>
                                          </Dropdown>
                                        )
                                      ) : null}
                                      &nbsp;
                                      {checkAccess("payouts").granted ? (
                                        <span className="inline-list-items">
                                          <span>
                                            <img src={send} alt="regroup" />
                                          </span>
                                          &nbsp;
                                          <span>
                                            <span className="p-tag">
                                              Send Money
                                            </span>
                                          </span>
                                        </span>
                                      ) : null}
                                      <div className="hide-show-list">
                                        <span className="inline-list-items">
                                          {wallet.currency !==
                                          accountSummary?.currency ? (
                                            checkAccess("wallets").granted ? (
                                              <span className="deleteTooltip">
                                                <span
                                                  onClick={() =>
                                                    deactivateWallet(wallet)
                                                  }
                                                >
                                                  <img
                                                    src={deleteNow}
                                                    alt="regroup"
                                                  />
                                                </span>
                                                <span className="tooltip-ex-text">
                                                  <span>Delete</span>
                                                </span>
                                              </span>
                                            ) : null
                                          ) : null}
                                        </span>
                                        <span className="inline-list-items">
                                          <span className="deleteTooltip">
                                            <span>
                                              <img
                                                src={regroup}
                                                alt="regroup"
                                              />
                                            </span>
                                            <span className="tooltip-ex-text">
                                              <span>Drag</span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : null}
              {/* {singleWalletView} */}
              {addWalletView}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
