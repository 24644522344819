import React from "react";
import { Button, Modal } from "antd";
// import Loader from "../../../components/Loader";

export default function DeleteCard({
  isModalOpen,
  handleCancel,
  handleOk,
  loading,
}) {
  return (
    <div className="auth-modal-auto">
      <Modal
        title="Delete card"
        visible={isModalOpen}
        centered
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={handleCancel}
            disable={loading}
            style={{
              background: "#F0F0F0",
              borderRadius: "4px",
              border: "none",
              color: "#5A50FE",
              width: "30%",
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleOk}
            disable={loading}
            style={{
              background: "#5A50FE",
              borderRadius: "4px",
              width: "30%",
            }}
            loading={loading}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this card?</p>
      </Modal>
    </div>
  );
}
