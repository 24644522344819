import makeAPICall from "../../utilities/apiUtils";
import {
  fetchAnalyticsBalance,
  fetchAnalyticsBalanceError,
  fetchAnalyticsBalanceSuccess,
  fetchAnalyticsTransactions,
  fetchAnalyticsTransactionsError,
  fetchAnalyticsTransactionsSuccess,
} from "../selectors";

const fetchBalanceSummaryAction = () => (dispatch) => {
  dispatch(fetchAnalyticsBalance());
  return makeAPICall({
    path: "analytics/balance",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchAnalyticsBalanceSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchAnalyticsBalanceError(err.message));
    });
};

const fetchTransactionSummaryAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchAnalyticsTransactions());
    return makeAPICall({
      path: "analytics/transactions",
      method: "GET",
      params,
    })
      .then((res) => {
        const { transactions, change } = res.data;
        dispatch(fetchAnalyticsTransactionsSuccess(transactions, change));
      })
      .catch((err) => {
        dispatch(fetchAnalyticsTransactionsError(err.message));
      });
  };

export { fetchBalanceSummaryAction, fetchTransactionSummaryAction };
