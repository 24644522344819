import ACTIONS from '../../constants';

const initialState = {
  user: null,
  errorMessage: null,
  loading: false,
};

const adminProfile = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.ADMIN_PROFILE:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ACTIONS.ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.ADMIN_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default: return state;
  }
};

export default adminProfile;
