import React, { useState } from "react";
import { Tabs, Steps } from "antd";
import { useSelector } from "../../../utilities/hooks";
import AddSingleBeneficiary from "./AddSingleBeneficiary";
import AddBulkBeneficiaries from "./AddBulkBeneficiaries";
import UploadBulkBeneficiary from "./UploadBulkBeneficiary";
import StyledModal from "../styled-modal";

const { Step } = Steps;

export default function AddBeneficiaryModal({
  show,
  onClose,
  addBeneficiaries,
  page = 0,
}) {
  const { TabPane } = Tabs;

  const [showUploadView, setShowUploadView] = useState(page);
  const [isSingle, setIsSingle] = useState(true);

  const { banks } = useSelector((state) => state.BanksReducer);

  function onChangeViewShow() {
    setShowUploadView(1);
  }

  function handleTabClick(keys, event) {
    switch (keys) {
      case "1":
        setIsSingle(true);
        break;
      case "2":
        setIsSingle(false);
        break;
      default:
        setIsSingle(true);
        break;
    }
  }

  function onChangeViewHide() {
    setShowUploadView(0);
  }

  function onFileUploaded(data) {
    onClose();
  }

  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 pt-5">
            {!isSingle ? (
              <div className="beneficiary-progress-test">
                <Steps
                  current={showUploadView}
                  direction="vertical"
                  size="small"
                >
                  <Step title="Download XLSX" />
                  <Step title="Upload XLSX" />
                  <Step title="Review" />
                  <Step title="Success" />
                </Steps>
              </div>
            ) : null}
          </div>
          <div className="col-sm-4 beneficiary-progress">
            {showUploadView === 0 ? (
              <div>
                <Tabs
                  className="e__tabs mt-5"
                  defaultActiveKey={1}
                  onTabClick={handleTabClick}
                >
                  <TabPane tab="One beneficiary" key="1">
                    <AddSingleBeneficiary
                      banks={banks}
                      addBeneficiary={addBeneficiaries}
                    />
                  </TabPane>
                  <TabPane tab="Group" key="2">
                    <AddBulkBeneficiaries onChangeViewShow={onChangeViewShow} />
                  </TabPane>
                </Tabs>
              </div>
            ) : (
              <>
                <UploadBulkBeneficiary
                  onChangeViewHide={onChangeViewHide}
                  onFileUploaded={onFileUploaded}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
