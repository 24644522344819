import config from "../configs";
import { logout } from "./authUtils";
// import history from "./history";

export const AUTH_TOKEN = "__API_TOKEN__";

export const getAuthToken = () => window.localStorage.getItem(AUTH_TOKEN);

// Define the global AbortController
const globalController = new AbortController();

const networkError = "Failed to fetch";

const makeAPICall = async (
  {
    path,
    method = "POST",
    payload = null,
    params = null,
    useCustomController = false,
  },
  customConfigs,
  currentController // Pass the controller as an argument
) => {
  const token = getAuthToken();

  const headers = {
    Accept: "application/json, */*",
    "Content-type": "application/json",
  };

  if (token) headers.Authorization = `Bearer ${token}`;

  const configs = {
    method,
    headers,
    signal: useCustomController
      ? useCustomController.signal
      : globalController.signal, // Use custom controller or global controller signal
    ...customConfigs,
  };

  if (payload) configs.body = JSON.stringify(payload);

  let url = new window.URL(`${config.BACKEND_URL}/${path}`);

  const buildParams = (data) => {
    const params = new window.URLSearchParams();

    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else {
        params.append(key, value);
      }
    }

    return params;
  };

  if (params) url.search = buildParams(params);

  return window
    .fetch(url, configs)
    .then(async (response) => {
      const data = await response.json();

      if (!response.ok) {
        let errorMessage;

        if (data && data.message) {
          if (typeof data.message === "string") {
            errorMessage = data.message;
          } else if (Array.isArray(data.message)) {
            errorMessage = data.message
              .map(({ msg, param }) => `${param}: ${msg}`)
              .join(", ");
          }
        } else {
          errorMessage = "An unknown error occurred!";
        }

        let error = new Error(errorMessage);
        console.log(error, errorMessage);
        if (
          data.code === 401 &&
          (error === "jwt expired" ||
            error === "Invalid session. Please login to continue." ||
            errorMessage === "Invalid session. Please login to continue." ||
            error === "Token is not provided!")
        ) {
          //destroy client auth details and log user out
          logout();
          // window.location.reload(true);
        } else {
          error.status = response.status || 500;
        }

        return Promise.reject(error);
        // throw error;
      }
      return data;
    })
    .catch((error) => {
      if (error instanceof SyntaxError) {
        error.message = "Something went wrong";
      } else {
        // error.message = "Something went wrong"
        error.message =
          error?.message === networkError
            ? "Something went wrong, please try again later."
            : error.message;
      }
      throw error;
    });
};

export default makeAPICall;
