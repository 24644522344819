import makeAPICall from "../../../utilities/apiUtils";
import {
  fetchProfile,
  fetchProfileError,
  fetchProfileSuccess,
  changeSecret,
} from "../../selectors";

const fetchProfileAction = () => (dispatch) => {
  dispatch(fetchProfile());
  return makeAPICall({
    path: "profile",
    method: "GET",
  })
    .then((res) => {
      const { user } = res.data;
      dispatch(fetchProfileSuccess(user));
    })
    .catch((err) => {
      dispatch(fetchProfileError(err.message));
    });
  // const { user } = data;
  // dispatch(fetchProfileSuccess(user));
};

const changeSecretAction = (payload) => (dispatch) => {
  dispatch(changeSecret(payload));
};

export { fetchProfileAction, changeSecretAction };
