import ACTIONS from '../../constants';

const initialState = {
  user: null,
  errorMessage: null,
  loading: false,
};

const businessKyb = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.BUSINESS_KYB:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.BUSINESS_KYB_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.BUSINESS_KYB_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default: return state;
  }
};

export default businessKyb;
