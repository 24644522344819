import React, { useState } from "react";
import { useSelector } from "../../../utilities/hooks";
import { InputNumber, message } from "antd";
import { ReactComponent as SettingIcon } from "../../../assets/images/setting-card-icon.svg";
import { ReactComponent as FeeIcon } from "../../../assets/images/fees-card.svg";
import Loader from "../../../components/Loader";

import makeAPICall from "../../../utilities/apiUtils";
import { formatMoney } from "../../../utilities/helperFunctions";

export default function Third({ next, card, cardDetails }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const minimumFunding = 1;
  const creationFee = 1;

  let USDWallet = wallets.find((wallet) => wallet.currency === "USD");
  // console.log(card, "tetst");
  // useEffect(() => {
  //   console.log(card);
  // }, [card]);

  const onChange = (value) => {
    setAmount(value);
  };

  const handleSubmit = () => {
    const totalCost = amount + creationFee;
    if (USDWallet.amount >= totalCost) {
      const data = {
        title: card.title,
        userId: card.userId.toString(),
        color: card.colors ? card.colors : "blue",
        amount: amount,
        brand: card.brand, //visa or mastercard
        currency: "USD",
      };
      setLoading(true);
      return makeAPICall({
        path: "cards",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          cardDetails({ newCard: res.data });
          setLoading(false);
          next();
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message, 5);
        });
    } else if (USDWallet.amount >= minimumFunding) {
      message.error("Insufficient funds", 5);
    } else {
      message.error("Insufficient funds", 5);
    }
  };

  return (
    <div>
      <div className="banktransfer-others">
        <div style={{ position: "relative" }}>
          <div className="custom-select-card">
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="d-flex align-items-center p-2"
                style={{ backgroundColor: "#F8F9FA", borderRadius: "40px" }}
              >
                <img
                  src={USDWallet.icon}
                  alt=""
                  className="rounded-circle"
                  width="24px"
                  height="24px"
                />
                <p className="ml-2">USD</p>
              </div>

              <p>Fund card</p>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-2">
              <p>Balance: USD {formatMoney(USDWallet.amount)}</p>
              <div style={{ textAlign: "right" }}>
                <InputNumber
                  value={amount}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={onChange}
                  bordered={false}
                />
              </div>
            </div>
          </div>
          <div className="fees-card-area mt-4">
            <div className="d-flex just align-items-center">
              <FeeIcon />
              <p>Minimum fund wallet amount = USD 1</p>
            </div>
            <div className="d-flex align-items-center mt-2">
              <SettingIcon />
              <p>Monthly maintenance fee = USD 1 (reoccurring)</p>
            </div>
          </div>
          {/* <div className="d-flex just align-items-center">
              <p>
                NB: When creating a card, we charge USD 1, it's factored in the
                amount of money you currently have in your USD wallet. It means
                if you have USD 10 in your wallet and input USD 2 as fund
                amount, we will charge USD 1 and you will have USD 7 remaining
                in your USD wallet and USD 2 on your card.
              </p>
            </div> */}
        </div>
        <button
          className="btn-btn-btn mt-4"
          style={{ cursor: "pointer" }}
          disabled={!amount || loading}
          onClick={handleSubmit}
        >
          {loading ? (
            <span className="d-flex align-items-center justify-between">
              <Loader dark={false} />
            </span>
          ) : (
            "Create card"
          )}
        </button>
      </div>
    </div>
  );
}
