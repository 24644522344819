import makeAPICall from "../../utilities/apiUtils";

import {
  fetchCryptoChain,
  fetchCryptoChainError,
  fetchCryptoChainSuccess,
  deleteFetchCryptoChain,
} from "../selectors";

const fetchCryptoChainAction = (wallet) => (dispatch) => {
  dispatch(fetchCryptoChain());
  return makeAPICall({
    path: `transactions/collections/cryptoChains/${wallet}`,
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchCryptoChainSuccess(res.data.chains));
    })
    .catch((err) => {
      dispatch(fetchCryptoChainError(err.message));
    });
};

const deleteFetchCryptoChainAction = () => (dispatch) => {
  dispatch(deleteFetchCryptoChain());
};

export { fetchCryptoChainAction, deleteFetchCryptoChainAction };
