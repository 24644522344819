import React from "react";

// Tabs Component
const Tabs = ({ children, activeTabIndex, onTabClick }) => {
  return (
    <div>
      <div className="custom-tabs">
        {children.map((child, index) => (
          <button
            key={index}
            className={`tabs ${index === activeTabIndex ? "active" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {child.props.icon && <img src={child.props.icon} alt="" />}
            {child.props.title}
          </button>
        ))}
      </div>
      <div className="custom-tab-content">{children[activeTabIndex]}</div>
    </div>
  );
};

export default Tabs;
