import React from "react";
import styled from "styled-components";
import close from "../../assets/images/Close.svg";

const ModalTitle = styled.h1`
  margin-bottom: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #040052;
`;

const WelcomeModal = ({ title, children, show, onClose }) => {
  return (
    <div>
      {show ? (
        <div className="welcome-modal-overlay">
          <div className="welcome-modal-parent">
            <div className="welcome-modal-close" onClick={onClose}>
              <img src={close} alt="close modal" />
            </div>
            <ModalTitle>Welcome {title}</ModalTitle>
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WelcomeModal;
