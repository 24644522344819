import React, { useState } from "react";
import countries from "../../../utilities/countries.json";
import CountryPhoneInput from "../../../components/CountryPhoneInput";
import { useSelector } from "../../../utilities/hooks";

export default function FirstForm({ goToNextStep, title, inputDetails }) {
  const { sourceCountries } = useSelector((state) => state.CountryReducer);
  const countriesArray = sourceCountries?.map((item) => item.code);
  const selectedCountries = countries?.filter((country) =>
    countriesArray?.includes(country.code)
  );
  // console.log(selectedCountries, 'text')
  const defaultCountry = countries.find((country) => country.code === "NG");
  const [inputValues, setInputValues] = useState({
    firstName: inputDetails?.firstName ? inputDetails?.firstName : "",
    lastName: inputDetails?.lastName ? inputDetails?.lastName : "",
    email: inputDetails?.email ? inputDetails?.email : "",
    phone: inputDetails?.phone ? inputDetails?.phone : "",
    localPhone: inputDetails?.localPhone ? inputDetails?.localPhone : "",
    dialCode: inputDetails?.dialCode ? inputDetails?.dialCode : "",
    country: inputDetails?.country ? inputDetails?.country : "",
    haveBusiness: inputDetails?.haveBusiness ? inputDetails?.haveBusiness : "",
  });

  // console.log(inputValues, 'hshhs')

  const handleChange = (e) => {
    setInputValues({
      ...inputValues,
      haveBusiness: e.target.value,
    });
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const allDetails = (details) => {
    // Remove the leading zero if present
    const adjustedValue = details?.phoneNumber.startsWith("0")
      ? details?.phoneNumber.slice(1)
      : details?.phoneNumber;
    setInputValues({
      ...inputValues,
      phone: `${details?.selectedCountry?.dial_code}${adjustedValue}`,
      localPhone: adjustedValue,
      dialCode: details?.selectedCountry?.dial_code,
      country: details?.selectedCountry?.code,
    });
  };

  return (
    <div>
      {/* <h1 style={{ fontSize: "16px", textAlign: "center" }}>
          Sorry, our systems are down at the moment due to maintenance work.
        </h1> */}
      <h2>{title}</h2>
      <div className="form-group">
        <label className="label-form">Full name</label>
        <div className="input-row">
          <input
            type="text"
            name="firstName"
            value={inputValues.firstName}
            onChange={handleChangeInput}
            placeholder="First"
          />
          <input
            type="text"
            name="lastName"
            value={inputValues.lastName}
            onChange={handleChangeInput}
            placeholder="Last"
          />
        </div>
      </div>
      <div className="form-group">
        <label className="label-form">Company email</label>
        <input
          type="email"
          name="email"
          value={inputValues.email}
          onChange={handleChangeInput}
          placeholder="Company@mail.co "
          className="reg-input"
        />
      </div>
      <div className="form-group">
        <label className="label-form">Phone number</label>
        <CountryPhoneInput
          countries={selectedCountries}
          defaultCountry={defaultCountry}
          allDetails={allDetails}
          phoneNumber={inputValues.localPhone}
        />
      </div>
      <div className="form-group">
        <label className="label-form">
          What type of account do you want to create?
        </label>
        <div className="custom-radio-group">
          <label className="custom-radio">
            <input
              type="radio"
              id="radio-input-1"
              name="radio-group"
              value="no"
              onChange={handleChange}
              checked={inputValues.haveBusiness === "no"}
            />
            <span className="checkmark"></span>
            Personal account
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              id="radio-input-2"
              name="radio-group"
              value="yes"
              onChange={handleChange}
              checked={inputValues.haveBusiness === "yes"}
            />
            <span className="checkmark"></span>
            Business account
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={
          !(
            inputValues.firstName.trim() &&
            inputValues.lastName.trim() &&
            inputValues.email.trim() &&
            inputValues.phone &&
            inputValues.haveBusiness
          )
        }
        // disabled={true}
        onClick={() => goToNextStep(inputValues)}
        className="submit-reg"
      >
        Continue
      </button>
    </div>
  );
}
