import ACTIONS from '../constants';

const { SET_GLOBAL_SEARCH_QUERY, PERFORM_GLOBAL_SEARCH_SUCCESS } = ACTIONS;

const initialState = {
	query: '',
	results: {}
};

const searchReducer = (state = initialState, action) => {
	const { payload } = action;

	switch (action.type) {
		case SET_GLOBAL_SEARCH_QUERY:
			return { ...state, query: payload };

		case PERFORM_GLOBAL_SEARCH_SUCCESS:
			return { ...state, results: payload };

		default:
			return state;
	}
};

export default searchReducer;