import ACTIONS from "../constants";

const initialState = {
  activities: null,
  activityError: null,
  loading: false,
};

const activityLogReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_LOGS:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.FETCH_LOGS_SUCCESS:
      return {
        ...state,
        activities: payload,
        loading: false,
      };
    case ACTIONS.FETCH_LOGS_ERROR:
      return {
        ...state,
        activityError: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default activityLogReducer;
