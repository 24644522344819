import React, { useState, useEffect, useRef } from "react";
import CountryFlag from "react-country-flag";
import downArrow from "../../assets/images/Chevron_down.svg";
import upArrow from "../../assets/images/Chevron_up.svg";
import "./country.css";

const CountryPhoneInput = ({
  countries = null,
  defaultCountry,
  allDetails,
  phoneNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    defaultCountry || countries[0]
  );
  const dropdownRef = useRef();
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(countries);

  useEffect(() => {
    if (defaultCountry) {
      setSelectedCountry(defaultCountry);
    }
  }, [defaultCountry]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };

  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const phoneDetails = {
      phoneNumber: numericValue,
      selectedCountry: selectedCountry,
    };
    allDetails(phoneDetails);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    if (searchTerm === "") {
      setFilteredItems(countries);
    } else {
      const newFilteredItems = countries.filter((item) =>
        item.name.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
      setFilteredItems(newFilteredItems);
    }
  };

  return (
    <div className="country-phone-input" ref={dropdownRef}>
      <div className="drpdown">
        <button onClick={toggleDropdown} className="drpdown-button">
          <CountryFlag countryCode={selectedCountry.code} svg />
          <span>({selectedCountry.dial_code})</span>
          <img
            src={isOpen ? upArrow : downArrow}
            alt="down"
            className="arrow-btn"
          />
        </button>
        {isOpen && (
          <ul className="drpdown-menu">
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
            {filteredItems.map((country) => (
              <li
                key={country.code}
                onClick={() => selectCountry(country)}
                className="drpdown-item"
              >
                <CountryFlag countryCode={country.code} svg />
                <span>
                  {country.name} ({country.dial_code})
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <input
        type="text"
        value={phoneNumber}
        onChange={handlePhoneNumber}
        className="phone-input"
        placeholder="000-0000"
      />
    </div>
  );
};

export default CountryPhoneInput;
