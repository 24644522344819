import ACTIONS from "../constants";

const initialState = {
  countries: [],
  errorMessage: null,
  loading: false,
  destinationCountries: [],
  destinationCountriesErrorMessage: null,
  destinationCountriesLoading: false,
  sourceCountries: [],
  sourceCountriesErrorMessage: null,
  sourceCountriesLoading: false,
};

const CountryReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_COUNTRY:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: payload,
        loading: false,
      };
    case ACTIONS.FETCH_COUNTRY_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.FETCH_DESTINATION_COUNTRY:
      return {
        ...state,
        destinationCountriesErrorMessage: null,
        destinationCountriesLoading: true,
      };
    case ACTIONS.FETCH_DESTINATION_COUNTRY_SUCCESS:
      return {
        ...state,
        destinationCountries: payload,
        destinationCountriesLoading: false,
      };
    case ACTIONS.FETCH_DESTINATION_COUNTRY_ERROR:
      return {
        ...state,
        destinationCountriesErrorMessage: payload,
        destinationCountriesLoading: false,
      };
    case ACTIONS.FETCH_SOURCE_COUNTRY:
      return {
        ...state,
        sourceCountriesErrorMessage: null,
        sourceCountriesLoading: true,
      };
    case ACTIONS.FETCH_SOURCE_COUNTRY_SUCCESS:
      return {
        ...state,
        sourceCountries: payload,
        sourceCountriesLoading: false,
      };
    case ACTIONS.FETCH_SOURCE_COUNTRY_ERROR:
      return {
        ...state,
        sourceCountriesErrorMessage: payload,
        sourceCountriesLoading: false,
      };
    default:
      return state;
  }
};

export default CountryReducer;
