import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import {
  loginReducer,
  twoFactorReducer,
  registerReducer,
  verificationReducer,
  profileReducer,
  sendPhoneCodeReducer,
  verifyPhoneCodeReducer,
  adminProfile,
  businessInfo,
  businessKyb,
  forgotPasswordReducer,
  logoutReducer,
  resetPassword,
} from "./authReducers";
import searchReducer from "./search";

import walletsReducer from "./walletsReducer";
import cardsReducer from "./cardsReducer";
import beneficiariesReducer from "./beneficiariesReducer";
import transactionsReducer from "./transactionsReducer";
import statementReducer from "./statementReducer";
import usersReducer from "./usersReducer";
import ipAddressReducer from "./IPAddressReducer";
import analyticsReducer from "./analyticsReducer";
import quotationsReducer from "./quotationsReducer";
import BanksReducer from "./banksReducer";
import CountryReducer from "./countryReducer";
import RoleReducer from "./roleReducer";
import activateDeactiveReducer from "./activateDeactiveReducer";
import addMoneyReducer from "./addMoneyReducer";
import UserBanksReducer from "./fetchUserBankReducer";
import changePassword from "./changePasswordReducer";
import cryptoReducer from "./fetchCryptoReducer";
import cryptoChainReducer from "./fetchCryptoChainReducer";
import activityLogReducer from "./activityLogsReducer";

const persistConfig = {
  key: "counter",
  storage,
};

const CombinedReducers = combineReducers({
  loginReducer,
  twoFactorReducer,
  adminProfile,
  searchReducer,
  businessInfo,
  businessKyb,
  registerReducer,
  verificationReducer,
  profileReducer,
  sendPhoneCodeReducer,
  verifyPhoneCodeReducer,
  walletsReducer,
  cardsReducer,
  beneficiariesReducer,
  transactionsReducer,
  statementReducer,
  usersReducer,
  analyticsReducer,
  quotationsReducer,
  BanksReducer,
  activateDeactiveReducer,
  CountryReducer,
  forgotPasswordReducer,
  addMoneyReducer,
  UserBanksReducer,
  changePassword,
  cryptoReducer,
  cryptoChainReducer,
  logoutReducer,
  ipAddressReducer,
  RoleReducer,
  resetPassword,
  activityLogReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    // check for action type
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root')
    // TODO: clear all local storage
    window.localStorage.clear();
    state = undefined;
    // console.log(state, "test");
  }
  return CombinedReducers(state, action);
};

const PersistedReducer = persistReducer(persistConfig, rootReducer);

export default PersistedReducer;
