import makeAPICall from "../../utilities/apiUtils";
import { message } from "antd";
import { addMoney, addMoneyError, addMoneySuccess } from "../selectors";

const createAddMoney = (payload) => (dispatch) => {
  dispatch(addMoney());

  return makeAPICall({
    path: "transactions/collections/bankAccounts",
    payload,
    method: "POST",
  })
    .then((res) => {
      // console.log(res, "res");
      const {data} = res;
      dispatch(addMoneySuccess({ data }));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(addMoneyError(err.message));
    });
};

export default createAddMoney ;
