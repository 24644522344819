import React from "react";
import { Button, Form } from "antd";
import QRCode from "qrcode.react";

import VerifyPhoneNumberModalImage from "../../assets/images/Component 2.svg";
import { MediumText } from "../texts";
import StyledModal from "./styled-modal";
import ButtonComponent from "../otpInput";

export default function PinModal({
  show,
  onClose,
  onClick,
  handleInputPinChange,
  isDisabled,
  loading,
  user,
  method,
  details,
}) {
  return (
    <StyledModal show={show} onClose={onClose}>
      {method === "apps" ? (
        <>
          <div className="flex justify-center flex-column align-center">
            <img
              style={{ marginTop: "16px" }}
              src={VerifyPhoneNumberModalImage}
              alt="three stars"
            />
            <MediumText
              style={{ marginTop: "16px", marginBottom: "5px" }}
              value="Enter OTP"
            />
            <br />

            <p className="mb-1" style={{ textAlign: "center" }}>
              Please open up your authenticator app (google authenticator,
              authy, Microsoft Authenticator) and scan the QR code below
            </p>

            <div className="flex justify-center">
              <QRCode value={details?.data?.uri} size={200} />
            </div>
          </div>

          <br />

          <div>
            <p className="mb-1" style={{ textAlign: "center" }}>
              Please enter the pin from your authenticator app
            </p>

            <Form layout="vertical">
              <Form.Item>
                <div className="otp-css">
                  <ButtonComponent
                    secret
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      border: "none",
                      borderBottom: "2px solid #707070",
                      color: "#949494",
                    }}
                    focus
                    handlePincomplete={handleInputPinChange}
                    length={6}
                  />
                </div>
              </Form.Item>
              <div className="mt-5 flex mx-auto" style={{ maxWidth: "230px" }}>
                <Button
                  type="primary"
                  className="resend-modal-btn text-bold text-smd e__btn--primary"
                  disabled={isDisabled}
                  onClick={onClick}
                  loading={loading}
                >
                  Activate
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center flex-column align-center">
            <img
              style={{ marginTop: "16px" }}
              src={VerifyPhoneNumberModalImage}
              alt="three stars"
            />
            <MediumText
              style={{ marginTop: "16px", marginBottom: "5px" }}
              value="Enter OTP"
            />
            <br />
            {method === "email" && (
              <p className="mb-1" style={{ textAlign: "center" }}>
                Please enter the pin sent to your email
              </p>
            )}

            {method === "text" && (
              <p className="mb-1" style={{ textAlign: "center" }}>
                Please enter the pin sent to your phone number
              </p>
            )}
          </div>
          <br />
          <div>
            <Form layout="vertical">
              <Form.Item>
                <div className="otp-css">
                  <ButtonComponent
                    secret
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      border: "none",
                      borderBottom: "2px solid #707070",
                      color: "#949494",
                    }}
                    focus
                    handlePincomplete={handleInputPinChange}
                    length={6}
                  />
                </div>
              </Form.Item>
              <div className="mt-5 flex mx-auto" style={{ maxWidth: "230px" }}>
                <Button
                  type="primary"
                  className="resend-modal-btn text-bold text-smd e__btn--primary"
                  disabled={isDisabled}
                  onClick={onClick}
                  loading={loading}
                >
                  Activate
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
    </StyledModal>
  );
}
