import ACTIONS from "../../constants";

const initialState = {
  user: null,
  errorMessage: null,
  loading: false,
};

const sendPhoneCodeReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.SEND_PHONE_CODE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.SEND_PHONE_CODE_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.SEND_PHONE_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.RESET_SEND_PHONE_CODE:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
};

export default sendPhoneCodeReducer;
