import ACTIONS from "../constants";

const initialState = {
  activeWallet: null,
  activeWalletErrorMessage: null,
  activeWalletLoading: false,
  inactiveWallet: null,
  inactiveWalletErrorMessage: null,
  inactiveWalletLoading: false,
  reorderWallet: null,
  reorderWalletErrorMessage: null,
  reorderWalletLoading: false,
};

const activateDeactiveReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_ACTIVATE:
      return {
        ...state,
        activeWalletLoading: true,
      };
    case ACTIONS.FETCH_ACTIVATE_SUCCESS:
      return {
        ...state,
        activeWallet: payload,
        activeWalletLoading: false,
      };
    case ACTIONS.FETCH_ACTIVATE_ERROR:
      return {
        ...state,
        activeWalletErrorMessage: payload,
        activeWalletLoading: false,
      };
    case ACTIONS.FETCH_DEACTIVATE:
      return {
        ...state,
        inactiveWalletLoading: true,
      };
    case ACTIONS.FETCH_DEACTIVATE_SUCCESS:
      return {
        ...state,
        inactiveWallet: payload,
        inactiveWalletLoading: false,
      };
    case ACTIONS.FETCH_DEACTIVATE_ERROR:
      return {
        ...state,
        inactiveWalletErrorMessage: payload,
        inactiveWalletLoading: false,
      };
    case ACTIONS.FETCH_REORDER:
      return {
        ...state,
        reorderWalletLoading: true,
      };
    case ACTIONS.FETCH_REORDER_SUCCESS:
      return {
        ...state,
        reorderWallet: payload,
        reorderWalletLoading: false,
      };
    case ACTIONS.FETCH_REORDER_ERROR:
      return {
        ...state,
        reorderWalletErrorMessage: payload,
        reorderWalletLoading: false,
      };

    default:
      return state;
  }
};

export default activateDeactiveReducer;
