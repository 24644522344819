import makeAPICall from "../../utilities/apiUtils";
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  resetChangePasswordState
} from "../selectors";
import { message } from "antd";

const changePasswordAction = (data) => (dispatch) => {
  dispatch(changePassword());
  return makeAPICall({
    path: "profile/password",
    payload: data,
    method: "PUT",
  })
    .then((res) => {
      dispatch(changePasswordSuccess("yes"));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(changePasswordError(err.message));
    });
};

const resetChangePasswordAction = () => (dispatch) => {
  dispatch(resetChangePasswordState());
};

export {changePasswordAction, resetChangePasswordAction};
