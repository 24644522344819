import ACTIONS from "../../constants";

const initialState = null;

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOGOUT_USER:
      return {
        ...state,
        initialState: null,
      };
    default:
      return state;
  }
};

export default logoutReducer;
