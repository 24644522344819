import React, { useState } from "react";
import { Steps } from "antd";
import StyledModal from "../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import CardDetails from "../CardDetails";
import { useDispatch } from "../../../utilities/hooks";
import {
  fetchCardsAction,
  fetchWalletsAction,
} from "../../../services/actions";

export default function CreateCardModal({ show, onClose }) {
  const dispatch = useDispatch();
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [card, setCard] = useState({});
  const [cardDetails, setCardDetailsNow] = useState({});


  function onCloseModalDetails() {
    setCardDetailsModal(false);
    dispatch(fetchCardsAction({}));
    dispatch(fetchWalletsAction());
    onClose();
  }

  let colors = {
    blue: "#5a50fe",
    black: "#040052",
    purple: "#FF3599",
    orange: "#00C279",
    yellow: "#EAA5FF",
  };

  const cardModal = (data) => {
    setCardDetailsNow({ ...cardDetails, ...data });
    setCardDetailsModal(true);
  };

  const setCardDetails = (data) => {
    setCard({ ...card, ...data });
  };

  const { Step } = Steps;

  const steps = [
    {
      title: "Choose Recipient and Source Wallet",
      content: (
        <First
          next={() => next()}
          colors={colors}
          card={card}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Select Your Card's Style",
      content: (
        <Second
          next={() => next()}
          card={card}
          colors={colors}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Payment and Card Creation",
      content: (
        <Third
          next={() => next()}
          card={card}
          // colors={colors}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Card Creation Status",
      content: (
        <Fourth
          next={() => next()}
          card={card}
          colors={colors}
          close={closeFinal}
          cardModal={(data) => cardModal(data)}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
  ];

  function next() {
    setCurrent(current + 1);
  }

  function onCloseModal(item) {
    // dispatch(fetchCardsAction({}));
    setCurrent(item);
    onClose();
  }

  function closeFinal() {
    onClose();
    dispatch(fetchCardsAction({}));
    dispatch(fetchWalletsAction());
  }

  return (
    <div>
      {cardDetailsModal ? (
        <CardDetails
          cardDetailsAll={cardDetails}
          tabIndexNow={0}
          onClose={onCloseModalDetails}
          show={cardDetailsModal}
        />
      ) : (
        <div>
          <StyledModal show={show} onClose={() => onCloseModal(0)}>
            <div className="p-4">
              <h1 className="addmoney-title mb-4">Create Card</h1>
              <div className="payout-modal-text">
                <Steps
                  direction="vertical"
                  size="small"
                  current={current}
                  style={{ alignSelf: "flex-start" }}
                  // onChange={(item) => {
                  //   if (current < item) return;
                  //   setCurrent(item);
                  // }}
                >
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div>{steps[current].content}</div>
                <div className="selected"></div>
              </div>
            </div>
          </StyledModal>
        </div>
      )}
    </div>
  );
}
