import React, { useState } from "react";
import { Steps } from "antd";
import StyledModal from "../../styled-modal";
import First from "./First";
import {
  deleteFetchCryptoAction,
  deleteFetchCryptoChainAction,
} from "../../../../services/actions";
import { useDispatch } from "../../../../utilities/hooks";

export default function CyptoMoneyModal({ show, onClose, defaultWallet }) {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);

  // console.log(currentWallet, 'jfjf')

  const { Step } = Steps;
  const steps = [
    {
      title: "Funding details",
      content: (
        <First
          defaultCrypto={defaultWallet}
          closeModal={onClose}
        />
      ),
    },
  ];

  // function next() {
  //   setCurrent(current + 1);
  // }

  // function prev() {
  //   setCurrent(current - 1);
  // }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
    dispatch(deleteFetchCryptoAction());
    dispatch(deleteFetchCryptoChainAction());
  }


  return (
    <div>
      <StyledModal show={show} onClose={() => onCloseModal(0)}>
        <div className="p-4">
          <h1 className="addmoney-title mb-2">Fund Wallet</h1>
          <div className="payout-modal-text">
            <Steps
              direction="vertical"
              size="small"
              current={current}
              style={{ alignSelf: "flex-start" }}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div>{steps[current].content}</div>
            <div className="selected"></div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
}
