import React from 'react';

export function MediumText ({ value, ...props }) {
    return (
        <p 
            style={{ 
                fontWeight: "bold",
                fontSize: "26px",
                lineHeight: "39px",
                letterSpacing: "0.01em",
                color: "#1C223C",
                ...props
            }}
            {...props}
        >
            {value}
        </p>
    )
    
}