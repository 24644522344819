import success from "../../../assets/images/successIcon.svg";
import { useSelector } from "../../../utilities/hooks";
import {
  formatMoney,
  formatBalanceCrypto,
} from "../../../utilities/helperFunctions";
import { Button, Col, Row } from "antd";

export default function SuccessPage({ onClose, onBackPress, cryptoCheck }) {
  const { quotation } = useSelector((state) => state.quotationsReducer);

  if (quotation == null) return null;

  return (
    <div className="m-4 pt-4">
      <Row justify="center" align="middle">
        <Col>
          <h6 className="text-bold color-bold text-center">
            <img src={success} alt="" width={60} />
          </h6>
          <br />
          <h4>Exchange Successful</h4>
        </Col>
      </Row>
      <br />

      <p className="text-center">
        You have successfully exchanged &nbsp;
        {quotation.baseCurrency} &nbsp;
        {cryptoCheck(quotation.baseCurrency).isCrypto
          ? formatBalanceCrypto(quotation.baseAmount)
          : formatMoney(quotation.baseAmount)}
        &nbsp; to &nbsp;
        {quotation.destCurrency} &nbsp;
        {cryptoCheck(quotation.destCurrency).isCrypto
          ? formatBalanceCrypto(quotation.destAmount)
          : formatMoney(quotation.destAmount)}
      </p>

      <br />
      <br />

      <div>
        <Button
          size="large"
          type="default"
          className="grey-btn"
          onClick={onBackPress}
          style={{ width: "100%" }}
        >
          <b>Create New Exchange</b>
        </Button>
      </div>

      <br />

      <div>
        <Button
          size="large"
          type="default"
          className="pay-blue-btn"
          onClick={onClose}
          style={{ width: "100%" }}
        >
          <b>Go to dashboard</b>
        </Button>
      </div>
    </div>
  );
}
