import React, { useState, useRef, useEffect } from "react";
import { Table, Pagination, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import gears from "../../assets/images/filter-gears.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-plus.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-card.svg";
import OneDateRange from "../OneDateRange";
import CustomDateInput from "../CustomDateInput";
import moment from "moment";

export default function GeneralTable({
  columns,
  data,
  download,
  filter,
  filterSelect,
  pagination,
  paginateAll,
  placeHolder,
  handleInputChange,
  filterTransactions,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [filterOptions, setFilterOptions] = useState(filter);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const selectRef = useRef();
  const applyButtonRef = useRef(null);
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDateEnd, setFormattedDateEnd] = useState("");

  const handleDateChange = (event) => {
    setFormattedDate(event);
  };

  const handleDateChangeEnd = (event) => {
    setFormattedDateEnd(event);
  };

  const closeSelect = () => {
    selectRef.current.blur();
  };

  // console.log(selectedFilters, filterOptions, "test");

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    handleInputChange(e.target.value);

    // setTimeout(() => {
    //   setShowClearFilter(true);
    //   handleInputChange(e.target.value);
    // }, 2000); // 2 seconds delay
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(e.target) &&
        applyButtonRef.current &&
        !applyButtonRef.current.contains(e.target)
      ) {
        closeSelect();
        setSelectedFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedFilter]);

  const handleClear = () => {
    setSearchValue("");
  };
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleClearFilterSingleClick = (filter) => {
    const updatedFilterOptions = filterOptions.map((item) => {
      if (item.label === filter) {
        return { label: filter, clickable: true };
      }
      return item;
    });
    setSelectedFilters((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[filter];
      return updatedValues;
    });
    setFilterOptions(updatedFilterOptions);
    filterTransactions("cleared");
  };

  const handleOptionSelect = (filter, value) => {
    setSelectedFilters({
      ...selectedFilters,
      [filter]: value,
    });
  };

  const handleOperation = (filter, value) => {
    // Perform your operation here based on the selected value
    // console.log(
    //   `Performing operation for filter ${filter} with value ${value}`
    // );

    if (value === "Custom") {
      setShowCalendar(true);
      setSelectedFilter(null); // Optionally close the dropdown
    } else {
      filterTransactions(value);
      const updatedFilterOptions = filterOptions.map((item) => {
        if (item.label === filter) {
          return { label: item.label, clickable: false };
        }
        return item;
      });

      const falseClickables = updatedFilterOptions.filter(
        (item) => !item.clickable
      );
      // Check if there are more than one clickable: false
      if (falseClickables.length > 1) setShowClearFilter(true);
      setFilterOptions(updatedFilterOptions);
      setSelectedFilter(null); // Optionally close the dropdown
    }
  };

  const clearAll = () => {
    setSelectedFilters({});
    setFilterOptions(filter);
    setShowClearFilter(false);
    setSearchValue("");
    filterTransactions("cleared");
  };

  const clearCustom = () => {
    setShowCalendar(false);
    filterTransactions("cleared");
  };

  const getStartDate = (date) => {
    setStartDate(date);
    setFormattedDate(moment(date).format("DD-MM-YYYY"));
  };

  const getEndDate = (date) => {
    setEndDate(date);
    setFormattedDateEnd(moment(date).format("DD-MM-YYYY"));
  };

  const customFilterApply = () => {
    setShowCalendar(false);
    filterTransactions("Custom", {
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
    });
    const updatedFilterOptions = filterOptions.map((item) => {
      if (item.label === "Custom") {
        return { label: item.label, clickable: false };
      }
      return item;
    });

    const falseClickables = updatedFilterOptions.filter(
      (item) => !item.clickable
    );
    // Check if there are more than one clickable: false
    if (falseClickables.length > 1) setShowClearFilter(true);
    setFilterOptions(updatedFilterOptions);
    setSelectedFilter(null); // Optionally close the dropdown
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="custom-table-component">
        <div className=" p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center p-3">
              <p className="mr-2 paginate-total">
                Items per page: <span>{pagination?.limit}</span>
              </p>
              <Pagination
                defaultCurrent={pagination?.page}
                total={pagination?.total}
                showSizeChanger={false}
                showQuickJumper={false}
                onChange={(page, pageSize) => paginateAll(page, pageSize)}
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="mr-2 input-card">
                <Input
                  placeholder={placeHolder}
                  prefix={
                    <SearchOutlined
                      onClick={handleClear}
                      style={{ cursor: "pointer" }}
                    />
                  }
                  value={searchValue}
                  onChange={handleChange}
                />
              </div>
              {download ? (
                <button className="general-btn-grey">Download</button>
              ) : null}
            </div>
          </div>
          <div className="px-2">
            {filterOptions?.length === 0 ? null : (
              <div style={{ position: "relative" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    {showClearFilter ? (
                      <div
                        className="filter-btn-general mr-4"
                        style={{ color: "#74748A", cursor: "pointer" }}
                        onClick={clearAll}
                      >
                        <CloseIcon />
                        <span className="ml-2">Clear Filter</span>
                      </div>
                    ) : null}
                    {filterOptions.map((item, index) => (
                      <div key={index}>
                        {item.clickable ? (
                          <div
                            className="mr-2 filter-options-general"
                            onClick={() => handleFilterClick(item.label)}
                          >
                            <FilterIcon />
                            <span className="ml-1">{item.label}</span>
                          </div>
                        ) : (
                          <div className="mr-2 filter-options-general-white">
                            <CloseIcon
                              onClick={() =>
                                handleClearFilterSingleClick(item.label)
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <span className="ml-1" style={{ color: "#4B487A" }}>
                              {item.label}
                            </span>
                            |
                            <span className="ml-1" style={{ color: "#5A50FE" }}>
                              {selectedFilters[item.label]}
                            </span>
                          </div>
                        )}

                        {selectedFilter === item.label && (
                          <div className="filter-dropdown-table mt-2">
                            <select
                              ref={selectRef}
                              onChange={(e) =>
                                handleOptionSelect(item.label, e.target.value)
                              }
                              className="reg-select"
                              style={{ height: "40px" }}
                            >
                              {isEmpty(filterSelect) ? null : (
                                <>
                                  {filterSelect[item.label].map(
                                    (option, optionIndex) => (
                                      <option key={optionIndex} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </>
                              )}
                            </select>
                            <button
                              ref={applyButtonRef}
                              type="button"
                              style={{ width: "100%" }}
                              className="general-btn mt-2"
                              disabled={!selectedFilters[item.label]}
                              onClick={() =>
                                handleOperation(
                                  item.label,
                                  selectedFilters[item.label]
                                )
                              }
                            >
                              Apply
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="general-table-filters">
                      <button className="filter-btn-general">
                        <span>
                          <img src={gears} alt="" />
                        </span>
                        &nbsp; Filter by
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-1">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 350 }}
          />
        </div>
      </div>

      {showCalendar ? (
        <div className="card-custom-date d-flex align-items-center">
          <div className="custom-date-filter-options">
            <h1>Today</h1>
            <h1>This week</h1>
            <h1>This month</h1>
            <h1>This year</h1>
            <hr />
            <h1>Custom</h1>
          </div>
          <div className="custom-date-filter-table">
            <div className="d-flex mb-2">
              <div className="mr-4">
                <h1>From</h1>
                <CustomDateInput
                  value={formattedDate}
                  onChange={handleDateChange}
                />
              </div>
              <div>
                <h1>To</h1>
                <CustomDateInput
                  value={formattedDateEnd}
                  onChange={handleDateChangeEnd}
                />
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <OneDateRange
                  selected={startDate}
                  onChange={(date) => getStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  showPopperArrow={false}
                  inline
                />
                <OneDateRange
                  selected={endDate}
                  onChange={(date) => getEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  showPopperArrow={false}
                  inline
                />
              </div>
              <hr />
              <div className="float-right">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="general-btn-grey mr-2"
                    onClick={clearCustom}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="general-btn"
                    onClick={customFilterApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
