import config from "../configs";
import axios from "axios";

export const AUTH_TOKEN = "__API_TOKEN__";

export const getAuthToken = () => window.localStorage.getItem(AUTH_TOKEN);

const makeAPICall = async ({
  path,
  method = "POST",
  payload = null,
  onUploadProgress = null,
  params = null, // New params property
}) => {
  const token = getAuthToken();

  const myHeaders = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const configuration = {
    method: method,
    headers: myHeaders,
    data: payload,
    url: `${config.BACKEND_URL}/${path}`,
    onUploadProgress: onUploadProgress,
    params: params, 
  };

  try {
    const response = await axios(configuration);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default makeAPICall;

