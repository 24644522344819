import React from "react";
import payoutLogoOne from "../../../assets/images/blue-payout.svg";
import payoutLogoTwo from "../../../assets/images/blue-payout-border.svg";
import { Tag, Form, Button } from "antd";
import { formatBalance, formatTime } from "../../../utilities/helperFunctions";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "20%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 30,
    marginBottom: 20,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 10,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 20,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    marginRight: "20px",
    marginBottom: "10px",
    width: "25%",
  },
  rightText: {
    color: "#040052",
    fontSize: 13,
    fontWeight: 700,
    marginBottom: "10px",
  },
});

export default function Fourth({ transDetails, onClick, gotoHome }) {
  const [viewOne, setViewOne] = React.useState(true);
  const [viewTwo, setViewTwo] = React.useState(false);

  let accountDetails = transDetails;

  // console.log(exchange, 'test');

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/eversend_receipt_logo.png" />
          <Text style={styles.date}>
            {formatTime(accountDetails?.createdAt)}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>
            Hi {accountDetails?.user?.firstName},
          </Text>
          <Text style={styles.userName}>
            You have created a transaction on your Eversend wallet
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Transaction Details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>ID</Text>
          <Text style={styles.rightText}>{accountDetails?.transactionId}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Type</Text>
          <Text style={styles.rightText}>
            Payout - &nbsp;
            {accountDetails?.beneficiary?.isMomo === true
              ? "Momo"
              : accountDetails?.beneficiary?.isBank === true
              ? "Bank"
              : accountDetails?.beneficiary?.isEversend === true
              ? "Eversend"
              : null}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Beneficiary</Text>
          <Text style={styles.rightText}>
            {accountDetails?.beneficiary?.firstName}&nbsp;
            {accountDetails?.beneficiary?.lastName}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Amount</Text>
          <Text style={styles.rightText}>
            {accountDetails?.currency} {formatBalance(accountDetails?.amount)}
          </Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Status</Text>
          <Text style={styles.rightText}>
            {accountDetails?.status === "pending" ? "Pending" : null}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The Eversend team</Text>
        </View>
        <View style={styles.rowTwo}>
          <Image style={styles.imageGray} src="/eversend_logo_gray.png" />
          {/* <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/twitter.png" />
          </Link>
          <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/facebook.png" />
          </Link>
          <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/instagram.png" />
          </Link> */}
        </View>
      </Page>
    </Document>
  );

  const handleClick = () => {
    setViewOne(false);
    setViewTwo(true);
  };
  return (
    <div className="payout-receipt">
      {viewOne ? (
        <div className="text-center">
          <img src={payoutLogoOne} alt="" width="30px" />
          <p className="payout-success-title">
            Transaction Successfully Created
          </p>
          <p className="payout-sent">You Sent </p>
          <h1 className="payout-amount">
            {accountDetails?.currency} {formatBalance(accountDetails?.amount)}
          </h1>
          <p className="payout-sent">
            To --{accountDetails?.beneficiary?.firstName}&nbsp;
            {accountDetails?.beneficiary?.lastName}--
          </p>
          <br />
          <hr />
          <br />
          <div
            className="d-flex"
            style={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <p>
              <Tag color="geekblue">
                {accountDetails?.status === "pending" ? "pending" : null}
              </Tag>
            </p>
            <p className="payout-initials">
              {accountDetails?.beneficiary?.firstName.charAt(0) +
                accountDetails?.beneficiary?.lastName.charAt(0)}
            </p>
            &nbsp;
            <p className="payout-user-name">
              {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails?.beneficiary?.lastName}
            </p>
            &nbsp;&nbsp;
            <p className="payout-user-name">
              -{accountDetails?.currency}{" "}
              {formatBalance(accountDetails?.amount)}
            </p>
            &nbsp;
            <p className="payout-view-more">
              View Full Details&nbsp; <i className="fa fa-angle-right"></i>
            </p>
          </div>
          {/* <h1 className="beneficiary-payout">Add as benefitiary</h1> */}
          <Form>
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                style={{ width: "49%" }}
                onClick={onClick}
              >
                Create New Payout
              </Button>
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                style={{ width: "49%", marginLeft: "5px" }}
                onClick={gotoHome}
              >
                Go To Dashboard
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}
      {viewTwo ? (
        <div>
          <div className="text-center">
            <p className="payout-success-title">
              <img src={payoutLogoTwo} alt="logo" />
              &nbsp; Payout Successful
            </p>
            <p className="payout-sent">
              Payment to {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails?.beneficiary?.lastName} has been initiated.
            </p>
          </div>
          <div className="d-flex">
            <p className="payout-sent">Transaction</p>
            <p className="ml-auto">
              <Tag color="geekblue">
                {accountDetails?.status === "pending" ? "pending" : null}
              </Tag>
            </p>
          </div>
          <hr />
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent time
              </span>
              <br />
              {formatTime(accountDetails?.createdAt)}
            </p>
            <p className="ml-auto text-right payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Transaction Id
              </span>
              <br />
              {accountDetails?.transactionId}
            </p>
          </div>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent By
              </span>
              <br />
              {accountDetails?.user?.firstName}&nbsp;
              {accountDetails?.user?.lastName}
            </p>
            <p className="ml-auto text-right payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent From
              </span>
              <br />
              {accountDetails?.currency} wallet
            </p>
          </div>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Transaction Fee
              </span>
              <br />
              {accountDetails?.currency} {accountDetails?.meta?.fees}
            </p>
            <p className="ml-auto text-right payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Exchange Rate
              </span>
              <br />
              {/* {exchange.rate} */}
              {accountDetails?.meta?.fees}
            </p>
          </div>
          <hr />
          <p className="payout-view-more">Recipient Details</p>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Recipient
              </span>
              <br />
              {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails?.beneficiary?.lastName}
            </p>
            <p className="ml-auto text-right payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Account Type
              </span>
              <br />
              {accountDetails?.beneficiary?.isMomo === true
                ? "Momo"
                : accountDetails?.beneficiary?.isBank === true
                ? "Bank"
                : accountDetails?.beneficiary?.isEversend === true
                ? "Eversend"
                : null}
            </p>
          </div>
          {accountDetails?.beneficiary?.isBank === true ? (
            <div className="d-flex">
              <p className="payout-sent">
                <span style={{ color: "#696779", fontSize: "14px" }}>
                  Bank Name
                </span>
                <br />
                {accountDetails?.beneficiary?.bankAccountName}
              </p>
              <p className="ml-auto text-right payout-sent">
                <span style={{ color: "#696779", fontSize: "14px" }}>
                  Bank Account Number
                </span>
                <br />
                {accountDetails?.beneficiary?.bankAccountNumber}
              </p>
            </div>
          ) : null}

          {/* <p className="payout-view-more">
            <span style={{ color: "#2f80ed" }}>
              <i className="fa fa-download"></i>&nbsp; Download
            </span>&nbsp;
            these details as a PDF
          </p> */}
          <PDFDownloadLink document={<MyDocument />} fileName="payout.pdf">
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <p className="payout-view-more">
                  <span style={{ color: "#2f80ed" }}>
                    <i className="fa fa-download"></i>&nbsp; Download
                  </span>
                  &nbsp; these details as a PDF
                </p>
              )
            }
          </PDFDownloadLink>
          {/* <h1 className="beneficiary-payout">Add as benefitiary</h1> */}
          <Form>
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                style={{ width: "49%" }}
                onClick={onClick}
              >
                Create New Payout
              </Button>
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                style={{ width: "49%", marginLeft: "5px" }}
                onClick={gotoHome}
              >
                Go To Dashboard
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}
    </div>
  );
}
