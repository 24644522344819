import React from "react";
import { Layout, Avatar } from "antd";
import _ from "lodash";
import { useSelector } from "../../utilities/hooks";

import NavHeader from "./Header";
import BackBone from "./NewSideBar";
import history from "../../utilities/history";
// import { getUser } from "../../utilities/authUtils";
import { NavLink } from "react-router-dom";

export function Dashboard({ children }) {
  // const user = getUser();
  const { user } = useSelector((state) => state.profileReducer);

  const { Content } = Layout;

  const businessName = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <NavLink
      className="sidebar-profile-container"
      to="#"
      onClick={(e) => e.preventDefault()}
    >
      {user?.account?.logo ? (
        <Avatar src={user?.account?.logo} size={50} />
      ) : (
        <Avatar style={{ backgroundColor: "#f56a00" }}>
          {_.get(user, "account.name", "E").charAt(0)}
        </Avatar>
      )}
      <span>{_.get(user, "account.name", "")}</span>
    </NavLink>
    // <a href='javascript:void(0);' className='sidebar-profile-container'>

    // </a>
  );

  const showFaqOnboardModal = (index) => {
    history.push(`/overview/${index}`);
  };

  return (
    <div className="layout-changes">
      <Layout>
        <BackBone accountChildren={businessName} />
        <Layout className="site-layout">
          <NavHeader openModal={showFaqOnboardModal} />
          <Content>
            <div className="site-layout-background">
              <div>{children}</div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
