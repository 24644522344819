import { message } from "antd";

import makeAPICall from "../../utilities/apiUtils";

import {
  fetchBeneficiaries,
  fetchBeneficiariesError,
  fetchBeneficiariesSuccess,
  addBeneficiaries,
  addBeneficiariesError,
  addBulkBeneficiaries,
  deleteAllBeneficiaries,
  deleteSingleBeneficiaries,
  checkAllBeneficiaries,
  checkSingleBeneficiaries,
  unCheckAllBeneficiaries,
  unCheckSingleBeneficiaries,
  editSingleBeneficiary,
  deleteSingleBeneficiariesByIndex,
  hideUploadBeneficiary,
  showUploadBeneficiary,
  pauseResumeBulkUploadBeneficiaries,
  markBulkBeneficiaryEntryUploadStatus,
  percentageOfUploadBeneficiary,
  currentUploadBeneficiary,
  isUploadBeneficiaryCompleted,
  resetUploadBeneficiary,
  fetchSingleBeneficiarySuccess,
  fetchSingleBeneficiary,
} from "../selectors";
import TagManager from "react-gtm-module";

import { key } from "../../utilities/helperFunctions";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_saved_beneficiary",
    beneficiary_operation: "create",
  },
};

const fetchBeneficiariesAction =
  ({ params = null }, shouldFetch = true) =>
  (dispatch) => {
    if (shouldFetch) dispatch(fetchBeneficiaries());
    return makeAPICall({
      path: "beneficiaries",
      method: "GET",
      params,
    })
      .then((res) => {
        // const { beneficiaries } = res.data;
        dispatch(fetchBeneficiariesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchBeneficiariesError(err.message));
      });
  };

const fetchSingleBeneficiaryAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchSingleBeneficiary());
    return makeAPICall({
      path: `beneficiaries/${params.id}`,
      method: "GET",
      params,
    })
      .then((res) => {
        dispatch(fetchSingleBeneficiarySuccess(res.data));
      })
      .catch((err) => {});
  };

const addBeneficiariesAction = (beneficiaries) => (dispatch) => {
  dispatch(addBeneficiaries());
  message.loading({ content: "Loading...", key });
  return makeAPICall({
    path: "beneficiaries",
    payload: beneficiaries,
    method: "POST",
  })
    .then((res) => {
      TagManager.dataLayer(tagManagerArgs);
      return makeAPICall({
        path: "beneficiaries",
        method: "GET",
      })
        .then((res) => {
          // const { beneficiaries } = res.data;
          message.success({
            content: "Beneficiary Added Successfully",
            key,
            duration: 4,
          });
          dispatch(fetchBeneficiariesSuccess(res.data));
        })
        .catch((err) => {
          dispatch(fetchBeneficiariesError(err.message));
        });
      // const { beneficiaries } = res.data;

      // dispatch(addBeneficiariesSuccess(beneficiaries));
    })
    .catch((err) => {
      dispatch(addBeneficiariesError(err.message));
    });
};

const uploadBulkBeneficiariesAction =
  ({ beneficiaries, type = "start" }) =>
  (dispatch) => {
    if (type === "start") dispatch(resetUploadBeneficiary());
    dispatch(pauseResumeBulkUploadBeneficiaries());

    const beneficiariesToUpload = [...beneficiaries];

    const uploadBeneficiary = (index) => {
      let percent = ((index + 1) / beneficiariesToUpload.length) * 100;
      dispatch(currentUploadBeneficiary(index + 1));
      dispatch(percentageOfUploadBeneficiary(percent.toFixed(2)));
      const beneficiary = beneficiariesToUpload[index];

      return new Promise((resolve, reject) => {
        const completeRequest = (status) => {
          dispatch(markBulkBeneficiaryEntryUploadStatus(status, beneficiary));

          if (index === beneficiariesToUpload.length - 1) {
            dispatch(isUploadBeneficiaryCompleted(true));
            resolve();
          } else {
            const isManuallyPaused =
              window.localStorage.getItem("bulkUploadBeneficiariesPaused") ||
              "false";

            if (isManuallyPaused !== "true") {
              uploadBeneficiary(index + 1);
            } else {
              reject();
            }
          }
        };

        let arr = [];
        arr.push(beneficiary);

        makeAPICall({
          path: "beneficiaries",
          method: "POST",
          payload: arr,
        })
          .then((res) => {
            completeRequest("success");
          })
          .catch((err) => {
            completeRequest("failed");
          });
      });
    };

    if (beneficiariesToUpload.length !== 0) {
      uploadBeneficiary(0)
        .then(() => dispatch(pauseResumeBulkUploadBeneficiaries()))
        .catch(() => {
          dispatch(pauseResumeBulkUploadBeneficiaries());
        });
    }
  };

const addBulkBeneficiariesAction = (beneficiaries) => (dispatch) => {
  dispatch(addBulkBeneficiaries(beneficiaries));
};

const deleteSingleBeneficiariesAction = (beneficiaries) => (dispatch) => {
  dispatch(deleteSingleBeneficiaries(beneficiaries));
};

const deleteSingleBeneficiaryAction =
  ({ params = null }) =>
  (dispatch) => {
    message.loading({ content: "deleting beneficiary...", key });
    return makeAPICall({
      path: `beneficiaries/${params.id}`,
      payload: params,
      method: "DELETE",
    })
      .then((res) => {
        message.success({
          content: "Beneficiary Deleted Successfully",
          key,
          duration: 4,
        });
        window.history.back();
      })
      .catch((err) => {
        message.error({
          content: err.message,
          key,
          duration: 4,
        });
      });
  };

const deleteSingleBeneficiariesByIndexAction = (index) => (dispatch) => {
  dispatch(deleteSingleBeneficiariesByIndex(index));
};

const deleteAllBeneficiariesAction = (beneficiaries) => (dispatch) => {
  dispatch(deleteAllBeneficiaries(beneficiaries));
};

const checkAllBeneficiariesAction = () => (dispatch) => {
  dispatch(checkAllBeneficiaries());
};

const checkSingleBeneficiariesAction = (index) => (dispatch) => {
  dispatch(checkSingleBeneficiaries(index));
};

const unCheckAllBeneficiariesAction = () => (dispatch) => {
  dispatch(unCheckAllBeneficiaries());
};

const unCheckSingleBeneficiariesAction = (index) => (dispatch) => {
  dispatch(unCheckSingleBeneficiaries(index));
};

const editSingleBeneficiaryAction = (beneficiary) => (dispatch) => {
  dispatch(editSingleBeneficiary(beneficiary));
};

const showUploadBeneficiaryAction = () => (dispatch) => {
  dispatch(showUploadBeneficiary());
};

const hideUploadBeneficiaryAction = () => (dispatch) => {
  dispatch(hideUploadBeneficiary());
};

export {
  fetchBeneficiariesAction,
  addBeneficiariesAction,
  addBulkBeneficiariesAction,
  deleteSingleBeneficiariesAction,
  deleteAllBeneficiariesAction,
  unCheckSingleBeneficiariesAction,
  unCheckAllBeneficiariesAction,
  checkSingleBeneficiariesAction,
  checkAllBeneficiariesAction,
  editSingleBeneficiaryAction,
  deleteSingleBeneficiariesByIndexAction,
  showUploadBeneficiaryAction,
  hideUploadBeneficiaryAction,
  uploadBulkBeneficiariesAction,
  fetchSingleBeneficiaryAction,
  deleteSingleBeneficiaryAction,
};
