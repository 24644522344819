import React, { useState } from "react";
import { Steps } from "antd";
import StyledModal from "../../styled-modal";
import First from "./first";
import Second from "./second";
import Third from "./third";
// import { useDispatch } from "../../../utilities/hooks";

export default function RequestMoneyModal({ show, onClose }) {
  //   const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [currencyDetails, setCurrencyDetails] = useState();

  const { Step } = Steps;
  const steps = [
    {
      title: "Eversend user info",
      content: <First next={nextFirst} userDetailsTwo={userDetails} />,
    },
    {
      title: "Request amount",
      content: (
        <Second
          next={nextSecond}
          currencyDetails={currencyDetails}
          prev={() => prev()}
        />
      ),
    },
    {
      title: "Confirm",
      content: (
        <Third
          next={nextThird}
          allData={{ userDetails, currencyDetails }}
          prev={() => prev()}
          close={onClose}
          newTrans={newTrans}
        />
      ),
    },
  ];

  function newTrans() {
    setUserDetails();
    setCurrencyDetails();
    setCurrent(0);
  }

  function nextFirst(userDetails) {
    setUserDetails(userDetails);
    setCurrent(current + 1);
  }

  function nextSecond(amountCurrency) {
    setCurrencyDetails(amountCurrency);
    setCurrent(current + 1);
  }

  function nextThird(amountCurrency) {
    setCurrencyDetails(amountCurrency);
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
  }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
  }

  return (
    <div>
      <StyledModal show={show} onClose={() => onCloseModal(0)}>
        <div className="p-4">
          <h1 className="addmoney-title mb-1">Fund Wallet</h1>
          <p className="request-money-title mb-4">Request money</p>
          <div className="payout-modal-text">
            <Steps
              direction="vertical"
              size="small"
              current={current}
              style={{ alignSelf: "flex-start" }}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div>{steps[current].content}</div>
            <div className="selected"></div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
}
