import React, { Suspense, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin} from "../utilities/authUtils";

const PrivateRouteCheck = ({ component: Component, title, ...rest }) => {
  
  useEffect(() => {
    document.title = `Eversend | ${title || 'Dashboard'}`;
  }, [title]);

  return (<Route
    {...rest}
    render={(props) =>
      isLogin() ? (
        <Suspense fallback={""}>
          <Component {...props} {...rest} />
        </Suspense>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
)};

export default PrivateRouteCheck;
