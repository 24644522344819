import ACTIONS from "../../constants";

const initialState = {
  user: {},
  errorMessage: null,
  loading: false,
};

const profileReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.FETCH_USER:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.FETCH_USER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PROFILE:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case ACTIONS.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.CHANGE_API_SECRET:
      return {
        ...state,
        user: {
          ...state.user,
          account: { ...state.user.account, clientSecret: payload },
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
