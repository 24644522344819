import makeAPICall from "../../utilities/apiUtils";
import {
  fetchWallets,
  fetchWalletsError,
  fetchWalletsSuccess,
  toggleExchangeModal,
  toggleAddMoneyModal,
  toggleSendMoneyModal,
  toggleCreateCardModal,
  toggleSendMoneyBeneficiaryModal,
} from "../selectors";

const fetchWalletsAction = () => (dispatch) => {
  dispatch(fetchWallets());
  return makeAPICall({
    path: "wallets?analyticsRange=month",
    method: "GET",
  })
    .then((res) => {
      const { wallets } = res.data;

      dispatch(fetchWalletsSuccess(wallets));
    })
    .catch((err) => {
      dispatch(fetchWalletsError(err.message));
    });
};

export const toggleExchangeModalAction = (name) => (dispatch) => {
  dispatch(toggleExchangeModal(name));
};

export const toggleAddMoneyModalAction = (name, item) => (dispatch) => {
  const action = {
    name,
    item,
  };

  // console.log(action, "playground");
  dispatch(toggleAddMoneyModal(action));
};

export const toggleSendMoneyModalAction = () => (dispatch) => {
  dispatch(toggleSendMoneyModal());
};

export const toggleCreateCardModalAction = () => (dispatch) => {
  dispatch(toggleCreateCardModal());
};

export const toggleSendMoneyBeneficiaryModalAction = (name) => (dispatch) => {
  dispatch(toggleSendMoneyBeneficiaryModal(name));
};

export default fetchWalletsAction;
