import React, { useState } from "react";
import StyledModal from "../styled-modal";
import ClosedEye from "../../../assets/images/Eye_Closed.svg";
import OpenEye from "../../../assets/images/Eye_Open.svg";
import logo from "../../../assets/images/v-card-logo.svg";
import masterCard from "../../../assets/images/master-card.svg";
import visaCard from "../../../assets/images/visa-logo.svg";
import { ReactComponent as FundNewIcon } from "../../../assets/images/add-card-circle.svg";
import { ReactComponent as WithdrawCardIcon } from "../../../assets/images/fund-card-circle.svg";
import { ReactComponent as ShareIcon } from "../../../assets/images/share-card.svg";
import { ReactComponent as FreezeIcon } from "../../../assets/images/new-freeze-card.svg";
import { ReactComponent as UnfreezeIcon } from "../../../assets/images/color-frozen.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/new-delete-card.svg";
import { maskCardMiddleNumbers } from "../../../utilities/helperFunctions";
import Tabs from "../../../components/StartTab";
import Tab from "../../../components/StartTab/tab";
import moment from "moment";
import CardNumbers from "./CardNumbers";
// import CardBillingInfo from "./CardBillingInfo";
import CardTransactions from "./CardTransactions";
import FundCard from "../fundCard";
import WithdrawCard from "../withdrawCard";
import DeleteCard from "../deleteCard";
import makeAPICall from "../../../utilities/apiUtils";
import { useDispatch, useSelector } from "../../../utilities/hooks";
import { message, Spin } from "antd";
import {
  fetchCardsAction,
  fetchWalletsAction,
} from "../../../services/actions";
import { formatBalance } from "../../../utilities/helperFunctions";
import { AccessControl } from "accesscontrol";

export default function CardDetails({
  show,
  tabIndexNow,
  onClose,
  cardDetailsAll,
}) {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.RoleReducer);
  const { user } = useSelector((state) => state.profileReducer);
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndexNow);
  const [showCardSummary, setShowCardSummary] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalWithdrawOpen, setIsModalWithdrawOpen] = useState(false);
  const [isLoadingSingle, setLoadingStateSingle] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  let colors = {
    blue: "#5a50fe",
    black: "#040052",
    purple: "#FF3599",
    orange: "#00C279",
    yellow: "#EAA5FF",
  };
  function handleTabClick(index) {
    setActiveTabIndex(index);
  }

  function toggleCardSummaryDisplay() {
    setShowCardSummary(!showCardSummary);
  }

  function handleCancel() {
    setIsModalOpen(false);
    onClose();
  }

  const onPrompt = () => {
    setIsModalOpen(true);
  };

  const onDelete = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDelete = () => {
    const data = {
      cardId: cardDetailsAll?.card?.id,
    };
    setLoadingDelete(true);
    return makeAPICall({
      path: "cards/terminate",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        // console.log(res);
        message.success("Your card has been deleted successfully", 5);
        setLoadingDelete(false);
        dispatch(fetchCardsAction({}));
        dispatch(fetchWalletsAction());
        setIsModalDeleteOpen(false);
        onClose();
      })
      .catch((err) => {
        setLoadingDelete(false);
        message.error(err.message, 5);
      });
  };

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
  }

  function handleCancelWithdraw() {
    setIsModalWithdrawOpen(false);
    onClose();
  }

  const onPromptWithdraw = () => {
    setIsModalWithdrawOpen(true);
  };

  function onFreeze() {
    if (cardDetailsAll?.card?.status === "frozen") {
      const data = {
        cardId: cardDetailsAll?.card?.id,
      };
      setLoadingStateSingle(true);
      return makeAPICall({
        path: "cards/unfreeze",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          message.success(`Your card has been unfrozen successfully`, 5);
          setLoadingStateSingle(false);
          dispatch(fetchCardsAction({}));
          onClose();
        })
        .catch((err) => {
          setLoadingStateSingle(false);
          message.error(err.message, 5);
        });
    } else {
      const data = {
        cardId: cardDetailsAll?.card?.id,
      };
      setLoadingStateSingle(true);
      return makeAPICall({
        path: "cards/freeze",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          message.success(`Your card has been frozen successfully`, 5);
          setLoadingStateSingle(false);
          dispatch(fetchCardsAction({}));
          onClose();
        })
        .catch((err) => {
          setLoadingStateSingle(false);
          message.error(err.message, 5);
        });
    }
  }

  const handleShare = () => {
    const subject = "Your Eversend Virtual Card Details";

    const bodyLines = [
      `Here's your Eversend Virtual Card Details`,
      `Card Name: ${cardDetailsAll?.card?.name}`,
      `Card Number: ${cardDetailsAll?.card?.number}`,
      `Card Expiration Date: ${cardDetailsAll?.card?.expiration}`,
      `Card Security Code: ${cardDetailsAll?.card?.securityCode}`,
      `Street: ${cardDetailsAll?.card?.billingAddress?.address}`,
      `Town/City: ${cardDetailsAll?.card?.billingAddress?.city}`,
      `ZIP/Postal code: ${cardDetailsAll?.card?.billingAddress?.zipCode}`,
      `State: ${cardDetailsAll?.card?.billingAddress?.state}`,
      `Country: ${cardDetailsAll?.card?.billingAddress?.country}`,
      // `Phone Number: +1 609 257 4786`,
      // Add more lines as needed
    ];

    // Construct the email body by joining the lines with line breaks
    const body = bodyLines.join("\n");
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Create a temporary textarea element for copying to clipboard
    const textArea = document.createElement("textarea");
    textArea.value = body;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    message.success("Copied to clipboard", 5);

    // Open the default email client
    window.location.href = mailtoLink;
  };

  const isCardDeleted =
    cardDetailsAll?.card?.status === "terminated" ||
    cardDetailsAll?.card?.status === "terminating";

  const isCardFrozen = cardDetailsAll?.card?.status === "frozen";

  // const cardUser = cardDetails?.card?.userId === user?.id;

  const cardNumber = (number) => {
    if (number === "" || number === null || number === undefined) {
      return "****";
    } else {
      return number;
    }
  };

  const isExpiryDate = (date) => {
    // Check if the expiration date is in the first format
    if (/^\d{4}$/.test(date)) {
      return date;
    } else {
      date = moment(date).format("MM/YY");
      return date;
    }
  };

  return (
    <div>
      <div>
        {isModalOpen ? (
          <FundCard
            show={isModalOpen}
            onClose={handleCancel}
            cardDetails={cardDetailsAll}
          />
        ) : isModalWithdrawOpen ? (
          <WithdrawCard
            show={isModalWithdrawOpen}
            onClose={handleCancelWithdraw}
            cardDetails={cardDetailsAll}
          />
        ) : (
          <StyledModal show={show} onClose={onClose}>
            <div className="custom-padding">
              <div className="d-flex justify-content-between align-items-center v-card-header">
                <div>
                  <h1>{cardDetailsAll?.card?.title}</h1>
                  <p className="mt-2">
                    Assigned to: {cardDetailsAll?.card?.name}
                  </p>
                </div>
                {/* <h2>Minimise view</h2> */}
              </div>
              <br />
              <div className="row">
                <div className="col-md-4">
                  {checkAccess("cards").granted ? (
                    <div>
                      {isCardFrozen || isCardDeleted ? null : (
                        <div className="d-flex align-items-center v-card-show">
                          <h1 className="mr-1">Show</h1>
                          {showCardSummary ? (
                            <img
                              onClick={toggleCardSummaryDisplay}
                              src={ClosedEye}
                              alt="open"
                              width="25px"
                              height="auto"
                            />
                          ) : (
                            <img
                              onClick={toggleCardSummaryDisplay}
                              src={OpenEye}
                              alt="open"
                              width="25px"
                              height="auto"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div
                    className="virtual-card-design p-4 mt-3"
                    style={{
                      backgroundColor: isCardFrozen
                        ? "#04005266"
                        : colors[cardDetailsAll?.card?.color] || "#5a50fe",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <img src={logo} alt="Eversend logo" />
                      <h2>VIRTUAL</h2>
                    </div>
                    <h1 className="mt-3">
                      {checkAccess("cards").granted
                        ? !showCardSummary
                          ? cardNumber(cardDetailsAll?.card?.number)
                          : maskCardMiddleNumbers(
                              cardNumber(cardDetailsAll?.card?.number)
                            )
                        : cardDetailsAll?.card?.mask}
                    </h1>
                    <div className="d-flex align-items-center mt-3">
                      <div>
                        <p>Valid thru</p>
                        <h3>
                          {checkAccess("cards").granted ? (
                            <span>
                              {isExpiryDate(cardDetailsAll?.card?.expiration)}
                            </span>
                          ) : (
                            "****"
                          )}
                        </h3>
                      </div>

                      <div className="ml-3">
                        <p>CVV</p>
                        <h3>
                          {checkAccess("cards").granted
                            ? !showCardSummary
                              ? cardDetailsAll?.card?.securityCode
                              : "***"
                            : "***"}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h4>{cardDetailsAll?.card?.name}</h4>
                      <img
                        src={
                          cardDetailsAll?.card?.brand === "Visa"
                            ? visaCard
                            : masterCard
                        }
                        alt="mastercard logo"
                      />
                    </div>
                  </div>
                  {isCardDeleted ? null : (
                    <div>
                      {checkAccess("cards").granted && (
                        <div>
                          <Spin spinning={isLoadingSingle}>
                            <div className="row v-card-actions mt-4">
                              <div className="col-md-4">
                                <div
                                  className="d-flex align-items-center div-card-details"
                                  onClick={onPrompt}
                                  disabled={isCardFrozen}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FundNewIcon />
                                  <p className="ml-2">Fund card</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div
                                  className="d-flex align-items-center div-card-details"
                                  onClick={onPromptWithdraw}
                                  disabled={isCardFrozen}
                                  style={{ cursor: "pointer" }}
                                >
                                  <WithdrawCardIcon />
                                  <p className="ml-2">Withdraw</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div
                                  className="d-flex align-items-center"
                                  onClick={onFreeze}
                                  style={{ cursor: "pointer" }}
                                >
                                  {isCardFrozen ? (
                                    <UnfreezeIcon />
                                  ) : (
                                    <FreezeIcon />
                                  )}
                                  <p
                                    className="ml-2"
                                    style={{
                                      color: isCardFrozen ? "#5A50FE" : "",
                                    }}
                                  >
                                    {isCardFrozen ? "Unfreeze" : "Freeze card"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Spin>
                          <div className="row v-card-actions mt-4">
                            <div className="col-md-4">
                              <div
                                className="d-flex align-items-center"
                                onClick={onDelete}
                                disabled={isCardFrozen}
                                style={{ cursor: "pointer" }}
                              >
                                <DeleteIcon />
                                <p
                                  className="ml-2"
                                  style={{ color: "#EA0016" }}
                                >
                                  Delete
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="d-flex align-items-center div-card-details"
                                onClick={handleShare}
                                disabled={isCardFrozen}
                                style={{ cursor: "pointer" }}
                              >
                                <ShareIcon />
                                <p className="ml-2">Share</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="card-balances mt-5">
                        <div className="row">
                          <div className="col-md-6 border-right">
                            <h1>Card balance</h1>
                            <p>
                              USD {formatBalance(cardDetailsAll?.card?.balance)}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <h1>Spent this month</h1>
                            <p>
                              USD&nbsp;
                              {formatBalance(cardDetailsAll?.totalCardSpend)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <h5 className="card-creator mt-3">
                    Created by {user?.account?.name}&nbsp;
                    <span>
                      {moment(cardDetailsAll?.card?.createdAt).format(
                        "MMM DD YYYY"
                      )}
                    </span>
                  </h5>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-8">
                  <Tabs
                    activeTabIndex={activeTabIndex}
                    onTabClick={handleTabClick}
                  >
                    <Tab title="Card information">
                      <CardNumbers
                        cardDetailsAll={cardDetailsAll}
                        showCardSummary={showCardSummary}
                        checkAccess={checkAccess}
                        cardNumber={cardNumber}
                        isCardDeleted={isCardDeleted}
                        isCardFrozen={isCardFrozen}
                      />
                    </Tab>
                    {/* <Tab title="Billing address">
                      <CardBillingInfo cardDetails={cardDetails} />
                    </Tab> */}
                    <Tab title="Transaction history">
                      <CardTransactions
                        cardDetails={cardDetailsAll}
                        user={user}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </StyledModal>
        )}
        {isModalDeleteOpen && (
          <DeleteCard
            isModalOpen={isModalDeleteOpen}
            handleCancel={handleCancelDelete}
            handleOk={handleOkDelete}
            loading={loadingDelete}
          />
        )}
      </div>
    </div>
  );
}
