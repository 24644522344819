import ACTIONS from "../../constants";

const initialState = {
  account: null,
  errorMessage: null,
  loading: false,
  userInfo: null,
  errorMessageGet: null,
  loadingGet: false,
};

const businessInfo = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.BUSINESS_INFO:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.BUSINESS_INFO_SUCCESS:
      return {
        ...state,
        account: payload,
        loading: false,
      };
    case ACTIONS.BUSINESS_INFO_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case ACTIONS.BUSINESS_INFO_GET:
      return {
        ...state,
        loadingGet: true,
      };
    case ACTIONS.BUSINESS_INFO_GET_SUCCESS:
      return {
        ...state,
        userInfo: payload,
        loading: false,
      };
    case ACTIONS.BUSINESS_GET_INFO_ERROR:
      return {
        ...state,
        errorMessageGet: payload,
        loading: false,
      };
    case ACTIONS.BUSINESS_INFO_RESET:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        account: null,
      };
    default:
      return state;
  }
};

export default businessInfo;
