import makeAPICall from "../../utilities/apiUtils";

import {
  fetchUserCrypto,
  fetchUserCryptoError,
  fetchUserCryptoSuccess,
  deleteFetchCrypto,
} from "../selectors";

const fetchCryptoAction = (wallet) => (dispatch) => {
  dispatch(fetchUserCrypto());
  return makeAPICall({
    path: `transactions/collections/cryptoAddresses/${wallet}`,
    method: "GET",
  })
    .then((res) => {
      const { address } = res.data;
      dispatch(fetchUserCryptoSuccess(address));
    })
    .catch((err) => {
      dispatch(fetchUserCryptoError(err.message));
    });
};

const deleteFetchCryptoAction = () => (dispatch) => {
  dispatch(deleteFetchCrypto());
};

export { fetchCryptoAction, deleteFetchCryptoAction };
