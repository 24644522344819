import React from "react";
import { Button, Space } from "antd";
// import { ReactComponent as PlusIcon } from "../../assets/images/plus-dark.svg";

export default function EmptyState(props) {
  return (
    <div className="full-width flex justify-center flex-column align-center empty__body--wrap">
      {/* image icon wrap */}
      <div className="flex flex-column align-center">
        <div>
          <img
            src={props.img}
            alt={props.alt}
            style={{ width: "64px", height: "64px" }}
          />
        </div>
        <p className="mt-5 text-smd text-bold color-bold">{props.title}</p>
      </div>
      <div className="empty__description empty__description--beneficiary text-center">
        <p className="color-grey-2 text-smd">{props.description}</p>
      </div>
      <div>
        <Space>
          <Button
            className="btn auth-btn full-width text-bold btn-primary"
            style={{ ...props.btnProps }}
            onClick={props.onClick}
          >
            {props.btnLabel}
          </Button>
          <Button
            className="btn auth-btn full-width text-bold bg-grey"
            style={{ ...props.btnProps }}
            onClick={props.onClickTwo}
          >
            {props.btnLabelTwo}
          </Button>
        </Space>
      </div>
    </div>
  );
}
