import _ from "lodash";
import makeAPICall from "../../utilities/apiUtils";
import { setGlobalSearchQuery, performGlobalSearchSuccess } from "../selectors";

const errors = {};

export const performGlobalSearchAction =
  (query, onSuccess = () => {}, onFailure = () => {}) =>
  (dispatch) => {
    dispatch(setGlobalSearchQuery(query));
    if (_.isEqual(query, "")) {
      dispatch(performGlobalSearchSuccess({}));
      onSuccess();
    } else {
      return makeAPICall({
        path: "search",
        params: { keyword: query },
        method: "GET",
      })
        .then((res) => {
          // console.log("Query: ", query, " Res: ", res);
          const beneficiaries = _.map(
            _.get(res, "data.beneficiaries", []),
            (entry) =>
              _.pick(entry, [
                "id",
                "firstName",
                "lastName",
                "country",
                "type",
                "phoneNumber",
              ])
          );
          const transactions = _.map(
            _.get(res, "data.transactions", []),
            (entry) =>
              _.pick(entry, [
                "id",
                "createdAt",
                "currency",
                "amount",
                "status",
                "transactionId",
                "type",
              ])
          );
          const users = _.map(_.get(res, "data.users", []), (entry) =>
            _.pick(entry, [
              "id",
              "firstName",
              "lastName",
              "email",
              "phone",
              "role",
            ])
          );

          dispatch(
            performGlobalSearchSuccess({
              ...(beneficiaries.length > 0 ? { beneficiaries } : {}),
              ...(transactions.length > 0 ? { transactions } : {}),
              ...(users.length > 0 ? { users } : {}),
            })
          );

          onSuccess(query);
        })
        .catch((err) => {
          const { message } = err;
          onFailure(errors[message] || message);
        });
    }
  };
